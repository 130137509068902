var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "row mb-5" },
    [
      _c(
        "b-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.formMP,
              expression: "!formMP",
            },
          ],
          attrs: { md: "12" },
        },
        [
          _c("header", { staticClass: "bx-header-title" }, [
            _c("h2", {
              attrs: { id: "mi-perfil-id" },
              domProps: { textContent: _vm._s(_vm.$t("mi-perfil.title")) },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [_c("alerta", { ref: "alerta" })],
        1
      ),
      _vm._v(" "),
      _c("b-col", { attrs: { md: "12" } }, [_c("ayuda-mi-perfil")], 1),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c(
            "b-skeleton-wrapper",
            {
              attrs: { loading: !_vm.isLoaded },
              scopedSlots: _vm._u([
                {
                  key: "loading",
                  fn: function () {
                    return [
                      _c("skeleton", { attrs: { type: "image" } }),
                      _vm._v(" "),
                      _c("skeleton", { attrs: { type: "miPerfil" } }),
                      _vm._v(" "),
                      _c("skeleton", { attrs: { type: "form" } }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.formMP,
                      expression: "!formMP",
                    },
                  ],
                },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _vm.isLoaded
                        ? _c("input-image", {
                            attrs: {
                              id: "mi-perfil-fotografia-id",
                              label: _vm.$t("mi-perfil.fotografia.label"),
                              description: _vm.$t(
                                "mi-perfil.fotografia.description"
                              ),
                              readonly: false,
                              valid: !_vm.$v.miPerfil.fotografia.$invalid,
                              tiposMime: ".jpg, .png, .jpeg",
                              pesoMaximo: 1024,
                              estadoInvalido: _vm.estadoInvalidoFotografia,
                            },
                            on: {
                              "update:estadoInvalido": function ($event) {
                                _vm.estadoInvalidoFotografia = $event
                              },
                              "update:estado-invalido": function ($event) {
                                _vm.estadoInvalidoFotografia = $event
                              },
                            },
                            model: {
                              value: _vm.$v.miPerfil.fotografia.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.miPerfil.fotografia,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.miPerfil.fotografia.$model",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input-text-area", {
                        attrs: {
                          id: "mi-perfil-semblanza-id",
                          label: _vm.$t("mi-perfil.semblanza.label"),
                          description: _vm.$t(
                            "mi-perfil.semblanza.description"
                          ),
                          readonly: false,
                          required: true,
                          valid: _vm.$v.miPerfil.semblanza.$dirty
                            ? !_vm.$v.miPerfil.semblanza.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.miPerfil.semblanza.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.semblanza.validations.required"
                            ),
                            minValue: !_vm.$v.miPerfil.semblanza.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.semblanza.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.semblanza.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.semblanza.validations.maxMessage",
                              { max: "2000" }
                            ),
                          },
                          maxCaracteres: 2000,
                        },
                        model: {
                          value: _vm.$v.miPerfil.semblanza.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.semblanza, "$model", $$v)
                          },
                          expression: "$v.miPerfil.semblanza.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "border-custom" }),
                      _vm._v(" "),
                      _vm.validaContactoBandera
                        ? _c(
                            "b-alert",
                            { attrs: { show: "", variant: "danger" } },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("mi-perfil.medios-contacto.alert")
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("header", { staticClass: "mt-5 bx-header-title" }, [
                        _c("h3", {
                          staticClass: "inline",
                          attrs: { id: "cards-contacto-id" },
                          domProps: {
                            textContent: _vm._s(_vm.$t("contacto.title")),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group float-right" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.contactoForm(null)
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("entity.action.add")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-mas" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-linkedin-id",
                          label: _vm.$t("mi-perfil.linkedin.label"),
                          description: _vm.$t("mi-perfil.linkedin.description"),
                          placeholder: _vm.$t("mi-perfil.linkedin.placeHolder"),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.linkedin.$dirty
                            ? !_vm.$v.miPerfil.linkedin.$error
                            : null,
                          validationsCommons: {
                            regexValue: !_vm.$v.miPerfil.linkedin.LINKEDIN,
                            regexMessage: _vm.$t(
                              "mi-perfil.linkedin.validations.regexMessage"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.linkedin.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.linkedin, "$model", $$v)
                          },
                          expression: "$v.miPerfil.linkedin.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("cards-list", {
                        attrs: {
                          id: "id-cards-contacto",
                          items: _vm.mediosDeContactoCard,
                          mostrarListado: false,
                        },
                        on: {
                          remove: _vm.eliminaTelefono,
                          edit: _vm.contactoForm,
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "border-custom" }),
                      _vm._v(" "),
                      _c("header", { staticClass: "mt-5 bx-header-title" }, [
                        _c("h3", {
                          staticClass: "inline",
                          attrs: { id: "cards-habilidad-id" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("mi-perfil.habilidad.title")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group float-right" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.addSkill },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("entity.action.add")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-mas" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.$v.miPerfil.habilidades.$model,
                        function (habilidad, index) {
                          return _c("div", { key: index }, [
                            _c(
                              "div",
                              { staticClass: "flex justify-start ml-2 mt-4" },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: _vm.$t(
                                          "mi-perfil.habilidad.descripcion.placeHolder"
                                        ),
                                        state: _vm.descripcionState(
                                          _vm.$v.miPerfil.habilidades.$each[
                                            index
                                          ].descripcion
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.$v.miPerfil.habilidades.$each[
                                            index
                                          ].descripcion.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.miPerfil.habilidades.$each[
                                              index
                                            ].descripcion,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$v.miPerfil.habilidades.$each[index].descripcion.$model",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("b-form-radio-group", {
                                      staticClass: "p-3 mr-n2",
                                      attrs: {
                                        options: _vm.nivelOptions,
                                        state: _vm.nivelState(
                                          _vm.$v.miPerfil.habilidades.$each[
                                            index
                                          ].nivel
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.$v.miPerfil.habilidades.$each[
                                            index
                                          ].nivel.$model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.$v.miPerfil.habilidades.$each[
                                              index
                                            ].nivel,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "$v.miPerfil.habilidades.$each[index].nivel.$model",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeSkill(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("entity.action.delete")
                                            ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "icon-cerrar",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          state: _vm.descripcionState(
                                            _vm.$v.miPerfil.habilidades.$each[
                                              index
                                            ].descripcion
                                          ),
                                        },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "mi-perfil.habilidad.validations.descripcion"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        attrs: {
                                          state: _vm.nivelState(
                                            _vm.$v.miPerfil.habilidades.$each[
                                              index
                                            ].nivel
                                          ),
                                        },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t(
                                                "mi-perfil.habilidad.validations.nivel"
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "border-custom" }),
                      _vm._v(" "),
                      _c("header", { staticClass: "mt-5 bx-header-title" }, [
                        _c("h3", {
                          staticClass: "inline",
                          attrs: { id: "cards-contacto-id" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("mi-perfil.interes.title")
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "description",
                              fn: function () {
                                return [
                                  _c("div", { attrs: { id: "tags-help" } }, [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$t(
                                            "mi-perfil.interes.descripcion.help"
                                          )
                                        ),
                                      },
                                    }),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _c("b-form-tags", {
                            attrs: {
                              "input-id": "tag-interest",
                              name: "intereses[]",
                              "tag-variant": "primary",
                              "tag-pills": "",
                              size: "lg",
                              addButtonText: _vm.$t("entity.action.add"),
                              placeholder: _vm.$t(
                                "mi-perfil.interes.descripcion.placeHolder"
                              ),
                              "aria-describedby": "tags-help",
                            },
                            model: {
                              value: _vm.$v.miPerfil.intereses.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.miPerfil.intereses,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.miPerfil.intereses.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "border-custom" }),
                      _vm._v(" "),
                      _c("area-conocimiento", {
                        attrs: {
                          id: "area-conocimiento-id",
                          readonly: false,
                          required: true,
                          validA: _vm.validChildArea,
                          edit: _vm.$v.miPerfil.areaConocimiento.$model,
                        },
                        model: {
                          value: _vm.$v.miPerfil.areaConocimiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.areaConocimiento,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.areaConocimiento.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("h3", {
                        staticClass: "m-h3",
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.identificador")),
                        },
                      }),
                      _vm._v(" "),
                      _c("identificador", {
                        attrs: {
                          id: "mi-perfil-orc-id-id",
                          src: "../../../content/images/identificadores-autor/orcid.png",
                          label: _vm.$t("mi-perfil.orc-id.label"),
                          description: _vm.$t("mi-perfil.orc-id.description"),
                          placeholder: _vm.$t("mi-perfil.orc-id.placeHolder"),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.orcId.$dirty
                            ? !_vm.$v.miPerfil.orcId.$error
                            : null,
                          validationsCommons: {
                            minValue: !_vm.$v.miPerfil.orcId.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.orc-id.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.orcId.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.orc-id.validations.maxMessage",
                              { max: "8" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.orcId.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.orcId, "$model", $$v)
                          },
                          expression: "$v.miPerfil.orcId.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("identificador", {
                        attrs: {
                          id: "mi-perfil-researcher-id-thomson-id",
                          src: "../../../content/images/identificadores-autor/thomson.png",
                          label: _vm.$t(
                            "mi-perfil.researcher-id-thomson.label"
                          ),
                          description: _vm.$t(
                            "mi-perfil.researcher-id-thomson.description"
                          ),
                          placeholder: _vm.$t(
                            "mi-perfil.researcher-id-thomson.placeHolder"
                          ),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.researcherIdThomson.$dirty
                            ? !_vm.$v.miPerfil.researcherIdThomson.$error
                            : null,
                          validationsCommons: {
                            minValue:
                              !_vm.$v.miPerfil.researcherIdThomson.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.researcher-id-thomson.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue:
                              !_vm.$v.miPerfil.researcherIdThomson.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.researcher-id-thomson.validations.maxMessage",
                              { max: "8" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.researcherIdThomson.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.researcherIdThomson,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.researcherIdThomson.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("identificador", {
                        attrs: {
                          id: "mi-perfil-ar-xiv-author-id-id",
                          src: "../../../content/images/identificadores-autor/arxiv.png",
                          label: _vm.$t("mi-perfil.ar-xiv-author-id.label"),
                          description: _vm.$t(
                            "mi-perfil.ar-xiv-author-id.description"
                          ),
                          placeholder: _vm.$t(
                            "mi-perfil.ar-xiv-author-id.placeHolder"
                          ),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.arXivAuthorId.$dirty
                            ? !_vm.$v.miPerfil.arXivAuthorId.$error
                            : null,
                          validationsCommons: {
                            minValue: !_vm.$v.miPerfil.arXivAuthorId.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.ar-xiv-author-id.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.arXivAuthorId.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.ar-xiv-author-id.validations.maxMessage",
                              { max: "8" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.arXivAuthorId.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.arXivAuthorId,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.arXivAuthorId.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("identificador", {
                        attrs: {
                          id: "mi-perfil-pub-med-author-id-id",
                          src: "../../../content/images/identificadores-autor/pubmed.png",
                          label: _vm.$t("mi-perfil.pub-med-author-id.label"),
                          description: _vm.$t(
                            "mi-perfil.pub-med-author-id.description"
                          ),
                          placeholder: _vm.$t(
                            "mi-perfil.pub-med-author-id.placeHolder"
                          ),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.pubMedAuthorId.$dirty
                            ? !_vm.$v.miPerfil.pubMedAuthorId.$error
                            : null,
                          validationsCommons: {
                            minValue: !_vm.$v.miPerfil.pubMedAuthorId.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.pub-med-author-id.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.pubMedAuthorId.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.pub-med-author-id.validations.maxMessage",
                              { max: "8" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.pubMedAuthorId.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.pubMedAuthorId,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.pubMedAuthorId.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("identificador", {
                        attrs: {
                          id: "mi-perfil-open-id-id",
                          src: "../../../content/images/identificadores-autor/openid.png",
                          label: _vm.$t("mi-perfil.open-id.label"),
                          description: _vm.$t("mi-perfil.open-id.description"),
                          placeholder: _vm.$t("mi-perfil.open-id.placeHolder"),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.openId.$dirty
                            ? !_vm.$v.miPerfil.openId.$error
                            : null,
                          validationsCommons: {
                            minValue: !_vm.$v.miPerfil.openId.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.open-id.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.openId.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.open-id.validations.maxMessage",
                              { max: "8" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.openId.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.openId, "$model", $$v)
                          },
                          expression: "$v.miPerfil.openId.$model",
                        },
                      }),
                      _vm._v(" "),
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-curp-id",
                          label: _vm.$t("mi-perfil.curp.label"),
                          description: _vm.$t("mi-perfil.curp.description"),
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.curp.$dirty
                            ? !_vm.$v.miPerfil.curp.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.miPerfil.curp.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.curp.validations.required"
                            ),
                            minValue: !_vm.$v.miPerfil.curp.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.curp.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.curp.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.curp.validations.maxMessage",
                              { max: "18" }
                            ),
                            regexValue: !_vm.$v.miPerfil.curp.CURP,
                            regexMessage: _vm.$t(
                              "mi-perfil.curp.validations.regexMessage"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.curp.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.curp, "$model", $$v)
                          },
                          expression: "$v.miPerfil.curp.$model",
                        },
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-nombre-id",
                          label: _vm.$t("mi-perfil.nombre.label"),
                          description: _vm.$t("mi-perfil.nombre.description"),
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.nombre.$dirty
                            ? !_vm.$v.miPerfil.nombre.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.miPerfil.nombre.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.nombre.validations.required"
                            ),
                            minValue: !_vm.$v.miPerfil.nombre.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.nombre.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.nombre.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.nombre.validations.maxMessage",
                              { max: "256" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.nombre.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.nombre, "$model", $$v)
                          },
                          expression: "$v.miPerfil.nombre.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-primer-apellido-id",
                          label: _vm.$t("mi-perfil.primer-apellido.label"),
                          description: _vm.$t(
                            "mi-perfil.primer-apellido.description"
                          ),
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.primerApellido.$dirty
                            ? !_vm.$v.miPerfil.primerApellido.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.miPerfil.primerApellido.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.primer-apellido.validations.required"
                            ),
                            minValue: !_vm.$v.miPerfil.primerApellido.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.primer-apellido.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.miPerfil.primerApellido.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.primer-apellido.validations.maxMessage",
                              { max: "256" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.primerApellido.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.primerApellido,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.primerApellido.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-segundo-apellido-id",
                          label: _vm.$t("mi-perfil.segundo-apellido.label"),
                          description: _vm.$t(
                            "mi-perfil.segundo-apellido.description"
                          ),
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.segundoApellido.$dirty
                            ? !_vm.$v.miPerfil.segundoApellido.$error
                            : null,
                          validationsCommons: {
                            minValue:
                              !_vm.$v.miPerfil.segundoApellido.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.segundo-apellido.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue:
                              !_vm.$v.miPerfil.segundoApellido.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.segundo-apellido.validations.maxMessage",
                              { max: "256" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.segundoApellido.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.segundoApellido,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.segundoApellido.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-fecha-nacimiento-id",
                          label: _vm.$t("mi-perfil.fecha-nacimiento.label"),
                          description: _vm.$t(
                            "mi-perfil.fecha-nacimiento.description"
                          ),
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.fechaNacimiento.$dirty
                            ? !_vm.$v.miPerfil.fechaNacimiento.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.miPerfil.fechaNacimiento.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.fecha-nacimiento.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.fechaNacimiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.fechaNacimiento,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.fechaNacimiento.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "mi-perfil-sexo-id",
                          label: _vm.$t("mi-perfil.sexo.label"),
                          description: _vm.$t("mi-perfil.sexo.description"),
                          options: _vm.sexoOptions,
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.sexo.$dirty
                            ? !_vm.$v.miPerfil.sexo.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.miPerfil.sexo.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.sexo.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.sexo.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.sexo, "$model", $$v)
                          },
                          expression: "$v.miPerfil.sexo.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "mi-perfil-pais-nacimiento-id",
                          label: _vm.$t("mi-perfil.pais-nacimiento.label"),
                          description: _vm.$t(
                            "mi-perfil.pais-nacimiento.description"
                          ),
                          options: _vm.paisNacimientoOptions,
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.paisNacimiento.$dirty
                            ? !_vm.$v.miPerfil.paisNacimiento.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.miPerfil.paisNacimiento.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.pais-nacimiento.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.paisNacimiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.paisNacimiento,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.paisNacimiento.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _vm.$v.miPerfil.paisNacimiento.$model &&
                      _vm.$v.miPerfil.paisNacimiento.$model.id == "MEX"
                        ? _c("input-select-one", {
                            attrs: {
                              id: "mi-perfil-entidad-federativa-id",
                              label: _vm.$t(
                                "mi-perfil.entidad-federativa.label"
                              ),
                              description: _vm.$t(
                                "mi-perfil.entidad-federativa.description"
                              ),
                              options: _vm.entidadFederativaOptions,
                              readonly: true,
                              required: false,
                              valid: _vm.$v.miPerfil.entidadFederativa.$dirty
                                ? !_vm.$v.miPerfil.entidadFederativa.$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.miPerfil.entidadFederativa.required,
                                requiredMessage: _vm.$t(
                                  "mi-perfil.entidad-federativa.validations.required"
                                ),
                              },
                            },
                            model: {
                              value: _vm.$v.miPerfil.entidadFederativa.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.miPerfil.entidadFederativa,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.miPerfil.entidadFederativa.$model",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "mi-perfil-rfc-id",
                          label: _vm.$t("mi-perfil.rfc.label"),
                          description: _vm.$t("mi-perfil.rfc.description"),
                          placeholder: _vm.$t("mi-perfil.rfc.placeHolder"),
                          readonly: false,
                          required: false,
                          valid: _vm.$v.miPerfil.rfc.$dirty
                            ? !_vm.$v.miPerfil.rfc.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.miPerfil.rfc.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.rfc.validations.required"
                            ),
                            minValue: !_vm.$v.miPerfil.rfc.minLength,
                            minMessage: _vm.$t(
                              "mi-perfil.rfc.validations.minMessage",
                              { min: "13" }
                            ),
                            maxValue: !_vm.$v.miPerfil.rfc.maxLength,
                            maxMessage: _vm.$t(
                              "mi-perfil.rfc.validations.maxMessage",
                              { max: "14" }
                            ),
                            regexValue: !_vm.$v.miPerfil.rfc.RFC,
                            regexMessage: _vm.$t(
                              "mi-perfil.rfc.validations.regexMessage"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.rfc.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.rfc, "$model", $$v)
                          },
                          expression: "$v.miPerfil.rfc.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "mi-perfil-estado-civil-id",
                          label: _vm.$t("mi-perfil.estado-civil.label"),
                          description: _vm.$t(
                            "mi-perfil.estado-civil.description"
                          ),
                          options: _vm.estadoCivilOptions,
                          readonly: false,
                          required: true,
                          valid: _vm.$v.miPerfil.estadoCivil.$dirty
                            ? !_vm.$v.miPerfil.estadoCivil.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.miPerfil.estadoCivil.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.estado-civil.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.estadoCivil.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.miPerfil.estadoCivil, "$model", $$v)
                          },
                          expression: "$v.miPerfil.estadoCivil.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "mi-perfil-nacionalidad-id",
                          label: _vm.$t("mi-perfil.nacionalidad.label"),
                          description: _vm.$t(
                            "mi-perfil.nacionalidad.description"
                          ),
                          options: _vm.nacionalidadOptions,
                          readonly: true,
                          required: false,
                          valid: _vm.$v.miPerfil.nacionalidad.$dirty
                            ? !_vm.$v.miPerfil.nacionalidad.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.miPerfil.nacionalidad.required,
                            requiredMessage: _vm.$t(
                              "mi-perfil.nacionalidad.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.miPerfil.nacionalidad.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.nacionalidad,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.nacionalidad.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("mi-perfil.alert.documento-probatorio")
                            ),
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("input-pdf", {
                        attrs: {
                          id: "documento-probatorio-id",
                          label: _vm.$t(
                            "document.documento-probatorio-extranjero"
                          ),
                          description: _vm.$t("document.description", {
                            size: "10",
                          }),
                          readonly: false,
                          type: "pdf",
                          tiposMime: ".pdf",
                          pesoMaximo: 10240,
                        },
                        model: {
                          value: _vm.$v.miPerfil.documentoExtranjero.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.miPerfil.documentoExtranjero,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.miPerfil.documentoExtranjero.$model",
                        },
                      }),
                      _vm._v(" "),
                      _vm.isLoaded
                        ? _c("domicilio", {
                            attrs: {
                              id: "domicilio-id",
                              validC: this.validChildDom,
                            },
                            model: {
                              value: _vm.$v.miPerfil.domicilio.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.miPerfil.domicilio,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.miPerfil.domicilio.$model",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("div", { staticClass: "border-custom" }),
                      _vm._v(" "),
                      _vm.validaContactoEmergenciaBandera
                        ? _c(
                            "b-alert",
                            { attrs: { show: "", variant: "danger" } },
                            [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("mi-perfil.medios-contacto.alert")
                                  ),
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("header", { staticClass: "mt-5 bx-header-title" }, [
                        _c("h3", {
                          staticClass: "inline",
                          attrs: { id: "cards-contactos-emergencia-id" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("contactos-emergencia.title")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group float-right" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.contactoEmergenciaForm(null)
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("entity.action.add")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-mas" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("cards-list", {
                        attrs: {
                          id: "id-cards-contactos-emergencia",
                          items: _vm.contactosEmergenciaCard,
                          mostrarListado: false,
                        },
                        on: {
                          remove: _vm.eliminaContactoEmergencia,
                          edit: _vm.contactoEmergenciaForm,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("div", { staticClass: "border-custom" }),
                      _vm._v(" "),
                      _c("header", { staticClass: "mt-5 bx-header-title" }, [
                        _c("h3", {
                          staticClass: "inline",
                          attrs: { id: "cards-dependientes-economicos-id" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("dependientes-economicos.title")
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group float-right" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.dependienteEconomicoForm(null)
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("entity.action.add")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "icon-mas" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("cards-list", {
                        attrs: {
                          id: "id-cards-dependientes-economicos",
                          items: _vm.dependientesEconomicosCard,
                          mostrarListado: false,
                        },
                        on: {
                          remove: _vm.eliminaDependienteEconomico,
                          edit: _vm.dependienteEconomicoForm,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                        _c("div", { staticClass: "border-custom" }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-5 float-right" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary", size: "lg" },
                              on: { click: _vm.cancel },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.cancel")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "ban" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary", size: "lg" },
                              on: {
                                click: function ($event) {
                                  return _vm.save(_vm.$v.miPerfil.$model)
                                },
                              },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("entity.action.save")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("font-awesome-icon", {
                                staticClass: "mr-2",
                                attrs: { icon: "save" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formContacto,
                      expression: "formContacto",
                    },
                  ],
                },
                [
                  _vm.isLoaded
                    ? _c("contacto", {
                        attrs: {
                          id: "mi-perfil-medios-contacto-id",
                          title: _vm.$t("contacto.title"),
                          index: _vm.indexContacto,
                        },
                        on: {
                          mostrarFormContactoChange: _vm.mostrarFomContacto,
                        },
                        model: {
                          value: _vm.mediosContacto,
                          callback: function ($$v) {
                            _vm.mediosContacto = $$v
                          },
                          expression: "mediosContacto",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formContactoEmergencia,
                      expression: "formContactoEmergencia",
                    },
                  ],
                },
                [
                  _vm.isLoaded
                    ? _c("contactos-emergencia", {
                        attrs: {
                          id: "contatos-emergencia-id",
                          domicilio: _vm.miPerfil.domicilio,
                          index: _vm.indexContactoEmergencia,
                        },
                        on: {
                          mostrarFormContactoEmergenciaChange:
                            _vm.mostrarFomContactoEmergencia,
                        },
                        model: {
                          value: _vm.contactosEmergencia,
                          callback: function ($$v) {
                            _vm.contactosEmergencia = $$v
                          },
                          expression: "contactosEmergencia",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.formDependienteEconomico,
                      expression: "formDependienteEconomico",
                    },
                  ],
                },
                [
                  _vm.isLoaded
                    ? _c("dependientes-economicos", {
                        attrs: {
                          id: "dependientes-economicos-id",
                          index: _vm.indexDependienteEconomico,
                        },
                        on: {
                          mostrarFormDependienteEconomicoChange:
                            _vm.mostrarFormDependienteEconomico,
                        },
                        model: {
                          value: _vm.dependientesEconomicos,
                          callback: function ($$v) {
                            _vm.dependientesEconomicos = $$v
                          },
                          expression: "dependientesEconomicos",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }