var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-breadcrumb",
    { class: _vm.headerClasses },
    _vm._l(_vm.breadcrumbs, function (item, index) {
      return _c(
        "b-breadcrumb-item",
        {
          key: index,
          attrs: {
            to: item.link ? item.link : undefined,
            active: index !== _vm.breadcrumbs.length - 1,
          },
        },
        [_vm._v("\n    " + _vm._s(item.name) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }