var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "xl", type: "dark", sticky: "" } },
    [
      _c(
        "b-navbar-brand",
        { attrs: { href: "https://conahcyt.mx/", target: "_blank" } },
        [
          _c("img", {
            staticClass: "conahcyt",
            attrs: {
              src: require("../../../content/images/logo_blanco.svg"),
              alt: "Conahcyt",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "logo-rizoma" }, [
        _c("img", {
          attrs: {
            src: require("../../../content/images/logo-rizoma-blanco.svg"),
            alt: "Logo Rizoma",
          },
        }),
      ]),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "nav-collapse", "is-nav": "" } }, [
        _c(
          "div",
          { staticClass: "nav-collapse-2" },
          [
            _c(
              "b-navbar-nav",
              [
                _c(
                  "b-nav-item",
                  { attrs: { href: "https://rizoma.conahcyt.mx/" } },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("global.menu.home")),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-navbar-nav",
              { staticClass: "navbar-right" },
              [
                _vm.authenticated
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.sugerencia()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "icon-aportaciones" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(_vm.$t("sugerencia.menu")),
                            },
                          },
                          [_vm._v("Sugerencia")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.authenticated
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.reportarIncidencia()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "icon-correo" }),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("reportar-incidencia.menu")
                            ),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.languages && Object.keys(_vm.languages).length > 1
                  ? _c(
                      "b-nav-item-dropdown",
                      { attrs: { id: "languagesnavBarDropdown", right: "" } },
                      [
                        _c(
                          "span",
                          {
                            attrs: { slot: "button-content" },
                            slot: "button-content",
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "flag" },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.language")
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.languages, function (value, key) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: "lang-" + key,
                              class: { active: _vm.isActiveLanguage(key) },
                              on: {
                                click: function ($event) {
                                  return _vm.changeLanguage(key)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(value.name) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-nav-item",
                  {
                    attrs: {
                      href:
                        "../../../content/docs/PU_User_Manual_" +
                        _vm.currentLanguage +
                        ".pdf",
                      target: "_blank",
                    },
                  },
                  [
                    _c("span", { staticClass: "icon-descargar" }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("perfil-unico.title-descarga-manual-PU")
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-nav-item-dropdown",
                  {
                    staticClass: "pointer",
                    class: {
                      "router-link-active": _vm.subIsActive("/account"),
                    },
                    attrs: {
                      right: "",
                      id: "account-menu",
                      "active-class": "active",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "navbar-dropdown-menu",
                        attrs: { slot: "button-content" },
                        slot: "button-content",
                      },
                      [
                        _c("img", {
                          staticClass: "icon-usuario-img",
                          attrs: {
                            src: require("../../../content/images/foto-empty1.png"),
                            alt: "Usuario",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "no-bold",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.main")
                            ),
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.authenticated
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "data-cy": "logout",
                              id: "logout",
                              "active-class": "active",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.logout()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "sign-out-alt" },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.logout")
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.authenticated
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "data-cy": "login",
                              id: "login",
                              "active-class": "active",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openLogin()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "sign-in-alt" },
                            }),
                            _vm._v(" "),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.login")
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-ayuda",
            title: _vm.$t("global.menu.ayuda"),
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(" Cerrar ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("contacto-asistencia")],
        1
      ),
      _vm._v(" "),
      _c("modal-incidencia", { attrs: { id: "modalIncidencia" } }),
      _vm._v(" "),
      _c("modal-sugerencia", { attrs: { id: "modalSugerencia" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }