var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-col",
    { attrs: { md: "12" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c("h3", {
              staticClass: "m-h3 mb-5",
              domProps: {
                textContent: _vm._s(_vm.$t("contactos-emergencia.title")),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [_c("alerta", { ref: "alerta" })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-nombre-id",
                  label: _vm.$t("contactos-emergencia.form.nombre.label"),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.nombre.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.nombre.$dirty
                    ? !_vm.$v.emergencia.nombre.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.nombre.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.nombre.validations.required"
                    ),
                    minValue: !_vm.$v.emergencia.nombre.minLength,
                    minMessage: _vm.$t(
                      "contactos-emergencia.form.nombre.validations.minMessage",
                      { min: "2" }
                    ),
                    maxValue: !_vm.$v.emergencia.nombre.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.nombre.validations.maxMessage",
                      { max: "50" }
                    ),
                  },
                },
                model: {
                  value: _vm.$v.emergencia.nombre.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.nombre, "$model", $$v)
                  },
                  expression: "$v.emergencia.nombre.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-primerApellido-id",
                  label: _vm.$t(
                    "contactos-emergencia.form.primerApellido.label"
                  ),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.primerApellido.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.primerApellido.$dirty
                    ? !_vm.$v.emergencia.primerApellido.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.primerApellido.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.primerApellido.validations.required"
                    ),
                    minValue: !_vm.$v.emergencia.primerApellido.minLength,
                    minMessage: _vm.$t(
                      "contactos-emergencia.form.primerApellido.validations.minMessage",
                      { min: "2" }
                    ),
                    maxValue: !_vm.$v.emergencia.primerApellido.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.primerApellido.validations.maxMessage",
                      { max: "50" }
                    ),
                  },
                },
                model: {
                  value: _vm.$v.emergencia.primerApellido.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.primerApellido, "$model", $$v)
                  },
                  expression: "$v.emergencia.primerApellido.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-segundoApellido-id",
                  label: _vm.$t(
                    "contactos-emergencia.form.segundoApellido.label"
                  ),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.segundoApellido.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.segundoApellido.$dirty
                    ? !_vm.$v.emergencia.segundoApellido.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.primerApellido.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.segundoApellido.validations.required"
                    ),
                    minValue: !_vm.$v.emergencia.primerApellido.minLength,
                    minMessage: _vm.$t(
                      "contactos-emergencia.form.segundoApellido.validations.minMessage",
                      { min: "2" }
                    ),
                    maxValue: !_vm.$v.emergencia.primerApellido.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.segundoApellido.validations.maxMessage",
                      { max: "50" }
                    ),
                  },
                },
                model: {
                  value: _vm.$v.emergencia.segundoApellido.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.segundoApellido, "$model", $$v)
                  },
                  expression: "$v.emergencia.segundoApellido.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-select-one", {
                attrs: {
                  id: "emergencia-parentesco-id",
                  options: _vm.parentestcoOption,
                  label: _vm.$t("contactos-emergencia.form.parentesco.label"),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.parentesco.$dirty
                    ? !_vm.$v.emergencia.parentesco.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.parentesco.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.parentesco.validations.required"
                    ),
                  },
                },
                on: { change: _vm.sexoParentescoChange },
                model: {
                  value: _vm.$v.emergencia.parentesco.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.parentesco, "$model", $$v)
                  },
                  expression: "$v.emergencia.parentesco.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-date-time", {
                attrs: {
                  id: "emergencia-nacimiento-id",
                  label: _vm.$t(
                    "contactos-emergencia.form.fechaNacimiento.label"
                  ),
                  readonly: false,
                  required: true,
                  maxDate: _vm.minAgeDate,
                  starDate: _vm.startDate,
                  starMonth: _vm.starMonth,
                  description: "El contacto tiene que ser mayor de edad",
                  valid: _vm.$v.emergencia.fechaNacimiento.$dirty
                    ? !_vm.$v.emergencia.fechaNacimiento.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.fechaNacimiento.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.fechaNacimiento.validations.required"
                    ),
                  },
                },
                on: {
                  change: function ($event) {
                    return _vm.validaCambiofecha()
                  },
                },
                model: {
                  value: _vm.$v.emergencia.fechaNacimiento.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.fechaNacimiento, "$model", $$v)
                  },
                  expression: "$v.emergencia.fechaNacimiento.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-select-radio", {
                attrs: {
                  id: "emergencia-sexo-id",
                  label: _vm.$t("contactos-emergencia.form.sexo.label"),
                  options: _vm.sexoOptions,
                  readonly: _vm.sexoDisabled,
                  required: false,
                  valid: _vm.$v.emergencia.sexo.$dirty
                    ? !_vm.$v.emergencia.sexo.$error
                    : null,
                },
                model: {
                  value: _vm.$v.emergencia.sexo.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.sexo, "$model", $$v)
                  },
                  expression: "$v.emergencia.sexo.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-correo-id",
                  label: _vm.$t("contactos-emergencia.form.correo.label"),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.correo.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.correo.$dirty
                    ? !_vm.$v.emergencia.correo.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.correo.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.correo.validations.required"
                    ),
                    minValue: !_vm.$v.emergencia.correo.minLength,
                    minMessage: _vm.$t(
                      "contactos-emergencia.form.correo.validations.minMessage",
                      { min: "5" }
                    ),
                    maxValue: !_vm.$v.emergencia.correo.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.correo.validations.maxMessage",
                      { max: "254" }
                    ),
                    regexValue: !_vm.$v.emergencia.correo.email,
                    regexMessage: _vm.$t(
                      "contactos-emergencia.form.correo.validations.regexMessage"
                    ),
                  },
                },
                model: {
                  value: _vm.$v.emergencia.correo.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.correo, "$model", $$v)
                  },
                  expression: "$v.emergencia.correo.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-confirmCorreo-id",
                  label: _vm.$t(
                    "contactos-emergencia.form.confirmCorreo.label"
                  ),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.confirmCorreo.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.confirmCorreo.$dirty
                    ? !_vm.$v.emergencia.confirmCorreo.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.confirmCorreo.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.confirmCorreo.validations.required"
                    ),
                    minValue: !_vm.$v.emergencia.confirmCorreo.minLength,
                    minMessage: _vm.$t(
                      "contactos-emergencia.form.confirmCorreo.validations.minMessage",
                      { min: "5" }
                    ),
                    maxValue: !_vm.$v.emergencia.confirmCorreo.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.confirmCorreo.validations.maxMessage",
                      { max: "254" }
                    ),
                    regexValue: !_vm.$v.emergencia.confirmCorreo.sameAsEmail,
                    regexMessage: _vm.$t(
                      "contactos-emergencia.form.confirmCorreo.validations.regexMessage"
                    ),
                  },
                },
                model: {
                  value: _vm.$v.emergencia.confirmCorreo.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.confirmCorreo, "$model", $$v)
                  },
                  expression: "$v.emergencia.confirmCorreo.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-lada-id",
                  label: _vm.$t("contactos-emergencia.form.lada.label"),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.lada.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.lada.$dirty
                    ? !_vm.$v.emergencia.lada.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.lada.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.lada.validations.required"
                    ),
                    maxValue: !_vm.$v.emergencia.lada.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.lada.validations.maxMessage",
                      { max: "10" }
                    ),
                    regexValue: !_vm.$v.emergencia.lada.numeric,
                    regexMessage: _vm.$t(
                      "contactos-emergencia.form.lada.validations.regexMessage"
                    ),
                  },
                },
                on: { keypress: _vm.numbersOnly },
                model: {
                  value: _vm.$v.emergencia.lada.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.lada, "$model", $$v)
                  },
                  expression: "$v.emergencia.lada.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-telefono-id",
                  label: _vm.$t("contactos-emergencia.form.telefono.label"),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.telefono.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.telefono.$dirty
                    ? !_vm.$v.emergencia.telefono.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.telefono.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.telefono.validations.required"
                    ),
                    maxValue: !_vm.$v.emergencia.telefono.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.telefono.validations.maxMessage",
                      { max: "10" }
                    ),
                    regexValue: !_vm.$v.emergencia.telefono.numeric,
                    regexMessage: _vm.$t(
                      "contactos-emergencia.form.telefono.validations.regexMessage"
                    ),
                  },
                },
                on: { keypress: _vm.numbersOnly },
                model: {
                  value: _vm.$v.emergencia.telefono.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.telefono, "$model", $$v)
                  },
                  expression: "$v.emergencia.telefono.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("input-text", {
                attrs: {
                  id: "emergencia-movil-id",
                  label: _vm.$t("contactos-emergencia.form.celular.label"),
                  placeholder: _vm.$t(
                    "contactos-emergencia.form.celular.placeholder"
                  ),
                  readonly: false,
                  required: true,
                  valid: _vm.$v.emergencia.celular.$dirty
                    ? !_vm.$v.emergencia.celular.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.celular.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.celular.validations.required"
                    ),
                    maxValue: !_vm.$v.emergencia.celular.maxLength,
                    maxMessage: _vm.$t(
                      "contactos-emergencia.form.celular.validations.maxMessage",
                      { max: "10" }
                    ),
                    regexValue: !_vm.$v.emergencia.celular.numeric,
                    regexMessage: _vm.$t(
                      "contactos-emergencia.form.celular.validations.regexMessage"
                    ),
                  },
                },
                on: { keypress: _vm.numbersOnly },
                model: {
                  value: _vm.$v.emergencia.celular.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.celular, "$model", $$v)
                  },
                  expression: "$v.emergencia.celular.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("input-select-one", {
                attrs: {
                  id: "tipo-id",
                  label: _vm.$t("contactos-emergencia.form.tipoContacto.label"),
                  options: _vm.catTipoDeContacto,
                  required: true,
                  valid: _vm.$v.emergencia.tipoContacto.$dirty
                    ? !_vm.$v.emergencia.tipoContacto.$error
                    : null,
                  validationsCommons: {
                    requiredValue: !_vm.$v.emergencia.tipoContacto.required,
                    requiredMessage: _vm.$t(
                      "contactos-emergencia.form.tipoContacto.validations.required"
                    ),
                  },
                },
                model: {
                  value: _vm.$v.emergencia.tipoContacto.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.emergencia.tipoContacto, "$model", $$v)
                  },
                  expression: "$v.emergencia.tipoContacto.$model",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("input-boolean", {
                attrs: {
                  id: "ce-domicilia-id",
                  label: _vm.$t(
                    "contactos-emergencia.form.mismaDireccion.label"
                  ),
                },
                on: { change: _vm.domiciliaChange },
                model: {
                  value: _vm.domicilia,
                  callback: function ($$v) {
                    _vm.domicilia = $$v
                  },
                  expression: "domicilia",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "mt-4 mb-4", attrs: { sm: "12", lg: "12" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                    _c("label", {
                      staticClass: "label",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "contactos-emergencia.form.domicilio.nacionalExtranjero.label"
                          )
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          options: _vm.catNacionalExtranjero,
                          disabled: _vm.domicilia,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeTipoUbicacion($event)
                          },
                        },
                        model: {
                          value:
                            _vm.$v.emergencia.domicilio.tipoUbicacion.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.emergencia.domicilio.tipoUbicacion,
                              "$model",
                              $$v
                            )
                          },
                          expression:
                            "$v.emergencia.domicilio.tipoUbicacion.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-overlay", {
            attrs: {
              show: _vm.showCP,
              "no-wrap": "",
              "spinner-type": "grow",
              "spinner-variant": "primary",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$v.emergencia.domicilio.tipoUbicacion.$model == "NACIONAL"
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "6", xl: "4" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-codigoPostal-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.codigoPostal.label"
                              ),
                              readonly: _vm.domicilia,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio.codigoPostal
                                .$dirty
                                ? !_vm.$v.emergencia.domicilio.codigoPostal
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.codigoPostal
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostal.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.codigoPostal
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostal.validations.minMessage",
                                  { min: "5" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.codigoPostal
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostal.validations.maxMessage",
                                  { max: "5" }
                                ),
                                regexValue:
                                  !_vm.$v.emergencia.domicilio.codigoPostal
                                    .numeric,
                                regexMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostal.validations.regexMessage"
                                ),
                              },
                            },
                            on: { keypress: _vm.numbersOnly },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.codigoPostal.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.codigoPostal,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.codigoPostal.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { col: "", sm: "12", lg: "6", xl: "3" } },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("span", { staticClass: "label-hidden" }),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    block: "",
                                    disabled:
                                      _vm.domicilia ||
                                      _vm.$v.emergencia.domicilio.codigoPostal
                                        .$invalid,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getCP(
                                        _vm.emergencia.domicilio.codigoPostal
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("entity.action.buscar")
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "icon-buscar ml-2",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "emergencia-estado-id",
                      label: _vm.$t(
                        "contactos-emergencia.form.domicilio.estado.label"
                      ),
                      options: _vm.catEstado,
                      readonly: true,
                      required: true,
                      valid: _vm.$v.emergencia.domicilio.estado.$dirty
                        ? !_vm.$v.emergencia.domicilio.estado.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.emergencia.domicilio.estado.required,
                        requiredMessage: _vm.$t(
                          "contactos-emergencia.form.domicilio.estado.validations.required"
                        ),
                      },
                    },
                    model: {
                      value: _vm.$v.emergencia.domicilio.estado.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.emergencia.domicilio.estado,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.emergencia.domicilio.estado.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "emergencia-municipio-alcaldia-id",
                      label: _vm.$t(
                        "contactos-emergencia.form.domicilio.municipio.label"
                      ),
                      options: _vm.catMunicipio,
                      readonly: true,
                      required: true,
                      valid: _vm.$v.emergencia.domicilio.municipio.$dirty
                        ? !_vm.$v.emergencia.domicilio.municipio.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.emergencia.domicilio.municipio.required,
                        requiredMessage: _vm.$t(
                          "contactos-emergencia.form.domicilio.municipio.validations.required"
                        ),
                      },
                    },
                    model: {
                      value: _vm.$v.emergencia.domicilio.municipio.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.emergencia.domicilio.municipio,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.emergencia.domicilio.municipio.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "emergencia-asentamiento-id",
                      label: _vm.$t(
                        "contactos-emergencia.form.domicilio.asentamiento.label"
                      ),
                      options: _vm.catAsentamiento,
                      readonly: _vm.domicilia,
                      required: false,
                      valid: _vm.$v.emergencia.domicilio.asentamiento.$dirty
                        ? !_vm.$v.emergencia.domicilio.asentamiento.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.emergencia.domicilio.asentamiento.required,
                        requiredMessage: _vm.$t(
                          "contactos-emergencia.form.domicilio.asentamiento.validations.required"
                        ),
                      },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changueTipoAsentamiento($event)
                      },
                    },
                    model: {
                      value: _vm.$v.emergencia.domicilio.asentamiento.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.emergencia.domicilio.asentamiento,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.emergencia.domicilio.asentamiento.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "6" } },
                [
                  _c("input-select-one", {
                    attrs: {
                      id: "emergencia-tipoAsentamiento-asentamiento-id",
                      label: _vm.$t(
                        "contactos-emergencia.form.domicilio.tipoAsentamiento.label"
                      ),
                      options: _vm.catTipoAsentamiento,
                      readonly: true,
                      required: false,
                      valid: _vm.$v.emergencia.domicilio.tipoAsentamiento.$dirty
                        ? !_vm.$v.emergencia.domicilio.tipoAsentamiento.$error
                        : null,
                      validationsCommons: {
                        requiredValue:
                          !_vm.$v.emergencia.domicilio.tipoAsentamiento
                            .required,
                        requiredMessage: _vm.$t(
                          "contactos-emergencia.form.domicilio.tipoAsentamiento.validations.required"
                        ),
                      },
                    },
                    model: {
                      value:
                        _vm.$v.emergencia.domicilio.tipoAsentamiento.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.emergencia.domicilio.tipoAsentamiento,
                          "$model",
                          $$v
                        )
                      },
                      expression:
                        "$v.emergencia.domicilio.tipoAsentamiento.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "8" } },
                        [
                          _c("input-select-one", {
                            attrs: {
                              id: "emergencia-extrangero-pais-id",
                              options: _vm.paises,
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.paisExt.label"
                              ),
                              readonly: false,
                              required: false,
                              valid: _vm.$v.emergencia.domicilio.pais.$dirty
                                ? !_vm.$v.emergencia.domicilio.pais.$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.pais.required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.paisExt.validations.required"
                                ),
                              },
                            },
                            model: {
                              value: _vm.$v.emergencia.domicilio.pais.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.pais,
                                  "$model",
                                  $$v
                                )
                              },
                              expression: "$v.emergencia.domicilio.pais.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { col: "", sm: "12", lg: "4" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-extrangero-codigo-postal-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.codigoPostalExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio.cpExtranjero
                                .$dirty
                                ? !_vm.$v.emergencia.domicilio.cpExtranjero
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.cpExtranjero
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostalExt.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.cpExtranjero
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostalExt.validations.minMessage",
                                  { min: "5" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.cpExtranjero
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostalExt.validations.maxMessage",
                                  { max: "10" }
                                ),
                                regexValue:
                                  !_vm.$v.emergencia.domicilio.cpExtranjero
                                    .numeric,
                                regexMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.codigoPostalExt.validations.regexMessage"
                                ),
                              },
                            },
                            on: { keypress: _vm.numbersOnly },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.cpExtranjero.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.cpExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.cpExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "4" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-extranjero-estado-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.estadoExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio
                                .estadoExtranjero.$dirty
                                ? !_vm.$v.emergencia.domicilio.estadoExtranjero
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.estadoExtranjero
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.estadoExt.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.estadoExtranjero
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.estadoExt.validations.minMessage",
                                  { min: "2" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.estadoExtranjero
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.estadoExt.validations.maxMessage",
                                  { max: "50" }
                                ),
                              },
                            },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.estadoExtranjero
                                  .$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.estadoExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.estadoExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "4" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-extranjero-ciudad-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.ciudadExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio
                                .ciudadExtranjero.$dirty
                                ? !_vm.$v.emergencia.domicilio.ciudadExtranjero
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.ciudadExtranjero
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.ciudadExt.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.ciudadExtranjero
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.ciudadExt.validations.minMessage",
                                  { min: "2" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.ciudadExtranjero
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.ciudadExt.validations.maxMessage",
                                  { max: "50" }
                                ),
                              },
                            },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.ciudadExtranjero
                                  .$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.ciudadExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.ciudadExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "4" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-extranjero-condado-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.condadoExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio
                                .condadoExtranjero.$dirty
                                ? !_vm.$v.emergencia.domicilio.condadoExtranjero
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.condadoExtranjero
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.condadoExt.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.condadoExtranjero
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.condadoExt.validations.minMessage",
                                  { min: "2" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.condadoExtranjero
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.condadoExt.validations.maxMessage",
                                  { max: "50" }
                                ),
                              },
                            },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.condadoExtranjero
                                  .$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.condadoExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.condadoExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "6" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-extranjero-calle-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.calleExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio.calleExtranjero
                                .$dirty
                                ? !_vm.$v.emergencia.domicilio.calleExtranjero
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.calleExtranjero
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.calleExt.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.calleExtranjero
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.calleExt.validations.minMessage",
                                  { min: "2" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.calleExtranjero
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.calleExt.validations.maxMessage",
                                  { max: "50" }
                                ),
                              },
                            },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.calleExtranjero
                                  .$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.calleExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.calleExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "6" } },
                        [
                          _c("input-text", {
                            attrs: {
                              id: "emergencia-extranjero-numero-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.numeroExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio
                                .numeroExtranjero.$dirty
                                ? !_vm.$v.emergencia.domicilio.numeroExtranjero
                                    .$error
                                : null,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio.numeroExtranjero
                                    .required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.numeroExt.validations.required"
                                ),
                                minValue:
                                  !_vm.$v.emergencia.domicilio.numeroExtranjero
                                    .minLength,
                                minMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.numeroExt.validations.minMessage",
                                  { min: "2" }
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio.numeroExtranjero
                                    .maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.numeroExt.validations.maxMessage",
                                  { max: "50" }
                                ),
                              },
                            },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio.numeroExtranjero
                                  .$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio.numeroExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.numeroExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", lg: "12" } },
                        [
                          _c("input-text-area", {
                            attrs: {
                              id: "emergencia-extrangero-referencias-id",
                              label: _vm.$t(
                                "contactos-emergencia.form.domicilio.referenciasExt.label"
                              ),
                              readonly: false,
                              required: true,
                              valid: _vm.$v.emergencia.domicilio
                                .referenciasExtranjero.$dirty
                                ? !_vm.$v.emergencia.domicilio
                                    .referenciasExtranjero.$error
                                : null,
                              maxCaracteres: 256,
                              validationsCommons: {
                                requiredValue:
                                  !_vm.$v.emergencia.domicilio
                                    .referenciasExtranjero.required,
                                requiredMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.referenciasExt.validations.required"
                                ),
                                maxValue:
                                  !_vm.$v.emergencia.domicilio
                                    .referenciasExtranjero.maxLength,
                                maxMessage: _vm.$t(
                                  "contactos-emergencia.form.domicilio.referenciasExt.validations.maxMessage",
                                  { max: "256" }
                                ),
                              },
                            },
                            model: {
                              value:
                                _vm.$v.emergencia.domicilio
                                  .referenciasExtranjero.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.emergencia.domicilio
                                    .referenciasExtranjero,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.emergencia.domicilio.referenciasExtranjero.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [_c("div", { staticClass: "border-custom" })]),
          _vm._v(" "),
          _c("b-col", { staticClass: "mt-3", attrs: { md: "12" } }, [
            _c(
              "div",
              { staticClass: "mb-5 float-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.resetForm },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.cancel")),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "icon-cerrar" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: { click: _vm.addContacto },
                  },
                  [
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("entity.action.save")),
                      },
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      staticClass: "mr-2",
                      attrs: { icon: "save" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }