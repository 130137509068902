import { Component, Inject, Prop, PropSync, Watch } from 'vue-property-decorator';

import CatalogoService from '@/shared/catalogo/catalogo.service';
import { minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';
import UtilityMixin from '@/shared/mixins/utility.mixin';
import { mixins } from 'vue-class-component';

const VALIDATIONS = function () {
  return {
    recibioApoyoConacyt: {
      recibioApoyoConacyt: {
        required: requiredIf(() => this.required),
        minLength: minLength(1),
        maxLength: maxLength(1),
      },
      fondoPrograma: {
        id: {},
        nombre: {},
        minLength: minLength(1),
        maxLength: maxLength(15),
        required: requiredIf(
          () => (this.required && this.recibioApoyoConacyt.recibioApoyoConacyt) || this.recibioApoyoConacyt.recibioApoyoConacyt
        ),
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class ApoyosConacytComponent extends mixins(UtilityMixin) {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @PropSync('value', { required: true, type: Object })
  public recibioApoyoConacyt;

  @Prop({ default: false })
  public readonly: boolean;

  @Prop({ default: false })
  public required: boolean;

  @Prop({ default: null })
  public validComponent: boolean;

  public selectedId = null;

  @Watch('recibioApoyoConacyt', { immediate: true, deep: true })
  onRecibioApoyoConacytChanged(newVal: any) {
    if (newVal?.fondoPrograma?.id) {
      this.selectedId = this.recibioApoyoConacyt.fondoPrograma.id;
    }
  }

  /**
   *validComponent formulario desde el componente (padre)
   *
   */
  @Watch('validComponent', { immediate: true, deep: true })
  handler(value: any) {
    if (value) {
      this.$v.recibioApoyoConacyt.$touch();
    } else {
      this.$v.recibioApoyoConacyt.$reset();
    }
  }

  public fondoProgramaOptions: Array<any> = [];

  mounted(): void {
    this.initCatalogos();
  }

  public initCatalogos(): void {
    this.catalogoService()
      .get('getCatTipoFondos')
      .then(result => {
        this.fondoProgramaOptions = result;
        if (this.selectedId) {
          const found = this.fondoProgramaOptions.find(opt => opt.value.id === this.selectedId);
          this.recibioApoyoConacyt.fondoPrograma = found ? { ...found.value } : null;
        }
      });
  }

  public changeApoyoConacyt(event) {
    if (!event) {
      this.recibioApoyoConacyt.fondoPrograma = null;
    }
  }
}
