import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class AyudaMiPerfilComponent extends Vue {
  public currentLanguage = '';

  created() {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
  }

  public abrirAyuda() {
    this.$bvModal.show('dialogoAyuda');
  }
}
