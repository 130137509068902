import { Component, Inject, Watch } from 'vue-property-decorator';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import AlertService from '@/shared/alert/alert.service';
import PerfilService from '@/shared/perfil/perfil.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { mixins } from 'vue-class-component';

import { MiPerfil, DatosGeneralesApi, BuscarApi, DocumentoProbatorio } from '@/shared/perfil-usuario/api';
import { required, minLength, maxLength, helpers, requiredIf } from 'vuelidate/lib/validators';
import UtilityMixin from '@/shared/mixins/utility.mixin';
import ContactosEmergenciaComponent from '@/components/contactos-emergencia/contactos-emergencia.vue';
import DependientesEconomicosComponent from '@/components/dependientes-pu/dependientes-pu.vue';
import AyudaMiPerfil from '@/components/ayudas/mi-perfil/mi-perfil.vue';
import isEqual from 'lodash.isequal';

const CURP = helpers.regex(
  'curp',
  /^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$|^EXT-[0-9]{14}$/
);
const RFC = helpers.regex('rfc', /^([A-Z,Ñ,&amp;]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/);
const LINKEDIN = helpers.regex('linkedinUrl', /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/in\/([-a-zA-Z0-9]+)$/);

const claveVialidadCarretera = '01';

const validations: any = {
  miPerfil: {
    fotografia: {
      maxLength: maxLength(1000),
    },
    semblanza: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(2000),
    },
    linkedin: {
      LINKEDIN,
    },
    mediosContacto: {
      minLength: minLength(1),
      required,
    },
    habilidades: {
      $each: {
        descripcion: { required },
        nivel: { required },
      },
    },
    areaConocimiento: {
      required,
    },
    orcId: {
      minLength: minLength(1),
      maxLength: maxLength(125),
    },
    researcherIdThomson: {
      minLength: minLength(1),
      maxLength: maxLength(125),
    },
    arXivAuthorId: {
      minLength: minLength(1),
      maxLength: maxLength(500),
    },
    pubMedAuthorId: {
      minLength: minLength(1),
      maxLength: maxLength(125),
    },
    openId: {
      minLength: minLength(1),
      maxLength: maxLength(500),
    },
    curp: {
      minLength: minLength(1),
      maxLength: maxLength(18),
      CURP,
    },
    nombre: {
      minLength: minLength(1),
      maxLength: maxLength(256),
    },
    primerApellido: {
      minLength: minLength(1),
      maxLength: maxLength(256),
    },
    segundoApellido: {
      minLength: minLength(1),
      maxLength: maxLength(256),
    },
    fechaNacimiento: {},
    sexo: {},
    paisNacimiento: {},
    entidadFederativa: {},
    rfc: {
      minLength: minLength(13),
      maxLength: maxLength(14),
      RFC,
    },
    estadoCivil: {
      required,
    },
    nacionalidad: {},
    domicilio: {
      required,
    },
    documentoExtranjero: {},
    contactosEmergencia: {},
    dependientesEconomicos: {},
    intereses: {},
  },
};

@Component({
  validations,
  components: {
    'contactos-emergencia': ContactosEmergenciaComponent,
    'dependientes-economicos': DependientesEconomicosComponent,
    'ayuda-mi-perfil': AyudaMiPerfil,
  },
})
export default class MiPerfilComponent extends mixins(UtilityMixin) {
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('miPerfilApi') private miPerfilApi: () => DatosGeneralesApi;
  @Inject('catalogoService') private catalogoService: () => CatalogoService;
  @Inject('perfilService') private perfilService: () => PerfilService;
  @Inject('buscarApi') private buscarApi: () => BuscarApi;

  public miPerfil: MiPerfil = EntityFactory.getMiPerfil();
  public isSaving = false;
  public currentLanguage = '';
  public documentoProbatorioExtranjero: DocumentoProbatorio = EntityFactory.getDocumentoProbatorio();

  public nivelOptions = [
    { text: '25%', value: '25%' },
    { text: '50%', value: '50%' },
    { text: '75%', value: '75%' },
    { text: '100%', value: '100%' },
  ];

  public estadoInvalidoFotografia = false;
  public sexoOptions: Array<any> = [];
  public paisNacimientoOptions: Array<any> = [];
  public entidadFederativaOptions: Array<any> = [];
  public estadoCivilOptions: Array<any> = [];
  public nacionalidadOptions: Array<any> = [];

  public isLoaded = false;
  public validChildDom = false; //manda a validar componente <domicilio>
  public validChildArea = false; //manda a validar componente <area-conocimiento>
  public isEditContacto = true;
  public formMP = false;
  public formContacto = false;
  public formContactoEmergencia = false;
  public formDependienteEconomico = false;
  public validaContactoBandera = false;
  public validaContactoEmergenciaBandera = false;

  public mediosDeContactoCard: Array<any> = [];
  public contactosEmergenciaCard: Array<any> = [];
  public mediosContacto: Array<any> = [];
  public contactosEmergencia: Array<any> = [];
  public dependientesEconomicosCard: Array<any> = [];
  public dependientesEconomicos: Array<any> = [];
  public mc: Array<any> = [];
  public ce: Array<any> = [];
  public de: Array<any> = [];
  public indexContacto = null;
  public indexContactoEmergencia = null;
  public indexDependienteEconomico = null;
  public documentoExtranjeroPrevio = {};

  created(): void {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
  }

  mounted(): void {
    this.scrollTop();
    this.initCatalogos();
    this.get();
    this.limpiaStorageDocumentos();
  }

  addSkill() {
    this.miPerfil.habilidades.push({
      descripcion: '',
      nivel: '',
    });
  }

  removeSkill(index) {
    this.miPerfil.habilidades.splice(index, 1);
  }

  // Validación del campo Descripción en la sección de Habilidades
  descripcionState(descripcion) {
    // Overall component validation state
    const { $dirty, $error } = descripcion;
    return $dirty ? !$error : null;
  }

  // Validación de opción seleccionada en la sección de Habilidades
  nivelState(nivel) {
    // Overall component validation state
    const { $dirty, $error } = nivel;
    return $dirty ? !$error : null;
  }

  public initCatalogos(): void {
    this.catalogoService()
      .get('getGeneros')
      .then(result => {
        this.sexoOptions = result;
      });
    this.catalogoService()
      .get('getPaises')
      .then(result => {
        this.paisNacimientoOptions = result;
      });
    this.catalogoService()
      .get('getEstadoCiviles')
      .then(result => {
        this.estadoCivilOptions = result;
      });
    this.catalogoService()
      .get('getNacionalidades')
      .then(result => {
        this.nacionalidadOptions = result;
      });
  }

  public getEntidadFederativa() {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getAreaGeoestadisticaEstatal')
        .then(result => {
          this.entidadFederativaOptions = result;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public get(): void {
    this.miPerfilApi()
      .getMiPerfil()
      .then(res => {
        if (res.data) {
          const perfilInit = EntityFactory.getMiPerfil();
          this.miPerfil = {
            ...perfilInit,
            ...res.data,
          };

          const domInit = EntityFactory.getDomicilio();
          this.miPerfil.domicilio = {
            ...domInit,
            ...res.data.domicilio,
          };

          this.mc = JSON.parse(JSON.stringify(this.miPerfil.mediosContacto));
          this.ce = JSON.parse(JSON.stringify(this.miPerfil.contactosEmergencia));
          this.de = JSON.parse(JSON.stringify(this.miPerfil.dependientesEconomicos));

          this.mapCardsMC(this.miPerfil.mediosContacto);
          this.mapCardsCE(this.miPerfil.contactosEmergencia);
          this.mapCardsDE(this.miPerfil.dependientesEconomicos);

          this.getEntidadFederativa().then(() => {
            this.miPerfil.entidadFederativa = this.filtraCatalogoClave(
              this.entidadFederativaOptions,
              this.miPerfil.entidadFederativa?.clave
            );
          });
          if (
            (this.miPerfil.hasOwnProperty('domicilio') && this.miPerfil.domicilio == null) ||
            !this.miPerfil.hasOwnProperty('domicilio')
          ) {
            this.miPerfil.domicilio = EntityFactory.getDomicilio();
          }
          if (
            (this.miPerfil.hasOwnProperty('areaConocimiento') && this.miPerfil.areaConocimiento == null) ||
            !this.miPerfil.hasOwnProperty('areaConocimiento')
          ) {
            this.miPerfil.areaConocimiento = EntityFactory.getAreaConocimiento();
          }
        } else {
          this.miPerfil = EntityFactory.getMiPerfil();
          this.getEntidadFederativa();
        }
        this.documentoExtranjeroPrevio = res.data.documentoExtranjero ? res.data.documentoExtranjero : null;
        this.isLoaded = true;
      })
      .catch(err => {
        if (err?.response?.data?.status === 404) {
          this.miPerfil = EntityFactory.getMiPerfil();
          this.getEntidadFederativa();
        } else if (err?.response?.data?.detail) {
          this.alertService().showError(this, err.response.data.detail);
        } else {
          this.alertService().showError(this, this.$t('error.internalServerError').toString());
        }
        this.isLoaded = true;
      });
  }

  public save(mp): void {
    this.isLoaded = false;
    this.miPerfil.rfc = this.miPerfil.rfc == '' ? null : this.miPerfil.rfc;
    if (!this.$v.miPerfil.$invalid && this.validaDomicilio() && this.validaAreaConocimiento(mp)) {
      this.alerta.limpiar();

      if (
        this.$store.getters.fotoModificable !== null ||
        (this.$store.getters.pdfModificable != null && this.$store.getters.pdfModificable?.content !== '')
      ) {
        if (this.$store.getters.fotoModificable !== null) {
          this.perfilService()
            .dispatchFotografiaV2(this.$store.getters.account.cvu, this.$store.getters.fotoModificable)
            .then(res => {
              if (res.status === 201) {
                // creada/actualizada
                this.miPerfil.fotografia = {
                  nombre: this.$store.getters.fotoModificable.nombre,
                  contentType: this.$store.getters.fotoModificable.contentType,
                  uri: res.headers.location,
                };

                if (this.$store.getters.pdfModificable !== null) {
                  this.perfilService()
                    .dispatchDocumentoV2(this.$store.getters.account.cvu, this.$store.getters.pdfModificable)
                    .then(res => {
                      if (res.status === 201) {
                        this.miPerfil.documentoExtranjero = {
                          nombre: this.$store.getters.pdfModificable.nombre,
                          contentType: this.$store.getters.pdfModificable.contentType,
                          uri: res.headers.location,
                          size: this.$store.getters.pdfModificable.size,
                          definicionDocumento: '1',
                        };

                        this.miPerfilApi()
                          .updateMiPerfilById(1, this.miPerfil)
                          .then(res => {
                            this.alertService().showSuccess(
                              this,
                              this.$t('global.messages.saved.detail', { section: 'Mi Perfil' }).toString()
                            );
                            this.perfilService().retrievePerfil();
                            this.isLoaded = true;
                            this.limpiaStorageDocumentos();
                          })
                          .catch(err => {
                            this.alertService().showError(
                              this,
                              this.$t('global.messages.error.saved', { section: 'Mi Perfil' }).toString()
                            );
                            this.isLoaded = true;
                          });
                      }
                    })
                    .catch(err => {
                      this.alertService().showError(this, 'Ocurrió un error al procesar el pdf');
                      this.isLoaded = true;
                    });
                } else {
                  this.miPerfilApi()
                    .updateMiPerfilById(1, this.miPerfil)
                    .then(res => {
                      this.alertService().showSuccess(this, this.$t('global.messages.saved.detail', { section: 'Mi Perfil' }).toString());
                      this.perfilService().retrievePerfil();
                      this.isLoaded = true;
                      this.limpiaStorageDocumentos();
                    })
                    .catch(err => {
                      this.alertService().showError(this, this.$t('global.messages.error.saved', { section: 'Mi Perfil' }).toString());
                      this.isLoaded = true;
                    });
                }
              } else if (res.status === 200) {
                //camino para cuando solo se elimina la imagen
                this.miPerfil.fotografia = null;
                if (this.$store.getters.pdfModificable !== null && this.documentoExtranjeroPrevio?.['uri'] != null) {
                  this.perfilService()
                    .borraDocumento(this.documentoExtranjeroPrevio['uri'])
                    .then(res => {})
                    .catch(err => {
                      this.alertService().showError(this, 'Ocurrió un error al borrar el pdf');
                      this.isLoaded = true;
                    });
                }

                this.miPerfilApi()
                  .updateMiPerfilById(1, this.miPerfil)
                  .then(res => {
                    this.alertService().showSuccess(
                      this,
                      this.$t('global.messages.saved.detail', { section: this.$t('mi-perfil.title') }).toString()
                    );
                    this.isLoaded = true;
                  })
                  .catch(err => {
                    this.alertService().showError(
                      this,
                      this.$t('global.messages.error.saved', { section: this.$t('mi-perfil.title') }).toString()
                    );
                    this.isLoaded = true;
                  });
              }
            })
            .catch(err => {
              this.alertService().showError(this, 'Ocurrió un error al procesar imagen');
              this.isLoaded = true;
            });
        } else {
          if (this.$store.getters.pdfModificable !== null) {
            //Cuando el PDF es modificado
            if (this.documentoExtranjeroPrevio?.['uri'] != null) {
              //Si existe previamente una URL se elimina el documento anterior
              this.miPerfil.fotografia = null;
              this.perfilService()
                .verificaURLEnSolicitudes(this.$store.getters.account.cvu, this.documentoExtranjeroPrevio['uri'])
                .then(res => {
                  if (!res) {
                    this.perfilService()
                      .borraDocumento(this.documentoExtranjeroPrevio['uri'])
                      .then(res => {
                        if (res.status == 200) {
                          this.actualizaMiPerfilIdPorDocumento();
                        }
                      })
                      .catch(err => {
                        this.alertService().showError(this, 'Ocurrió un error al borrar el pdf');
                        this.isLoaded = true;
                      });
                  } else {
                    this.actualizaMiPerfilIdPorDocumento();
                  }
                });
            } else {
              //Cuando el documento no existe previamente
              this.actualizaMiPerfilIdPorDocumento();
            }
          }
        }
      } else {
        if (
          this.documentoExtranjeroPrevio?.['uri'] != null &&
          this.$store.getters.pdfModificable != null &&
          this.$store.getters.pdfModificable?.uri == null
        ) {
          //En caso de que se requiera guardar mi perfil pero sin el documento probatorio
          this.perfilService()
            .verificaURLEnSolicitudes(this.$store.getters.account.cvu, this.documentoExtranjeroPrevio['uri'])
            .then(res => {
              if (!res) {
                this.perfilService()
                  .borraDocumento(this.documentoExtranjeroPrevio['uri'])
                  .then(res => {
                    if (res.status == 200) {
                      this.miPerfil.documentoExtranjero = null;
                      this.actualizaMiPerfilId();
                    }
                  })
                  .catch(err => {
                    this.alertService().showError(this, 'Ocurrió un error al borrar el pdf');
                    this.isLoaded = true;
                  });
              } else {
                this.miPerfil.documentoExtranjero = null;
                this.actualizaMiPerfilId();
              }
            });
        } else {
          this.actualizaMiPerfilId();
        }
      }
    } else {
      this.validChildArea = true;
      this.validChildDom = true;
      this.validaContactoBandera = true;
      this.$v.miPerfil.$touch();
      this.isLoaded = true;
      this.alerta.mostrar(this.$t('global.messages.alert.required'), 'primary', 20);
      this.scrollTop();
      if (this.miPerfil.mediosContacto.length > 0) {
        this.validaContactoBandera = false;
      }
    }
  }

  public actualizaMiPerfilId(): void {
    this.miPerfilApi()
      .updateMiPerfilById(1, this.miPerfil)
      .then(res => {
        this.alertService().showSuccess(this, this.$t('global.messages.saved.detail', { section: this.$t('mi-perfil.title') }).toString());
        this.documentoExtranjeroPrevio = this.miPerfil.documentoExtranjero ? this.miPerfil.documentoExtranjero : null;
        this.isLoaded = true;
      })
      .catch(err => {
        this.alertService().showError(this, this.$t('global.messages.error.saved', { section: this.$t('mi-perfil.title') }).toString());
        this.isLoaded = true;
      });
  }

  public actualizaMiPerfilIdPorDocumento(): void {
    this.perfilService()
      .dispatchDocumentoV2(this.$store.getters.account.cvu, this.$store.getters.pdfModificable)
      .then(res => {
        if (res.status === 201) {
          this.miPerfil.documentoExtranjero = {
            nombre: this.$store.getters.pdfModificable.nombre,
            contentType: this.$store.getters.pdfModificable.contentType,
            uri: res.headers.location,
            size: this.$store.getters.pdfModificable.size,
            definicionDocumento: '1',
          };
          this.miPerfilApi()
            .updateMiPerfilById(1, this.miPerfil)
            .then(res => {
              this.alertService().showSuccess(this, this.$t('global.messages.saved.detail', { section: 'Mi Perfil' }).toString());
              this.perfilService().retrievePerfil();
              this.isLoaded = true;
              this.documentoExtranjeroPrevio = this.miPerfil.documentoExtranjero ? this.miPerfil.documentoExtranjero : null;
              this.limpiaStorageDocumentos();
            })
            .catch(err => {
              this.alertService().showError(this, this.$t('global.messages.error.saved', { section: 'Mi Perfil' }).toString());
              this.isLoaded = true;
            });
        }
      })
      .catch(err => {
        this.alertService().showError(this, 'Ocurrió un error al procesar el pdf');
        this.isLoaded = true;
      });
  }

  public cancel(): void {
    this.$router.push('/');
    this.validChildArea = false;
  }

  /**
   *
   * @returns validacion domicilio true
   */
  public validaDomicilio() {
    //Tipo ubicacion Extranjero
    if (this.miPerfil.domicilio.tipoUbicacion != 'NACIONAL') {
      return (
        this.miPerfil.domicilio.pais &&
        this.miPerfil.domicilio.cpExtranjero &&
        this.miPerfil.domicilio.estadoExtranjero &&
        this.miPerfil.domicilio.ciudadExtranjero &&
        this.miPerfil.domicilio.condadoExtranjero &&
        this.miPerfil.domicilio.calleExtranjero &&
        this.miPerfil.domicilio.numeroExtranjero &&
        this.miPerfil.domicilio.descripcion &&
        this.miPerfil.domicilio.descripcion.length <= 500
      );
    } else {
      //Tipo ubicacion Nacional
      if (
        this.miPerfil.domicilio.codigoPostal &&
        this.miPerfil.domicilio.estado &&
        this.miPerfil.domicilio.municipio &&
        this.miPerfil.domicilio.localidad
      ) {
        if (this.miPerfil.domicilio.tipoDomicilio == 'URBANO' && this.miPerfil.domicilio.tipoVialidadUrbano?.nombre != 'CARRETERA') {
          if (this.miPerfil.domicilio.requiereDetalle) {
            //Domicilio Urbano con detalle requerido
            if (this.miPerfil.domicilio.descripcion == null) this.miPerfil.domicilio.descripcion = '';
            return (
              this.miPerfil.domicilio.tipoVialidadUrbano &&
              this.miPerfil.domicilio.nombreVialidad &&
              this.miPerfil.domicilio.descripcion.length <= 500
            );
          } else {
            //Domicilio Urbano sin Detalle
            return this.miPerfil.domicilio.tipoVialidadUrbano && this.miPerfil.domicilio.nombreVialidad;
          }
        } else {
          //Tipo Domicilio Rural
          if (this.miPerfil.domicilio.requiereDetalle) {
            //Domicilio Rural con detalle requerido
            if (this.miPerfil.domicilio.descripcion == null) this.miPerfil.domicilio.descripcion = '';
            if (this.miPerfil.domicilio.tipoVialidadRural?.id == claveVialidadCarretera) {
              //tipo vialidad  Carretera
              return (
                this.miPerfil.domicilio.tipoVialidadRural &&
                this.miPerfil.domicilio.nombreVialidad &&
                this.miPerfil.domicilio.administracion &&
                this.miPerfil.domicilio.derechoTransito &&
                this.miPerfil.domicilio.descripcion.length <= 500
              );
            } else {
              return (
                //tipo vialidad != Carretera
                this.miPerfil.domicilio.tipoVialidadRural &&
                this.miPerfil.domicilio.nombreVialidad &&
                this.miPerfil.domicilio.descripcion.length <= 500
              );
            }
          } else {
            //Domicilio Rural sin Detalle
            if (this.miPerfil.domicilio.tipoVialidadRural?.id == claveVialidadCarretera) {
              //tipo vialidad  Carretera
              return (
                this.miPerfil.domicilio.tipoVialidadRural &&
                this.miPerfil.domicilio.nombreVialidad &&
                this.miPerfil.domicilio.administracion &&
                this.miPerfil.domicilio.derechoTransito
              );
            } else {
              //tipo vialidad  != Carretera
              return this.miPerfil.domicilio.tipoVialidadRural && this.miPerfil.domicilio.nombreVialidad;
            }
          }
        }
      } else {
        return false;
      }
    }
  }

  public scrollTop() {
    window.scrollTo({
      top: window.scrollX,
      behavior: 'smooth',
    });
  }

  /**
   * se hace un filtro a los catalogos por id para sacar el modelo y setearlo en el formulario
      esto soluciona el problema de mayusculas y minusculas
   * @param cat catalogo que se va a filtrar
   * @param val valor con el cual se va a filtrar
   * @returns el valor del catalogo
   */
  // filtra catalogo por clave
  public filtraCatalogoClave(cat, val) {
    const filtro = cat.filter(e => e.value.clave == val);
    return filtro.length > 0 ? filtro[0].value : null;
  }

  public mapCardsMC(mc) {
    if (mc) {
      this.mediosDeContactoCard = [];
      mc.forEach(e => {
        this.mediosDeContactoCard.push(this.creaRegistroCardMC(e));
      });
    }
  }
  public mapCardsCE(ce) {
    if (ce) {
      this.contactosEmergenciaCard = [];
      ce.forEach(e => {
        this.contactosEmergenciaCard.push(this.creaRegistroCardCE(e));
      });
    }
  }

  public mapCardsDE(de) {
    if (de) {
      this.dependientesEconomicosCard = [];
      de.forEach(e => {
        this.dependientesEconomicosCard.push(this.creaRegistroCardDE(e));
      });
    }
  }

  //Crea modelo para CARD Medios de contacto
  private creaRegistroCardMC(contacto): any {
    return {
      title: null,
      description: `<b>${this.tipoContacto(contacto.tipo) ? this.resVal(contacto) : this.resVal(contacto)}</b>`,
      footer: `${contacto.tipo.nombre} <br/> ${
        contacto.principal ? '<span class="badge badge-primary badge-pill">Medio de contacto principal</span>' : ''
      }`,
      hidden: true,
      icon: this.tipoContacto(contacto.tipo) ? 'icon-correo' : 'icon-telefono',
    };
  }

  //Crea modelo para CARD
  private creaRegistroCardCE(contacto): any {
    const medioContacto =
      contacto.tipoContacto.id == 'COR' ? contacto.correo : contacto.tipoContacto.id == 'MOV' ? contacto.celular : contacto.telefono;
    const footer =
      contacto.domicilio.tipoUbicacion == 'NACIONAL'
        ? contacto.domicilio.estado.nombre + ' ' + contacto.domicilio.municipio.nombre
        : contacto.domicilio.pais.nombre + ' ' + contacto.domicilio.estadoExtranjero + ' ' + contacto.domicilio.ciudadExtranjero;
    return {
      title: `${contacto.nombre} ${contacto.primerApellido} ${contacto.segundoApellido}`,
      description: `${contacto.tipoContacto.nombre} ${medioContacto}`,
      footer: footer,
      hidden: true,
      icon: 'icon-usuario',
      badge: [
        {
          badge: contacto.parentesco.nombre,
          primary: true,
        },
      ],
    };
  }

  //Crea modelo para CARD Dependientes Economicos
  private creaRegistroCardDE(dependiente): any {
    return {
      title: `${dependiente.nombre} ${dependiente.primerApellido} ${dependiente.segundoApellido}`,
      description: `Orden: ${dependiente.orden}`,
      hidden: true,
      icon: 'icon-correo',
      badge: [
        {
          badge: dependiente.parentesco.nombre,
          primary: true,
        },
      ],
    };
  }

  //retorna el valor correcto para mostrar en el CARD
  public resVal(e) {
    if (e.valor) {
      return e.valor;
    } else if (e.correo) {
      return e.correo;
    } else {
      return e.telefono;
    }
  }
  //TODO modificar comparacion de id cuando se tengan los catalogos finales
  public tipoContacto(e) {
    if (e.id == 'COR' || e.id == '1') {
      return true;
    } else {
      return false;
    }
  }

  public async mostrarFomContacto(e) {
    if (!isEqual(this.mc, this.mediosContacto)) {
      this.updateMediosContacto(this.mediosContacto).then(() => {
        this.formMiPerfil();
        this.formContacto = !this.formContacto;
        this.indexContacto = null;
      });
    } else {
      this.formMiPerfil();
      this.formContacto = !this.formContacto;
      this.indexContacto = null;
    }
    this.validaContacto();
  }

  public async mostrarFomContactoEmergencia(e) {
    if (!isEqual(this.ce, this.contactosEmergencia)) {
      this.updateContactoEmergencia(this.contactosEmergencia).then(() => {
        this.formMiPerfil();
        this.formContactoEmergencia = !this.formContactoEmergencia;
        this.indexContactoEmergencia = null;
      });
    } else {
      this.formMiPerfil();
      this.formContactoEmergencia = !this.formContactoEmergencia;
      this.indexContactoEmergencia = null;
    }
    this.validaContacto();
  }
  public abrirAyuda(seccion) {
    this.$bvModal.show('dialogoAyuda');
  }

  public async mostrarFormDependienteEconomico(e) {
    if (!isEqual(this.de, this.dependientesEconomicos)) {
      this.updateDependientesEconomicos(this.dependientesEconomicos).then(() => {
        this.formMiPerfil();
        this.formDependienteEconomico = !this.formDependienteEconomico;
        this.indexDependienteEconomico = null;
      });
    } else {
      this.formMiPerfil();
      this.formDependienteEconomico = !this.formDependienteEconomico;
      this.indexDependienteEconomico = null;
    }
  }

  public contactoForm(index) {
    this.formMiPerfil();
    this.formContacto = !this.formContacto;
    this.mediosContacto = this.miPerfil.mediosContacto;
    this.indexContacto = index;
  }

  public contactoEmergenciaForm(index) {
    this.formMiPerfil();
    this.formContactoEmergencia = !this.formContactoEmergencia;
    this.contactosEmergencia = this.miPerfil.contactosEmergencia;
    this.indexContactoEmergencia = index;
  }

  public dependienteEconomicoForm(index) {
    this.formMiPerfil();
    this.formDependienteEconomico = !this.formDependienteEconomico;
    this.dependientesEconomicos = this.miPerfil.dependientesEconomicos;
    this.indexDependienteEconomico = index;
  }

  public formMiPerfil() {
    this.formMP = !this.formMP;
  }

  public updateMediosContacto(mc) {
    return new Promise((resolve, reject) => {
      this.miPerfilApi()
        .updateMediosContacto(mc)
        .then(res => {
          this.mapCardsMC(res.data);
          this.mc = JSON.parse(JSON.stringify(res.data));
          this.alertService().showSuccess(this, this.$t('global.messages.saved.detail', { section: 'Medios de contacto' }).toString());
          resolve('ok');
        })
        .catch(err => {
          if (err?.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('error.internalServerError').toString());
          }
          reject(err);
        });
    });
  }
  public updateContactoEmergencia(ce) {
    return new Promise((resolve, reject) => {
      this.miPerfilApi()
        .updateContactosEmergencia(ce)
        .then(res => {
          this.mapCardsCE(res.data);
          this.ce = JSON.parse(JSON.stringify(res.data));
          this.alertService().showSuccess(this, this.$t('global.messages.saved.detail', { section: 'Contactos de emergencia' }).toString());
          resolve('ok');
        })
        .catch(err => {
          if (err?.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('error.internalServerError').toString());
          }
          reject(err);
        });
    });
  }

  public updateDependientesEconomicos(de) {
    // Ordena dependientesEconomicos, provenientes del componente hijo, antes de los guardados
    const deOrdenados = de.slice().sort((a, b) => a.orden - b.orden);
    return new Promise((resolve, reject) => {
      this.miPerfilApi()
        .updateDependientesEconomicos(deOrdenados)
        .then(res => {
          this.mapCardsDE(res.data);
          this.de = JSON.parse(JSON.stringify(res.data));
          this.alertService().showSuccess(this, this.$t('global.messages.saved.detail', { section: 'Dependientes Económicos' }).toString());
          resolve('ok');
        })
        .catch(err => {
          if (err?.response?.data?.detail) {
            this.alertService().showError(this, err.response.data.detail);
          } else {
            this.alertService().showError(this, this.$t('error.internalServerError').toString());
          }
          reject(err);
        });
    });
  }

  public eliminaTelefono(removeId): void {
    this.miPerfil.mediosContacto.splice(removeId, 1);
    this.updateMediosContacto(this.miPerfil.mediosContacto).then(() => {
      this.mapCardsMC(this.miPerfil.mediosContacto);
    });
  }
  public eliminaContactoEmergencia(removeId): void {
    this.miPerfil.contactosEmergencia.splice(removeId, 1);
    this.updateContactoEmergencia(this.miPerfil.contactosEmergencia).then(() => {
      this.mapCardsCE(this.miPerfil.contactosEmergencia);
    });
  }

  public eliminaDependienteEconomico(removeId): void {
    this.miPerfil.dependientesEconomicos.splice(removeId, 1);
    this.updateDependientesEconomicos(this.miPerfil.dependientesEconomicos).then(() => {
      this.mapCardsDE(this.miPerfil.dependientesEconomicos);
    });
  }

  /** Copia las propiedades de un objeto */
  public shallowCopy<T extends object>(source: T): T {
    return {
      ...source,
    };
  }

  public validaContacto(): boolean {
    return (this.validaContactoBandera = this.miPerfil.mediosContacto.length == 0);
  }
}
