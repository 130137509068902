import { Module } from 'vuex';

export const translationStore: Module<any, any> = {
  state: {
    currentLanguage: localStorage.getItem('currentLanguage') || 'es',
    languages: {
      en: { name: 'English' },
      es: { name: 'Español' },
      // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    },
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
    languages: state => state.languages,
  },
  mutations: {
    CurrentLanguage(state, newLanguage) {
      state.currentLanguage = newLanguage;
      localStorage.setItem('currentLanguage', newLanguage);
    },
  },
  actions: {
    initializeCurrentLanguage({ commit }) {
      const savedLanguage = localStorage.getItem('currentLanguage') || 'es';
      commit('CurrentLanguage', savedLanguage);
    },
  },
};
