var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "row align-items-center", attrs: { id: "footer-2" } },
        [
          _c("div", { staticClass: "col-6" }, [
            _c("p", [
              _vm._v("©" + _vm._s(_vm.today) + " "),
              _c("span", {
                domProps: { textContent: _vm._s(_vm.$t("footer.reservados")) },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c(
                "b-link",
                {
                  attrs: {
                    href:
                      "../../../content/docs/AVISO_PRIVACIDAD_RIZOMA_" +
                      _vm.currentLanguage +
                      ".pdf",
                    target: "_blank",
                  },
                },
                [
                  _c("span", { staticClass: "icon-descargar" }),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("footer.privacidad")),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }