import { Vue } from 'vue-property-decorator';
import * as Sentry from '@sentry/vue';
import { Catalog } from '@/shared/model/catalogo.model';
import { saciApi } from '../config/api/saci';

export default class CatalogoService extends Vue {
  public catalogoServiceName = {
    getSectoresScian: 'getScianSectores',
    getSubSectorScian: 'getScianDivisionesScianSectores',
    getRamaScian: 'getScianGruposScianDivisiones',
    getSubRamaScian: 'getScianClasesScianGrupos',
    getClaseScian: 'getScianSubclasesScianClases',
    getSectorOcde: 'getOcdeSectores',
    getDivisionOcde: 'getOcdeDivisionesOcdeSector',
    getGrupoOcde: 'getOcdeGruposOcdeDivisiones',
    getClaseOcde: 'getOcdeClasesOcdeGrupos',
  };

  public get(catalogo: string, param1 = null, param2 = null, page = 1, registros = 600, valueId = false): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        if (catalogo == 'getCatCodigosPostalesFilter' && param1 != null) {
          saciApi
            .getCatCodigosPostalesFilter(param1, page, registros)
            .then(res => {
              resolve(this.toCatalogoVue(Object.assign(res.data)));
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        }
        if (param1 != null && param2 == null) {
          saciApi[catalogo](param1, page, registros)
            .then(res => {
              if (!valueId) {
                resolve(this.toCatalogoVue(res.data));
              } else {
                resolve(this.toCatalogoValueObjectId(res.data));
              }
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        } else if (param2 == null && param1 == null) {
          saciApi[catalogo](page, registros)
            .then(res => {
              if (!valueId) {
                resolve(this.toCatalogoVue(res.data));
              } else {
                resolve(this.toCatalogoValueObjectId(res.data));
              }
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        } else {
          saciApi[catalogo](param1, param2, page, registros)
            .then(res => {
              if (!valueId) {
                resolve(this.toCatalogoVue(res.data));
              } else {
                resolve(this.toCatalogoValueObjectId(res.data));
              }
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    });
  }

  public toCatalogoVue(jsonData: Array<any>): Array<any> {
    const catalogo = [];
    jsonData.forEach(element => {
      catalogo.push({ value: element, text: element.nombre });
    });
    return catalogo;
  }

  public toCatalogoIdValue(jsonData: Array<any>): Array<any> {
    const catalogo = [];
    jsonData.forEach(element => {
      catalogo.push({ value: element.id, text: element.nombre });
    });
    return catalogo;
  }

  public toCatalogoValueObjectId(jsonData: Array<any>): Array<any> {
    const catalogo = [];
    jsonData.forEach(element => {
      catalogo.push({ value: { id: element.id }, text: element.nombre });
    });
    return catalogo;
  }

  public getTipoPerfil(): any {
    const items = require('../../shared/mockups/tiposPerfil.json');
    const catalogos = [];
    for (const item of items) {
      catalogos.push({
        value: { id: item.value, nombre: item.text },
        text: item.text,
        disabled: item.disabled,
      });
    }
    return new Promise(resolve => {
      resolve(catalogos);
    });
  }

  public toCatalogo(data: any): any {
    const catalog = new Catalog(data.id, data.nombre);
    return { value: catalog.id, text: catalog.nombre };
  }

  public toCatalogos(data: Array<any>): Array<any> {
    const catalogos = [];
    data.forEach(item => {
      catalogos.push(this.toCatalogo(item));
    });
    return catalogos;
  }

  public getInst(
    catalogo: string,
    instTipoUnoOPais = null,
    instTipoDos = null,
    clvEntidad = null,
    clvMunicipio = null,
    page = 1,
    registros = 600,
    search: string = null
  ): Promise<any[]> {
    const dummy = [];
    switch (catalogo) {
      case 'getClaveCatExtranjeraPais':
      case 'getCveCatNacionalPublico':
        return new Promise((resolve, reject) => {
          saciApi[catalogo](instTipoUnoOPais, page, registros, search)
            .then(res => {
              resolve(Object.assign(res));
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        });

      case 'getCveClaveCatNacionalPublico':
        return new Promise((resolve, reject) => {
          saciApi
            .getCveClaveCatNacionalPublico(instTipoUnoOPais, instTipoDos, page, registros, search)
            .then(res => {
              resolve(Object.assign(res));
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        });

      case 'getsCveClaveCatNacionalPublico':
        return new Promise((resolve, reject) => {
          saciApi
            .getsCveClaveCatNacionalPublico(instTipoUnoOPais, instTipoDos, clvEntidad, page, registros, search)
            .then(res => {
              resolve(Object.assign(res));
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        });

      case 'getClaveCveCatNacionalPublico':
        return new Promise((resolve, reject) => {
          saciApi
            .getClaveCveCatNacionalPublico(instTipoUnoOPais, instTipoDos, clvEntidad, clvMunicipio, page, registros, search)
            .then(res => {
              resolve(Object.assign(res));
            })
            .catch(err => {
              Sentry.captureException(err);
            });
        });
    }
  }

  public pageTotal = 0;

  public getPaginatedService(
    operationId: string,
    search: string = null,
    instTipoUnoOPais = null,
    instTipoDos = null,
    clvEntidad = null,
    clvMunicipio = null,
    page = 1,
    registros = 600,
    listaInst = {}
  ) {
    return this.getInst(operationId, instTipoUnoOPais, instTipoDos, clvEntidad, clvMunicipio, page, registros, search)
      .then(result => {
        if (result['headers']['x-total-pages'] && this.pageTotal == 0) this.pageTotal = Number(result['headers']['x-total-pages']);
        if (page == 1) {
          listaInst = result['data'];
        } else {
          listaInst = Object.assign(listaInst).concat(result['data']);
        }
        if (page >= this.pageTotal) {
          return this.toCatalogoVue(Object.assign(listaInst));
        }
        page++;
        return this.getPaginatedService(
          operationId,
          search,
          instTipoUnoOPais,
          instTipoDos,
          clvEntidad,
          clvMunicipio,
          page,
          registros,
          listaInst
        );
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  }

  public getCatEjesProgramaticos() {
    return new Promise((resolve, reject) => {
      saciApi
        .getCatEjesProgramaticos()
        .then(res => {
          resolve(this.toCatalogoIdValue(Object.assign(res.data)));
        })
        .catch(err => {
          Sentry.captureException(err);
        });
    });
  }
}
