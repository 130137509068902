import { Component, Vue } from 'vue-property-decorator';
import { BgColor, DatosGeneralesApi, Template } from '@/shared/perfil-usuario';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import PerfilService from '@/shared/perfil/perfil.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import AlertService from '@/shared/alert/alert.service';
@Component
export default class MenuTabbarComponent extends Vue {
  public isHoveringProfile = false;
  public isHoveringEducacion = false;
  public isHoveringExperiencia = false;
  public isHoveringAportaciones = false;
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  @Inject('perfilService') private perfilService: () => PerfilService;
  @Inject('miPerfilApi') private miPerfilApi: () => DatosGeneralesApi;
  @Inject('alertService') private alertService: () => AlertService;

  @Prop({ default: 'perfil-unico.pdf' })
  public nombrePdf: string;
  public contenidoBase64 = '';
  public pdfReady = false;
  public showOverlay = false;
  public TEMPLATE_COMPLETO = EntityFactory.getTemplateOptions(this.$t.bind(this))[0].value;
  public TEMPLATE_RESUMEN = EntityFactory.getTemplateOptions(this.$t.bind(this))[1].value;
  public TEMPLATE_TARJETA = EntityFactory.getTemplateOptions(this.$t.bind(this))[2].value;

  /**
   * Genera un documento PDF basado en la plantilla seleccionada y lo descarga.
   *
   * @param item El tipo de plantilla seleccionado, que puede ser COMPLETO, RESUMEN o TARJETA.
   * @param bgColor El color de fondo seleccionado para la plantilla.
   *
   */
  public generarPdf() {
    this.showOverlay = true;
    this.contenidoBase64 = '';
    this.pdfReady = false;
    let template = null;
    template = this.TEMPLATE_COMPLETO;

    this.miPerfilApi()
      .getReportePerfil(template, BgColor.VINO)
      .then(res => {
        this.contenidoBase64 = res.data.contenidoBase64;
        this.descargarPdf(this.contenidoBase64);
        this.showOverlay = false;
      })
      .catch(() => {
        this.showOverlay = false;
        this.alertService().showError(this, this.$t('perfil-unico.error-descarga').toString());
      });
  }

  /**
   * Descarga un archivo PDF en base a un contenido codificado en Base64.
   *
   * @param pdf El contenido del archivo PDF codificado en formato Base64.
   *
   */
  public descargarPdf(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = this.nombrePdf;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    this.alertService().showSuccess(this, this.$t('perfil-unico.alerta-descarga-exitosa').toString());
  }
}
