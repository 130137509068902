var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "sidebar" },
    [
      _c("b-overlay", {
        attrs: {
          show: _vm.showOverlay,
          "no-wrap": "",
          "spinner-type": "grow",
          opacity: ".40",
          "spinner-variant": "primary",
          "z-index": "99999999",
        },
      }),
      _vm._v(" "),
      _c(
        "b-navbar-brand",
        {
          staticClass: "logo",
          attrs: { id: "popover-brand", "b-link": "", to: "/" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../content/images/rizoma_black.svg"),
              alt: "rizoma",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-version" }, [
            _vm._v(_vm._s(_vm.version)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar_header mb-3 mt-3" },
        [
          _c("b-link", {
            attrs: { to: "/", exact: "" },
            domProps: { textContent: _vm._s(_vm.$t("global.subtitle")) },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  attrs: {
                    id: "convocatoria-menu",
                    to: { name: "Home" },
                    "data-cy": "entity",
                  },
                },
                [
                  _c("span", { staticClass: "icon-solicitud" }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.solicitud")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.generarPdf("COMPLETO", "VINO")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "icon-descargar" }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("perfil-unico.title-descarga")
                      ),
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  class: { active: _vm.isOpen("collapseCuentaUsuario") },
                  attrs: { id: "cuenta-usuario", "data-cy": "entity" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleCollapse("collapseCuentaUsuario")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "tools" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.user-profile-menu.user-account")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml-auto when-items" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: _vm.isOpen("collapseCuentaUsuario")
                            ? "chevron-down"
                            : "chevron-right",
                          "font-scale": "1.5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "collapseCuentaUsuario",
                    visible: _vm.isOpen("collapseCuentaUsuario"),
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems" },
                    [_c("cuenta-usuario-menu")],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  class: { active: _vm.isOpen("collapsePerfil") },
                  attrs: { id: "perfil-menu", "data-cy": "entity" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleCollapse("collapsePerfil")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "person" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.user-profile-menu.user-profile")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml-auto when-items" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: _vm.isOpen("collapsePerfil")
                            ? "chevron-down"
                            : "chevron-right",
                          "font-scale": "1.5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "collapsePerfil",
                    visible: _vm.isOpen("collapsePerfil"),
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems" },
                    [_c("acerca-de-menu")],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  class: { active: _vm.isOpen("collapseEducacion") },
                  attrs: { id: "educacion-menu", "data-cy": "entity" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleCollapse("collapseEducacion")
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "book" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.educacion")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml-auto when-items" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: _vm.isOpen("collapseEducacion")
                            ? "chevron-down"
                            : "chevron-right",
                          "font-scale": "1.5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "collapseEducacion",
                    visible: _vm.isOpen("collapseEducacion"),
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems" },
                    [_c("educacion-menu")],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  class: {
                    active: _vm.isOpen("collapseTrayectoriaProfecional"),
                  },
                  attrs: { id: "trayectoria-profecional", "data-cy": "entity" },
                  on: {
                    click: function ($event) {
                      return _vm.toggleCollapse(
                        "collapseTrayectoriaProfecional"
                      )
                    },
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "person-badge" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.left-menu.trayectoria-profesional")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ml-auto when-items" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: _vm.isOpen("collapseTrayectoriaProfecional")
                            ? "chevron-down"
                            : "chevron-right",
                          "font-scale": "1.5",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  attrs: {
                    id: "collapseTrayectoriaProfecional",
                    visible: _vm.isOpen("collapseTrayectoriaProfecional"),
                  },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems" },
                    [_c("trayectoria-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseEntities",
                      arg: "collapseEntities",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "entity-menu",
                    "active-class": "active",
                    "data-cy": "entity",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "list-ul" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(_vm.$t("global.menu.entities.main")),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseEntities" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("entities-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-popover",
                {
                  attrs: {
                    disabled: _vm.popOverDisabled,
                    target: "entity-menu",
                    triggers: "hover",
                    placement: "right",
                    boundaryPadding: "50",
                    boundary: "window",
                  },
                  on: {
                    "update:disabled": function ($event) {
                      _vm.popOverDisabled = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.main")
                                ),
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    382218920
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems subitemsPopover" },
                    [_c("entities-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseAdmin",
                      arg: "collapseAdmin",
                    },
                  ],
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/admin") },
                  attrs: {
                    id: "admin-menu",
                    "active-class": "active",
                    "data-cy": "adminMenu",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "nut" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(_vm.$t("global.menu.admin.main")),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseAdmin" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("admin-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-popover",
                {
                  attrs: {
                    disabled: _vm.popOverDisabled,
                    target: "admin-menu",
                    triggers: "hover",
                    placement: "right",
                    boundaryPadding: "50",
                    boundary: "window",
                  },
                  on: {
                    "update:disabled": function ($event) {
                      _vm.popOverDisabled = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.main")
                                ),
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1954790522
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems subitemsPopover" },
                    [_c("admin-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      this.languages && Object.keys(this.languages).length > 1 && false
        ? _c(
            "b-nav-item",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle:collapseLenguage",
                  arg: "collapseLenguage",
                },
              ],
              attrs: { id: "languagesnavBarDropdown" },
            },
            [
              _c("b-icon", { attrs: { icon: "flag" } }),
              _vm._v(" "),
              _c("span", {
                staticClass: "item-text",
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.language")),
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "ml-auto when-items" }, [
                _c(
                  "span",
                  { staticClass: "when-open" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "chevron-down", "font-scale": "1.5" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "when-closed" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "chevron-up", "font-scale": "1.5" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "collapseLenguage" } }, [
        _c(
          "ul",
          { staticClass: "list-unstyled subitems" },
          _vm._l(this.languages, function (value, key) {
            return _c(
              "b-dropdown-item",
              {
                key: "lang-" + key,
                class: { active: _vm.isActiveLanguage(key) },
                attrs: { id: "itemsLenguage" },
                on: {
                  click: function ($event) {
                    return _vm.changeLanguage(key)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            disabled: _vm.popOverDisabled,
            target: "languagesnavBarDropdown",
            triggers: "hover",
            placement: "right",
            boundaryPadding: "50",
            boundary: "window",
          },
          on: {
            "update:disabled": function ($event) {
              _vm.popOverDisabled = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("global.menu.language")),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-unstyled subitems subitemsPopover" },
            _vm._l(this.languages, function (value, key) {
              return _c(
                "b-dropdown-item",
                {
                  key: "lang-" + key,
                  class: { active: _vm.isActiveLanguage(key) },
                  attrs: { id: "itemsLenguage" },
                  on: {
                    click: function ($event) {
                      return _vm.changeLanguage(key)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]
              )
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-xl-pu",
            title: "Documento de Perfil Único",
            size: "lg",
          },
        },
        [
          _c(
            "object",
            {
              attrs: {
                data: _vm.contenidoBase64,
                width: "500",
                height: "700",
                type: "application/pdf",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-secondary",
                  attrs: { text: _vm.$t("perfil-unico.alerta-reporte") },
                },
                [
                  _c("a", { attrs: { href: _vm.contenidoBase64 } }, [
                    _vm._v("Download the PDF"),
                  ]),
                  _vm._v(" instead."),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "pdf-preview" } }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "menu-template",
            size: "lg",
            centered: "",
            title: _vm.$t("perfil-unico.titulo-pdf-pu"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var hide = ref.hide
                return [
                  _c("b-button", {
                    attrs: { size: "sm", variant: "outline-secondary" },
                    domProps: {
                      textContent: _vm._s(_vm.$t("entity.action.close")),
                    },
                    on: {
                      click: function ($event) {
                        return hide("forget")
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          [
            _c("p", {
              domProps: { textContent: _vm._s(_vm.$t("perfil-unico.mensaje")) },
            }),
            _vm._v(" "),
            _c("b-table", {
              attrs: {
                "head-variant": "light",
                striped: "",
                items: this.items,
                fields: this.fields,
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(name)",
                  fn: function (data) {
                    return [
                      _vm._v(
                        "\n          " + _vm._s(data.item.name) + "\n        "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(action)",
                  fn: function (data) {
                    return [
                      _vm.showButtons(data.item.id)
                        ? _c("b-button", {
                            attrs: { variant: "primary" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("perfil-unico.visualizar")
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.vistaPreviaPdf(data.item, "VINO")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showButtons(data.item.id)
                        ? _c("b-button", {
                            attrs: { variant: "primary" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("perfil-unico.descargar")
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.generarPdf(data.item, "VINO")
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showDropdown(data.item.id)
                        ? _c(
                            "b-dropdown",
                            {
                              attrs: {
                                id: "dropdown-visualizar",
                                variant: "primary",
                                text: _vm.$t("perfil-unico.visualizar"),
                              },
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.vistaPreviaPdf(
                                        data.item,
                                        "VINO"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.colors.vino")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.vistaPreviaPdf(
                                        data.item,
                                        "VERDE"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.colors.verde")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.vistaPreviaPdf(
                                        data.item,
                                        "GRIS"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.colors.gris")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showDropdown(data.item.id)
                        ? _c(
                            "b-dropdown",
                            {
                              attrs: {
                                id: "dropdown-descargar",
                                variant: "primary",
                                text: _vm.$t("perfil-unico.descargar"),
                              },
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.generarPdf(data.item, "VINO")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.colors.vino")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.generarPdf(data.item, "VERDE")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.colors.verde")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.generarPdf(data.item, "GRIS")
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t("global.colors.gris")
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c("visualizador-pdf", {
        attrs: {
          modalId: "modalPdfPu",
          contenidoBase64: this.contenidoBase64,
          mostrar: this.pdfReady,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }