/* tslint:disable */
/* eslint-disable */
/**
 * msAportaciones
 * Microservicio para aportaciones
 *
 * The version of the OpenAPI document: 1.3.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Descripción de un Actor Articulado
 * @export
 * @interface ActorArticulado
 */
export interface ActorArticulado {
  /**
   * Nombre del actor articulado
   * @type {string}
   * @memberof ActorArticulado
   */
  nombre?: string;
  /**
   * Primer apellido del actor articulado
   * @type {string}
   * @memberof ActorArticulado
   */
  primerApellido?: string;
  /**
   * Segundo apellido del actor articulado
   * @type {string}
   * @memberof ActorArticulado
   */
  segundoApellido?: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof ActorArticulado
   */
  rol?: BaseCatalogo;
}
/**
 * Objeto con propiedades genéricas de una aportación
 * @export
 * @interface Aportacion
 */
export interface Aportacion {
  /**
   * id
   * @type {string}
   * @memberof Aportacion
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof Aportacion
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof Aportacion
   */
  apoyo: Apoyo;
}
/**
 * Información para las sumatorias de los tipos de citas de publicaciones científicas
 * @export
 * @interface AportacionesCompletas
 */
export interface AportacionesCompletas {
  /**
   * Campo para Artículos Científica
   * @type {Array<Articulo>}
   * @memberof AportacionesCompletas
   */
  articulosCientifica?: Array<Articulo>;
  /**
   * Campo para Libros Científica
   * @type {Array<Libro>}
   * @memberof AportacionesCompletas
   */
  librosCientifica?: Array<Libro>;
  /**
   * Campo para Capítulos Científica
   * @type {Array<Capitulo>}
   * @memberof AportacionesCompletas
   */
  capitulosCientifica?: Array<Capitulo>;
  /**
   * Campo para Artículos Difusión
   * @type {Array<Articulo>}
   * @memberof AportacionesCompletas
   */
  articulosDifusion?: Array<Articulo>;
  /**
   * Campo para libros Difusión
   * @type {Array<Libro>}
   * @memberof AportacionesCompletas
   */
  librosDifusion?: Array<Libro>;
  /**
   * Campo para Capítulos Difusión
   * @type {Array<Capitulo>}
   * @memberof AportacionesCompletas
   */
  capitulosDifusion?: Array<Capitulo>;
  /**
   * Campo para Desarrollo Tecnológico Innovación
   * @type {Array<DesarrolloTecnologicoInnovacion>}
   * @memberof AportacionesCompletas
   */
  desarrolloTecnologicoInnovacion?: Array<DesarrolloTecnologicoInnovacion>;
  /**
   * Campo para Propiedad Intelectual
   * @type {Array<PropiedadIntelectual>}
   * @memberof AportacionesCompletas
   */
  propiedadIntelectual?: Array<PropiedadIntelectual>;
  /**
   * Campo para Transferencia Tecnológica
   * @type {Array<TransferenciaTecnologica>}
   * @memberof AportacionesCompletas
   */
  transferenciaTecnologica?: Array<TransferenciaTecnologica>;
}
/**
 * Apoyo que recibe de CONACYT
 * @export
 * @interface Apoyo
 */
export interface Apoyo {
  /**
   * Fondo de programa
   * @type {BaseCatalogo}
   * @memberof Apoyo
   */
  fondoPrograma?: BaseCatalogo;
  /**
   * Recibió apoyo CONACYT
   * @type {boolean}
   * @memberof Apoyo
   */
  recibioApoyoConacyt: boolean;
}
/**
 * Objeto genérico para hacer referencia a archivos
 * @export
 * @interface Archivo
 */
export interface Archivo {
  /**
   * Nombre de archivo
   * @type {string}
   * @memberof Archivo
   */
  nombre?: string;
  /**
   * MIME types de los documentos
   * @type {string}
   * @memberof Archivo
   */
  contentType?: string;
  /**
   * Ubicación del documento
   * @type {string}
   * @memberof Archivo
   */
  uri?: string;
  /**
   * Contiene la definición de documento con la cual se puede determinar el tipo de documento
   * @type {string}
   * @memberof Archivo
   */
  definicionDocumento?: string;
  /**
   * Contiene la definición el tamanio del documento
   * @type {number}
   * @memberof Archivo
   */
  size?: number;
}
/**
 * Un artículo publicado
 * @export
 * @interface Articulo
 */
export interface Articulo {
  /**
   * id
   * @type {string}
   * @memberof Articulo
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof Articulo
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof Articulo
   */
  apoyo: Apoyo;
  /**
   * Número de registro ISSN
   * @type {string}
   * @memberof Articulo
   */
  issn?: string;
  /**
   * Número de registro ISSN electrónico
   * @type {string}
   * @memberof Articulo
   */
  issnElectronico?: string;
  /**
   * Identificador DOI
   * @type {string}
   * @memberof Articulo
   */
  doi?: string;
  /**
   * Nombre de revista donde se publicó
   * @type {string}
   * @memberof Articulo
   */
  nombreRevista: string;
  /**
   * Título de artículo
   * @type {string}
   * @memberof Articulo
   */
  titulo: string;
  /**
   * Año de publicación
   * @type {string}
   * @memberof Articulo
   */
  anio: string;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof Articulo
   */
  productoPrincipal?: boolean;
  /**
   *
   * @type {RolParticipacion}
   * @memberof Articulo
   */
  rolParticipacion?: RolParticipacion;
  /**
   *
   * @type {EstadoPublicacion}
   * @memberof Articulo
   */
  estado?: EstadoPublicacion;
  /**
   *
   * @type {Objetivo}
   * @memberof Articulo
   */
  objetivo?: Objetivo;
  /**
   *
   * @type {Cita}
   * @memberof Articulo
   */
  cita?: Cita;
  /**
   *
   * @type {Tipo}
   * @memberof Articulo
   */
  tipo?: Tipo;
  /**
   * Autores
   * @type {Array<AutorLegado>}
   * @memberof Articulo
   */
  autores?: Array<AutorLegado>;
  /**
   *
   * @type {Archivo}
   * @memberof Articulo
   */
  documento?: Archivo;
}
/**
 *
 * @export
 * @interface ArticuloAllOf
 */
export interface ArticuloAllOf {
  /**
   * Número de registro ISSN
   * @type {string}
   * @memberof ArticuloAllOf
   */
  issn?: string;
  /**
   * Número de registro ISSN electrónico
   * @type {string}
   * @memberof ArticuloAllOf
   */
  issnElectronico?: string;
  /**
   * Identificador DOI
   * @type {string}
   * @memberof ArticuloAllOf
   */
  doi?: string;
  /**
   * Nombre de revista donde se publicó
   * @type {string}
   * @memberof ArticuloAllOf
   */
  nombreRevista: string;
  /**
   * Título de artículo
   * @type {string}
   * @memberof ArticuloAllOf
   */
  titulo: string;
  /**
   * Año de publicación
   * @type {string}
   * @memberof ArticuloAllOf
   */
  anio: string;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof ArticuloAllOf
   */
  productoPrincipal?: boolean;
  /**
   *
   * @type {RolParticipacion}
   * @memberof ArticuloAllOf
   */
  rolParticipacion?: RolParticipacion;
  /**
   *
   * @type {EstadoPublicacion}
   * @memberof ArticuloAllOf
   */
  estado?: EstadoPublicacion;
  /**
   *
   * @type {Objetivo}
   * @memberof ArticuloAllOf
   */
  objetivo?: Objetivo;
  /**
   *
   * @type {Cita}
   * @memberof ArticuloAllOf
   */
  cita?: Cita;
  /**
   *
   * @type {Tipo}
   * @memberof ArticuloAllOf
   */
  tipo?: Tipo;
  /**
   * Autores
   * @type {Array<AutorLegado>}
   * @memberof ArticuloAllOf
   */
  autores?: Array<AutorLegado>;
  /**
   *
   * @type {Archivo}
   * @memberof ArticuloAllOf
   */
  documento?: Archivo;
}
/**
 * Descripción breve de un autor de publicación
 * @export
 * @interface AutorLegado
 */
export interface AutorLegado {
  /**
   * Nombre del autor
   * @type {string}
   * @memberof AutorLegado
   */
  nombre?: string;
  /**
   * Primer apellido
   * @type {string}
   * @memberof AutorLegado
   */
  primerApellido?: string;
  /**
   * Segundo apellido
   * @type {string}
   * @memberof AutorLegado
   */
  segundoApellido?: string;
  /**
   * Identificador OrcId
   * @type {string}
   * @memberof AutorLegado
   */
  orcId?: string;
  /**
   * Orden
   * @type {number}
   * @memberof AutorLegado
   */
  orden?: number;
}
/**
 * Objeto que es utilizado por todos los catálogos en rizoma
 * @export
 * @interface BaseCatalogo
 */
export interface BaseCatalogo {
  /**
   * identificador
   * @type {string}
   * @memberof BaseCatalogo
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof BaseCatalogo
   */
  nombre: string;
}
/**
 * Objeto utilizado para catálogos con datos adicionales
 * @export
 * @interface BaseCatalogoExtendido
 */
export interface BaseCatalogoExtendido {
  /**
   * identificador
   * @type {string}
   * @memberof BaseCatalogoExtendido
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof BaseCatalogoExtendido
   */
  nombre: string;
  /**
   * clave
   * @type {string}
   * @memberof BaseCatalogoExtendido
   */
  clave?: string;
}
/**
 *
 * @export
 * @interface BaseCatalogoExtendidoAllOf
 */
export interface BaseCatalogoExtendidoAllOf {
  /**
   * clave
   * @type {string}
   * @memberof BaseCatalogoExtendidoAllOf
   */
  clave?: string;
}
/**
 * Objeto utilizado para catálogos con clave MIIC
 * @export
 * @interface BaseCatalogoLegado
 */
export interface BaseCatalogoLegado {
  /**
   * Clave
   * @type {string}
   * @memberof BaseCatalogoLegado
   */
  clave: string;
  /**
   * Nombre
   * @type {string}
   * @memberof BaseCatalogoLegado
   */
  nombre: string;
}
/**
 * Un capítulo publicado
 * @export
 * @interface Capitulo
 */
export interface Capitulo {
  /**
   * id
   * @type {string}
   * @memberof Capitulo
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof Capitulo
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof Capitulo
   */
  apoyo: Apoyo;
  /**
   * Número de registro ISBN
   * @type {string}
   * @memberof Capitulo
   */
  isbn: string;
  /**
   * Identificador DOI
   * @type {string}
   * @memberof Capitulo
   */
  doi?: string;
  /**
   * Título de libro
   * @type {string}
   * @memberof Capitulo
   */
  tituloLibro: string;
  /**
   * Año de publicación
   * @type {string}
   * @memberof Capitulo
   */
  anio: string;
  /**
   * Editorial de publicación
   * @type {string}
   * @memberof Capitulo
   */
  editorial: string;
  /**
   * Título de capítulo
   * @type {string}
   * @memberof Capitulo
   */
  titulo: string;
  /**
   * Número de capítulo
   * @type {string}
   * @memberof Capitulo
   */
  numero: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof Capitulo
   */
  rolParticipacion?: RolParticipacion;
  /**
   *
   * @type {EstadoPublicacion}
   * @memberof Capitulo
   */
  estado?: EstadoPublicacion;
  /**
   *
   * @type {Objetivo}
   * @memberof Capitulo
   */
  objetivo?: Objetivo;
  /**
   * Indicador de dictaminación
   * @type {boolean}
   * @memberof Capitulo
   */
  estaDictaminado?: boolean;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof Capitulo
   */
  productoPrincipal?: boolean;
  /**
   *
   * @type {Cita}
   * @memberof Capitulo
   */
  cita?: Cita;
  /**
   *
   * @type {Tipo}
   * @memberof Capitulo
   */
  tipo?: Tipo;
  /**
   * Autores
   * @type {Array<AutorLegado>}
   * @memberof Capitulo
   */
  autores?: Array<AutorLegado>;
  /**
   *
   * @type {Archivo}
   * @memberof Capitulo
   */
  documento?: Archivo;
}
/**
 *
 * @export
 * @interface CapituloAllOf
 */
export interface CapituloAllOf {
  /**
   * Número de registro ISBN
   * @type {string}
   * @memberof CapituloAllOf
   */
  isbn: string;
  /**
   * Identificador DOI
   * @type {string}
   * @memberof CapituloAllOf
   */
  doi?: string;
  /**
   * Título de libro
   * @type {string}
   * @memberof CapituloAllOf
   */
  tituloLibro: string;
  /**
   * Año de publicación
   * @type {string}
   * @memberof CapituloAllOf
   */
  anio: string;
  /**
   * Editorial de publicación
   * @type {string}
   * @memberof CapituloAllOf
   */
  editorial: string;
  /**
   * Título de capítulo
   * @type {string}
   * @memberof CapituloAllOf
   */
  titulo: string;
  /**
   * Número de capítulo
   * @type {string}
   * @memberof CapituloAllOf
   */
  numero: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof CapituloAllOf
   */
  rolParticipacion?: RolParticipacion;
  /**
   *
   * @type {EstadoPublicacion}
   * @memberof CapituloAllOf
   */
  estado?: EstadoPublicacion;
  /**
   *
   * @type {Objetivo}
   * @memberof CapituloAllOf
   */
  objetivo?: Objetivo;
  /**
   * Indicador de dictaminación
   * @type {boolean}
   * @memberof CapituloAllOf
   */
  estaDictaminado?: boolean;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof CapituloAllOf
   */
  productoPrincipal?: boolean;
  /**
   *
   * @type {Cita}
   * @memberof CapituloAllOf
   */
  cita?: Cita;
  /**
   *
   * @type {Tipo}
   * @memberof CapituloAllOf
   */
  tipo?: Tipo;
  /**
   * Autores
   * @type {Array<AutorLegado>}
   * @memberof CapituloAllOf
   */
  autores?: Array<AutorLegado>;
  /**
   *
   * @type {Archivo}
   * @memberof CapituloAllOf
   */
  documento?: Archivo;
}
/**
 * Informacion de citas
 * @export
 * @interface Cita
 */
export interface Cita {
  /**
   * Url del número de citas de la pieza
   * @type {string}
   * @memberof Cita
   */
  urlCita?: string;
  /**
   * Número de veces que se ha citado la pieza, tipo A
   * @type {number}
   * @memberof Cita
   */
  citaA?: number;
  /**
   * Número de veces que se ha citado la pieza, tipo B
   * @type {number}
   * @memberof Cita
   */
  citaB?: number;
  /**
   * Suma del citas A y B
   * @type {number}
   * @memberof Cita
   */
  totalCitas?: number;
}
/**
 * Información para las sumatorias de los tipos de citas de publicaciones científicas
 * @export
 * @interface CitasTotales
 */
export interface CitasTotales {
  /**
   * Nombre de la categoría para el listado
   * @type {string}
   * @memberof CitasTotales
   */
  categoria: string;
  /**
   * Número total de citas A
   * @type {number}
   * @memberof CitasTotales
   */
  totalCitasA: number;
  /**
   * Número total de citas B
   * @type {number}
   * @memberof CitasTotales
   */
  totalCitasB: number;
  /**
   * Número total de citas general
   * @type {number}
   * @memberof CitasTotales
   */
  totalCitas: number;
  /**
   * Número total de productos
   * @type {number}
   * @memberof CitasTotales
   */
  totalProductos: number;
}
/**
 * Desarrollo Tecnologico o Innovacion
 * @export
 * @interface DesarrolloTecnologicoInnovacion
 */
export interface DesarrolloTecnologicoInnovacion {
  /**
   * id
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  apoyo: Apoyo;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  producto: BaseCatalogo;
  /**
   * Nombre del Desarrollo tecnológico o Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  nombre: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  tipoDesarrollo?: BaseCatalogo;
  /**
   * Descripción del Desarrollo tecnológico
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  descripcionDesarrollo: string;
  /**
   * Objetivos del Desarrollo tecnológico o de la Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  objetivosDesarollo: string;
  /**
   * Exponer el problema que atiende el Desarrollo tecnológico o la Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  problemaAtiende: string;
  /**
   *
   * @type {RangoPersonasMexico}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  numeroPersonas: RangoPersonasMexico;
  /**
   * Capturar la justificación del número de personas en México que se benefician del Desarrollo tecnológico o la Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  justificacionBeneficiarios: string;
  /**
   * Exponer el valor y el impacto que los productos tienen sobre las personas
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  generacionValorImpacto: string;
  /**
   * Describir, en caso de que aplique, la innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  descripcionInnovacion?: string;
  /**
   *
   * @type {Scian}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  scian: Scian;
  /**
   *
   * @type {Ocde}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  ocde: Ocde;
  /**
   * Pregunta sobre si la pieza recibió apoyo para su elaboración
   * @type {boolean}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  recibioApoyoConacyt: boolean;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  fondoPrograma?: BaseCatalogo;
  /**
   * Describir el tipo de apoyo recibido por parte del Conacyt
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  descripcionApoyo?: string;
  /**
   * Captura del título del último proyecto que fue apoyado por Conacyt
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  tituloProyectoApoyado?: string;
  /**
   * Número del último proyecto apoyado por Conacyt
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  numeroProyectoApoyado?: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  nivelMadurez: BaseCatalogo;
  /**
   * Descripción de otros resultados relevantes que ostenta el producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  otrosResultados: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  rolParticipacion: RolParticipacion;
  /**
   * Descripción de otro rol de participacion
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  otroRolParticipacion?: string;
  /**
   * Descripción de las tareas que e investigador tuvo en el desarrollo del producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  descripcionActividades: string;
  /**
   * Fecha de inicio de la participación del investigador en el desarrollo del producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  periodoParticipacionInicio: string;
  /**
   * Fecha de fin de la participación del investigador en el desarrollo del producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  periodoParticipacionFin: string;
  /**
   * Resultados de la participación del investigador en el producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  principalesResultados: string;
  /**
   * Actores Articulados
   * @type {Array<ActorArticulado>}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  actoresArticulados?: Array<ActorArticulado>;
  /**
   *
   * @type {Archivo}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  documento?: Archivo;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof DesarrolloTecnologicoInnovacion
   */
  productoPrincipal?: boolean;
}
/**
 *
 * @export
 * @interface DesarrolloTecnologicoInnovacionAllOf
 */
export interface DesarrolloTecnologicoInnovacionAllOf {
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  producto: BaseCatalogo;
  /**
   * Nombre del Desarrollo tecnológico o Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  nombre: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  tipoDesarrollo?: BaseCatalogo;
  /**
   * Descripción del Desarrollo tecnológico
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  descripcionDesarrollo: string;
  /**
   * Objetivos del Desarrollo tecnológico o de la Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  objetivosDesarollo: string;
  /**
   * Exponer el problema que atiende el Desarrollo tecnológico o la Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  problemaAtiende: string;
  /**
   *
   * @type {RangoPersonasMexico}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  numeroPersonas: RangoPersonasMexico;
  /**
   * Capturar la justificación del número de personas en México que se benefician del Desarrollo tecnológico o la Innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  justificacionBeneficiarios: string;
  /**
   * Exponer el valor y el impacto que los productos tienen sobre las personas
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  generacionValorImpacto: string;
  /**
   * Describir, en caso de que aplique, la innovación
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  descripcionInnovacion?: string;
  /**
   *
   * @type {Scian}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  scian: Scian;
  /**
   *
   * @type {Ocde}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  ocde: Ocde;
  /**
   * Pregunta sobre si la pieza recibió apoyo para su elaboración
   * @type {boolean}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  recibioApoyoConacyt: boolean;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  fondoPrograma?: BaseCatalogo;
  /**
   * Describir el tipo de apoyo recibido por parte del Conacyt
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  descripcionApoyo?: string;
  /**
   * Captura del título del último proyecto que fue apoyado por Conacyt
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  tituloProyectoApoyado?: string;
  /**
   * Número del último proyecto apoyado por Conacyt
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  numeroProyectoApoyado?: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  nivelMadurez: BaseCatalogo;
  /**
   * Descripción de otros resultados relevantes que ostenta el producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  otrosResultados: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  rolParticipacion: RolParticipacion;
  /**
   * Descripción de otro rol de participacion
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  otroRolParticipacion?: string;
  /**
   * Descripción de las tareas que e investigador tuvo en el desarrollo del producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  descripcionActividades: string;
  /**
   * Fecha de inicio de la participación del investigador en el desarrollo del producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  periodoParticipacionInicio: string;
  /**
   * Fecha de fin de la participación del investigador en el desarrollo del producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  periodoParticipacionFin: string;
  /**
   * Resultados de la participación del investigador en el producto
   * @type {string}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  principalesResultados: string;
  /**
   * Actores Articulados
   * @type {Array<ActorArticulado>}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  actoresArticulados?: Array<ActorArticulado>;
  /**
   *
   * @type {Archivo}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  documento?: Archivo;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof DesarrolloTecnologicoInnovacionAllOf
   */
  productoPrincipal?: boolean;
}
/**
 * Eje programático CONACYT
 * @export
 * @enum {string}
 */

export const EjeProgramatico = {
  FORTALECIMIENTO_COMUNIDAD: 'FORTALECIMIENTO_COMUNIDAD',
  IMPULSO_FRONTERAS: 'IMPULSO_FRONTERAS',
  INCIDENCIA_PROBLEMATICAS: 'INCIDENCIA_PROBLEMATICAS',
  DESARRROLLO_TECNOLOGIAS: 'DESARRROLLO_TECNOLOGIAS',
  DIFUSION_CIENCIA: 'DIFUSION_CIENCIA',
} as const;

export type EjeProgramatico = typeof EjeProgramatico[keyof typeof EjeProgramatico];

/**
 * Estado actual de la publicación
 * @export
 * @interface EstadoPublicacion
 */
export interface EstadoPublicacion {
  /**
   * identificador
   * @type {string}
   * @memberof EstadoPublicacion
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof EstadoPublicacion
   */
  nombre: string;
}
/**
 * Idioma
 * @export
 * @interface Idioma
 */
export interface Idioma {
  /**
   * identificador
   * @type {string}
   * @memberof Idioma
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof Idioma
   */
  nombre: string;
}
/**
 * Idioma traducido
 * @export
 * @interface IdiomaTraducido
 */
export interface IdiomaTraducido {
  /**
   * identificador
   * @type {string}
   * @memberof IdiomaTraducido
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof IdiomaTraducido
   */
  nombre: string;
}
/**
 * Descripción de una institución
 * @export
 * @interface Institucion
 */
export interface Institucion {
  /**
   * Identificador único
   * @type {number}
   * @memberof Institucion
   */
  id: number;
  /**
   * Clave de la institución, puede ser nula en algunos casos
   * @type {string}
   * @memberof Institucion
   */
  clave?: string;
  /**
   * Nombre de la institución
   * @type {string}
   * @memberof Institucion
   */
  nombre: string;
  /**
   * Tipo de institución
   * @type {BaseCatalogo}
   * @memberof Institucion
   */
  tipo: BaseCatalogo;
  /**
   * País de la institución de acuerdo a catálogo
   * @type {BaseCatalogo}
   * @memberof Institucion
   */
  pais: BaseCatalogo;
  /**
   * Entidad federativa donde se ubica una institución nacional
   * @type {BaseCatalogoLegado}
   * @memberof Institucion
   */
  entidad?: BaseCatalogoLegado;
  /**
   * Municipio donde se ubica una institución nacional
   * @type {BaseCatalogoLegado}
   * @memberof Institucion
   */
  municipio?: BaseCatalogoLegado;
  /**
   * Descripción del primer nivel de sector de una institución
   * @type {BaseCatalogo}
   * @memberof Institucion
   */
  nivelUno?: BaseCatalogo;
  /**
   * Descripción del segundo nivel de sector o ámbito de una institución
   * @type {BaseCatalogo}
   * @memberof Institucion
   */
  nivelDos?: BaseCatalogo;
}
/**
 * Un libro publicado
 * @export
 * @interface Libro
 */
export interface Libro {
  /**
   * id
   * @type {string}
   * @memberof Libro
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof Libro
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof Libro
   */
  apoyo: Apoyo;
  /**
   * Identificador de registro ISBN
   * @type {string}
   * @memberof Libro
   */
  isbn: string;
  /**
   * Identificador DOI
   * @type {string}
   * @memberof Libro
   */
  doi?: string;
  /**
   * Título de publicación
   * @type {string}
   * @memberof Libro
   */
  titulo: string;
  /**
   * Año de publicación
   * @type {string}
   * @memberof Libro
   */
  anio: string;
  /**
   *
   * @type {Pais}
   * @memberof Libro
   */
  pais: Pais;
  /**
   *
   * @type {Idioma}
   * @memberof Libro
   */
  idioma: Idioma;
  /**
   * Editorial de publicación
   * @type {string}
   * @memberof Libro
   */
  editorial: string;
  /**
   * Identificador ISBN traducido
   * @type {string}
   * @memberof Libro
   */
  isbnTraducido?: string;
  /**
   *
   * @type {IdiomaTraducido}
   * @memberof Libro
   */
  idiomaTraducido?: IdiomaTraducido;
  /**
   * Título traducido
   * @type {string}
   * @memberof Libro
   */
  tituloTraducido?: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof Libro
   */
  rolParticipacion?: RolParticipacion;
  /**
   *
   * @type {EstadoPublicacion}
   * @memberof Libro
   */
  estado?: EstadoPublicacion;
  /**
   *
   * @type {Objetivo}
   * @memberof Libro
   */
  objetivo?: Objetivo;
  /**
   * Indicador de dictaminación
   * @type {boolean}
   * @memberof Libro
   */
  estaDictaminado?: boolean;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof Libro
   */
  productoPrincipal?: boolean;
  /**
   *
   * @type {Cita}
   * @memberof Libro
   */
  cita?: Cita;
  /**
   *
   * @type {Tipo}
   * @memberof Libro
   */
  tipo?: Tipo;
  /**
   * Autores
   * @type {Array<AutorLegado>}
   * @memberof Libro
   */
  autores?: Array<AutorLegado>;
  /**
   *
   * @type {Archivo}
   * @memberof Libro
   */
  documento?: Archivo;
}
/**
 *
 * @export
 * @interface LibroAllOf
 */
export interface LibroAllOf {
  /**
   * Identificador de registro ISBN
   * @type {string}
   * @memberof LibroAllOf
   */
  isbn: string;
  /**
   * Identificador DOI
   * @type {string}
   * @memberof LibroAllOf
   */
  doi?: string;
  /**
   * Título de publicación
   * @type {string}
   * @memberof LibroAllOf
   */
  titulo: string;
  /**
   * Año de publicación
   * @type {string}
   * @memberof LibroAllOf
   */
  anio: string;
  /**
   *
   * @type {Pais}
   * @memberof LibroAllOf
   */
  pais: Pais;
  /**
   *
   * @type {Idioma}
   * @memberof LibroAllOf
   */
  idioma: Idioma;
  /**
   * Editorial de publicación
   * @type {string}
   * @memberof LibroAllOf
   */
  editorial: string;
  /**
   * Identificador ISBN traducido
   * @type {string}
   * @memberof LibroAllOf
   */
  isbnTraducido?: string;
  /**
   *
   * @type {IdiomaTraducido}
   * @memberof LibroAllOf
   */
  idiomaTraducido?: IdiomaTraducido;
  /**
   * Título traducido
   * @type {string}
   * @memberof LibroAllOf
   */
  tituloTraducido?: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof LibroAllOf
   */
  rolParticipacion?: RolParticipacion;
  /**
   *
   * @type {EstadoPublicacion}
   * @memberof LibroAllOf
   */
  estado?: EstadoPublicacion;
  /**
   *
   * @type {Objetivo}
   * @memberof LibroAllOf
   */
  objetivo?: Objetivo;
  /**
   * Indicador de dictaminación
   * @type {boolean}
   * @memberof LibroAllOf
   */
  estaDictaminado?: boolean;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof LibroAllOf
   */
  productoPrincipal?: boolean;
  /**
   *
   * @type {Cita}
   * @memberof LibroAllOf
   */
  cita?: Cita;
  /**
   *
   * @type {Tipo}
   * @memberof LibroAllOf
   */
  tipo?: Tipo;
  /**
   * Autores
   * @type {Array<AutorLegado>}
   * @memberof LibroAllOf
   */
  autores?: Array<AutorLegado>;
  /**
   *
   * @type {Archivo}
   * @memberof LibroAllOf
   */
  documento?: Archivo;
}
/**
 * Objetivo de una publicación
 * @export
 * @interface Objetivo
 */
export interface Objetivo {
  /**
   * identificador
   * @type {string}
   * @memberof Objetivo
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof Objetivo
   */
  nombre: string;
}
/**
 * Contenedor de categorías de la Organización para la Cooperación y el Desarrollo Económicos (OCDE)
 * @export
 * @interface Ocde
 */
export interface Ocde {
  /**
   * Sector económico de la Ocde
   * @type {BaseCatalogoExtendido}
   * @memberof Ocde
   */
  sector?: BaseCatalogoExtendido;
  /**
   * Division dependiente de un sector
   * @type {BaseCatalogoExtendido}
   * @memberof Ocde
   */
  division?: BaseCatalogoExtendido;
  /**
   * Grupo dependiente de una división
   * @type {BaseCatalogoExtendido}
   * @memberof Ocde
   */
  grupo?: BaseCatalogoExtendido;
  /**
   * Clase dependiente de un grupo
   * @type {BaseCatalogoExtendido}
   * @memberof Ocde
   */
  clase?: BaseCatalogoExtendido;
}
/**
 * Un país
 * @export
 * @interface Pais
 */
export interface Pais {
  /**
   * identificador
   * @type {string}
   * @memberof Pais
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof Pais
   */
  nombre: string;
}
/**
 *
 * @export
 * @interface Problem
 */
export interface Problem {
  /**
   * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML).
   * @type {string}
   * @memberof Problem
   */
  type?: string;
  /**
   * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable
   * @type {string}
   * @memberof Problem
   */
  title?: string;
  /**
   * The HTTP status code generated by the origin server for this occurrence of the problem.
   * @type {number}
   * @memberof Problem
   */
  status?: number;
  /**
   * A human readable explanation specific to this occurrence of the problem.
   * @type {string}
   * @memberof Problem
   */
  detail?: string;
  /**
   * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced.
   * @type {string}
   * @memberof Problem
   */
  instance?: string;
}
/**
 * Propiedad Intelectual
 * @export
 * @interface PropiedadIntelectual
 */
export interface PropiedadIntelectual {
  /**
   * id
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof PropiedadIntelectual
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof PropiedadIntelectual
   */
  apoyo: Apoyo;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof PropiedadIntelectual
   */
  tipoProteccion: BaseCatalogo;
  /**
   * Descripción de otra protección
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  otraProteccion?: string;
  /**
   * Título del producto
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  titulo: string;
  /**
   * Resumen o síntesis del producto
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  resumen: string;
  /**
   * Solicitantes
   * @type {Array<ActorArticulado>}
   * @memberof PropiedadIntelectual
   */
  solicitantes?: Array<ActorArticulado>;
  /**
   *
   * @type {RolParticipacion}
   * @memberof PropiedadIntelectual
   */
  rolParticipacion: RolParticipacion;
  /**
   * Descripción de otro rol de participacion
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  otroRolParticipacion?: string;
  /**
   * Número de solicitud de propiedad intelectual del producto en México
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  numeroSolicitudMexico?: string;
  /**
   * Fecha de solicitud de propiedad intelectual del producto en México
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  fechaSolicitudMexico?: string;
  /**
   * Especificar si el producto cuenta con una solicitud de propiedad intelectual a nivel internacional
   * @type {boolean}
   * @memberof PropiedadIntelectual
   * @deprecated
   */
  solicitudInternacional: boolean;
  /**
   * Número de solicitud internacional del producto
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  numerosSolicitudInternacional?: string;
  /**
   * Número de publicación internacional del producto
   * @type {string}
   * @memberof PropiedadIntelectual
   */
  numeroPublicacionInternacional?: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof PropiedadIntelectual
   */
  estado: BaseCatalogo;
  /**
   *
   * @type {Archivo}
   * @memberof PropiedadIntelectual
   */
  documento?: Archivo;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof PropiedadIntelectual
   */
  productoPrincipal?: boolean;
}
/**
 *
 * @export
 * @interface PropiedadIntelectualAllOf
 */
export interface PropiedadIntelectualAllOf {
  /**
   *
   * @type {BaseCatalogo}
   * @memberof PropiedadIntelectualAllOf
   */
  tipoProteccion: BaseCatalogo;
  /**
   * Descripción de otra protección
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  otraProteccion?: string;
  /**
   * Título del producto
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  titulo: string;
  /**
   * Resumen o síntesis del producto
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  resumen: string;
  /**
   * Solicitantes
   * @type {Array<ActorArticulado>}
   * @memberof PropiedadIntelectualAllOf
   */
  solicitantes?: Array<ActorArticulado>;
  /**
   *
   * @type {RolParticipacion}
   * @memberof PropiedadIntelectualAllOf
   */
  rolParticipacion: RolParticipacion;
  /**
   * Descripción de otro rol de participacion
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  otroRolParticipacion?: string;
  /**
   * Número de solicitud de propiedad intelectual del producto en México
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  numeroSolicitudMexico?: string;
  /**
   * Fecha de solicitud de propiedad intelectual del producto en México
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  fechaSolicitudMexico?: string;
  /**
   * Especificar si el producto cuenta con una solicitud de propiedad intelectual a nivel internacional
   * @type {boolean}
   * @memberof PropiedadIntelectualAllOf
   * @deprecated
   */
  solicitudInternacional: boolean;
  /**
   * Número de solicitud internacional del producto
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  numerosSolicitudInternacional?: string;
  /**
   * Número de publicación internacional del producto
   * @type {string}
   * @memberof PropiedadIntelectualAllOf
   */
  numeroPublicacionInternacional?: string;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof PropiedadIntelectualAllOf
   */
  estado: BaseCatalogo;
  /**
   *
   * @type {Archivo}
   * @memberof PropiedadIntelectualAllOf
   */
  documento?: Archivo;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof PropiedadIntelectualAllOf
   */
  productoPrincipal?: boolean;
}
/**
 * Rangos para los números de personas en México
 * @export
 * @enum {string}
 */

export const RangoPersonasMexico = {
  CIEN_A_MIL: 'CIEN_A_MIL',
  MIL_UNO_A_DIEZ_MIL: 'MIL_UNO_A_DIEZ_MIL',
  DIEZ_MIL_UNO_A_CIEN_MIL: 'DIEZ_MIL_UNO_A_CIEN_MIL',
  MAS_DE_CIEN_MIL: 'MAS_DE_CIEN_MIL',
} as const;

export type RangoPersonasMexico = typeof RangoPersonasMexico[keyof typeof RangoPersonasMexico];

/**
 * Rol de participación
 * @export
 * @interface RolParticipacion
 */
export interface RolParticipacion {
  /**
   * identificador
   * @type {string}
   * @memberof RolParticipacion
   */
  id: string;
  /**
   * nombre
   * @type {string}
   * @memberof RolParticipacion
   */
  nombre: string;
}
/**
 * Contenedor con los niveles del Sistema de Clasificación Industrial de América del Norte-SCIAN
 * @export
 * @interface Scian
 */
export interface Scian {
  /**
   * Sector económico Scian
   * @type {BaseCatalogoExtendido}
   * @memberof Scian
   */
  sector?: BaseCatalogoExtendido;
  /**
   * Subsector dependiente de un sector
   * @type {BaseCatalogoExtendido}
   * @memberof Scian
   */
  subsector?: BaseCatalogoExtendido;
  /**
   * Rama Scian
   * @type {BaseCatalogoExtendido}
   * @memberof Scian
   */
  rama?: BaseCatalogoExtendido;
  /**
   * Subrama dependiente de una Rama
   * @type {BaseCatalogoExtendido}
   * @memberof Scian
   */
  subrama?: BaseCatalogoExtendido;
  /**
   * Clase económica Scian
   * @type {BaseCatalogoExtendido}
   * @memberof Scian
   */
  clase?: BaseCatalogoExtendido;
}
/**
 * Tipo de publicacion
 * @export
 * @enum {string}
 */

export const Tipo = {
  CIENTIFICA: 'CIENTIFICA',
  DIFUSION: 'DIFUSION',
} as const;

export type Tipo = typeof Tipo[keyof typeof Tipo];

/**
 * Objeto que es utilizado por todos los totales de los productos y productos principales
 * @export
 * @interface TotalProductos
 */
export interface TotalProductos {
  /**
   * Total de productos generales
   * @type {number}
   * @memberof TotalProductos
   */
  totalProducto?: number;
  /**
   * Total de productos principales
   * @type {number}
   * @memberof TotalProductos
   */
  totalProductoPrincipal?: number;
}
/**
 * Transferencia Tecnologica
 * @export
 * @interface TransferenciaTecnologica
 */
export interface TransferenciaTecnologica {
  /**
   * id
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  id?: string;
  /**
   *
   * @type {EjeProgramatico}
   * @memberof TransferenciaTecnologica
   */
  eje: EjeProgramatico;
  /**
   *
   * @type {Apoyo}
   * @memberof TransferenciaTecnologica
   */
  apoyo: Apoyo;
  /**
   *
   * @type {BaseCatalogo}
   * @memberof TransferenciaTecnologica
   */
  tipoActividad: BaseCatalogo;
  /**
   * Descripción de otra actividad
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  otraActividad?: string;
  /**
   * Descripción de la actividad de transferencia
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  descripcionActividad: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof TransferenciaTecnologica
   */
  rolParticipacion: RolParticipacion;
  /**
   * Descripción de otro rol de participacion
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  otroRolParticipacion?: string;
  /**
   * Descripción de las actividades realizadas por el  investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  descripcionActividades: string;
  /**
   * Fecha inicio de la participación del investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  periodoParticipacionInicio: string;
  /**
   * Fecha fin de la participación del investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  periodoParticipacionFin: string;
  /**
   * Resultados de la participación del investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologica
   */
  principalesResultados: string;
  /**
   *
   * @type {Institucion}
   * @memberof TransferenciaTecnologica
   */
  institucionReceptora?: Institucion;
  /**
   * Actores Articulados
   * @type {Array<ActorArticulado>}
   * @memberof TransferenciaTecnologica
   */
  actoresArticulados?: Array<ActorArticulado>;
  /**
   *
   * @type {Archivo}
   * @memberof TransferenciaTecnologica
   */
  documento?: Archivo;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof TransferenciaTecnologica
   */
  productoPrincipal?: boolean;
}
/**
 *
 * @export
 * @interface TransferenciaTecnologicaAllOf
 */
export interface TransferenciaTecnologicaAllOf {
  /**
   *
   * @type {BaseCatalogo}
   * @memberof TransferenciaTecnologicaAllOf
   */
  tipoActividad: BaseCatalogo;
  /**
   * Descripción de otra actividad
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  otraActividad?: string;
  /**
   * Descripción de la actividad de transferencia
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  descripcionActividad: string;
  /**
   *
   * @type {RolParticipacion}
   * @memberof TransferenciaTecnologicaAllOf
   */
  rolParticipacion: RolParticipacion;
  /**
   * Descripción de otro rol de participacion
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  otroRolParticipacion?: string;
  /**
   * Descripción de las actividades realizadas por el  investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  descripcionActividades: string;
  /**
   * Fecha inicio de la participación del investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  periodoParticipacionInicio: string;
  /**
   * Fecha fin de la participación del investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  periodoParticipacionFin: string;
  /**
   * Resultados de la participación del investigador en la transferencia tecnológica
   * @type {string}
   * @memberof TransferenciaTecnologicaAllOf
   */
  principalesResultados: string;
  /**
   *
   * @type {Institucion}
   * @memberof TransferenciaTecnologicaAllOf
   */
  institucionReceptora?: Institucion;
  /**
   * Actores Articulados
   * @type {Array<ActorArticulado>}
   * @memberof TransferenciaTecnologicaAllOf
   */
  actoresArticulados?: Array<ActorArticulado>;
  /**
   *
   * @type {Archivo}
   * @memberof TransferenciaTecnologicaAllOf
   */
  documento?: Archivo;
  /**
   * Indicador de si es un producto principal
   * @type {boolean}
   * @memberof TransferenciaTecnologicaAllOf
   */
  productoPrincipal?: boolean;
}

/**
 * AportacionesApi - axios parameter creator
 * @export
 */
export const AportacionesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina un artículo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteArticulo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteArticulo', 'id', id);
      const localVarPath = `/articulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Elimina un capítulo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCapitulo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCapitulo', 'id', id);
      const localVarPath = `/capitulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDesarrolloTecnologicoInnovacion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteDesarrolloTecnologicoInnovacion', 'id', id);
      const localVarPath = `/desarrollos-tecnologicos-innovaciones/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Elimina un libro
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLibro: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteLibro', 'id', id);
      const localVarPath = `/libros/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Elimina información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePropiedadIntelectual: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deletePropiedadIntelectual', 'id', id);
      const localVarPath = `/propiedades-intelectuales/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Elimina información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransferenciaTecnologica: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteTransferenciaTecnologica', 'id', id);
      const localVarPath = `/transferencias-tecnologicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con artículos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [issnImpreso] ISSN Impreso del articulo
     * @param {string} [issnElectronico] ISSN Electrónico del articulo
     * @param {string} [doi] Doi del articulo
     * @param {string} [nombreRevista] Nombre de la revista del articulo
     * @param {string} [tituloArticulo] Titulo del articulo
     * @param {string} [anioPublicacion] Año de publicación del articulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArticulos: async (
      tipo: Tipo,
      page?: number,
      size?: number,
      issnImpreso?: string,
      issnElectronico?: string,
      doi?: string,
      nombreRevista?: string,
      tituloArticulo?: string,
      anioPublicacion?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getAllArticulos', 'tipo', tipo);
      const localVarPath = `/articulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (issnImpreso !== undefined) {
        localVarQueryParameter['issnImpreso'] = issnImpreso;
      }

      if (issnElectronico !== undefined) {
        localVarQueryParameter['issnElectronico'] = issnElectronico;
      }

      if (doi !== undefined) {
        localVarQueryParameter['doi'] = doi;
      }

      if (nombreRevista !== undefined) {
        localVarQueryParameter['nombreRevista'] = nombreRevista;
      }

      if (tituloArticulo !== undefined) {
        localVarQueryParameter['tituloArticulo'] = tituloArticulo;
      }

      if (anioPublicacion !== undefined) {
        localVarQueryParameter['anioPublicacion'] = anioPublicacion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con capitulos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del capitulo
     * @param {string} [doi] Doi del capitulo
     * @param {string} [tituloLibro] Titulo del libro del capitulo
     * @param {string} [anioPublicacion] Año de publicación del capitulo
     * @param {string} [tituloCapitulo] Titulo del capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCapitulos: async (
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      tituloCapitulo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getAllCapitulos', 'tipo', tipo);
      const localVarPath = `/capitulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (isbn !== undefined) {
        localVarQueryParameter['isbn'] = isbn;
      }

      if (doi !== undefined) {
        localVarQueryParameter['doi'] = doi;
      }

      if (tituloLibro !== undefined) {
        localVarQueryParameter['tituloLibro'] = tituloLibro;
      }

      if (anioPublicacion !== undefined) {
        localVarQueryParameter['anioPublicacion'] = anioPublicacion;
      }

      if (tituloCapitulo !== undefined) {
        localVarQueryParameter['tituloCapitulo'] = tituloCapitulo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCitas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/citas-totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [producto] Producto del desarrollo
     * @param {string} [nombre] Nombre del desarrollo
     * @param {string} [objetivo] Objetivo del desarrollo
     * @param {string} [problema] Problema del desarrollo
     * @param {string} [rol] Rol del desarrollo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDesarrollosTecnologicosInnovaciones: async (
      page?: number,
      size?: number,
      producto?: string,
      nombre?: string,
      objetivo?: string,
      problema?: string,
      rol?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/desarrollos-tecnologicos-innovaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (producto !== undefined) {
        localVarQueryParameter['producto'] = producto;
      }

      if (nombre !== undefined) {
        localVarQueryParameter['nombre'] = nombre;
      }

      if (objetivo !== undefined) {
        localVarQueryParameter['objetivo'] = objetivo;
      }

      if (problema !== undefined) {
        localVarQueryParameter['problema'] = problema;
      }

      if (rol !== undefined) {
        localVarQueryParameter['rol'] = rol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con libros
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del libro
     * @param {string} [doi] Doi del libro
     * @param {string} [tituloLibro] Titulo del libro
     * @param {string} [anioPublicacion] Año de publicación del libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLibros: async (
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getAllLibros', 'tipo', tipo);
      const localVarPath = `/libros`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (isbn !== undefined) {
        localVarQueryParameter['isbn'] = isbn;
      }

      if (doi !== undefined) {
        localVarQueryParameter['doi'] = doi;
      }

      if (tituloLibro !== undefined) {
        localVarQueryParameter['tituloLibro'] = tituloLibro;
      }

      if (anioPublicacion !== undefined) {
        localVarQueryParameter['anioPublicacion'] = anioPublicacion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene información de Propiedades Intelectuales de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
     * @param {string} [titulo] Titulo de la propiedad intelectual
     * @param {string} [rol] Rol de la propiedad intelectual
     * @param {string} [estado] Estado de la propiedad intelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPropiedadesIntelectuales: async (
      page?: number,
      size?: number,
      tipoProteccion?: string,
      titulo?: string,
      rol?: string,
      estado?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/propiedades-intelectuales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipoProteccion !== undefined) {
        localVarQueryParameter['tipoProteccion'] = tipoProteccion;
      }

      if (titulo !== undefined) {
        localVarQueryParameter['titulo'] = titulo;
      }

      if (rol !== undefined) {
        localVarQueryParameter['rol'] = rol;
      }

      if (estado !== undefined) {
        localVarQueryParameter['estado'] = estado;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene información de Transferencias Tecnologicas de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
     * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
     * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
     * @param {string} [rol] Rol de la transferencia tecnológica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransferenciasTecnologicas: async (
      page?: number,
      size?: number,
      tipoActividad?: string,
      fechaInicio?: string,
      fechaFin?: string,
      rol?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transferencias-tecnologicas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipoActividad !== undefined) {
        localVarQueryParameter['tipoActividad'] = tipoActividad;
      }

      if (fechaInicio !== undefined) {
        localVarQueryParameter['fechaInicio'] = fechaInicio;
      }

      if (fechaFin !== undefined) {
        localVarQueryParameter['fechaFin'] = fechaFin;
      }

      if (rol !== undefined) {
        localVarQueryParameter['rol'] = rol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un objeto que contiene todas las aportaciones
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAportacionesCompletas: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cvu' is not null or undefined
      assertParamExists('getAportacionesCompletas', 'cvu', cvu);
      const localVarPath = `/aportaciones-completas/{cvu}`.replace(`{${'cvu'}}`, encodeURIComponent(String(cvu)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales de los documentos guardados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalDocumentos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/total-documentos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales de produccion cientifica
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalProduccion: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalPrincipalProduccion', 'tipo', tipo);
      const localVarPath = `/total-documentos/principal-produccion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales de produccion tecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalTecnologico: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/total-documentos/principal-tecnologico`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesArticulos: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalesArticulos', 'tipo', tipo);
      const localVarPath = `/articulos/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesCapitulos: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalesCapitulos', 'tipo', tipo);
      const localVarPath = `/capitulos/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesDesarrollosTecnologicosInnovaciones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/desarrollos-tecnologicos-innovaciones/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesPropiedadesIntelectuales: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/propiedades-intelectuales/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesTransferenciasTecnologicas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transferencias-tecnologicas/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesibros: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalesibros', 'tipo', tipo);
      const localVarPath = `/libros/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Agrega un nuevo artículo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveArticulo: async (tipo: Tipo, articulo: Articulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('saveArticulo', 'tipo', tipo);
      // verify required parameter 'articulo' is not null or undefined
      assertParamExists('saveArticulo', 'articulo', articulo);
      const localVarPath = `/articulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(articulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Agrega un capítulo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Capitulo} capitulo Información de un capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCapitulo: async (tipo: Tipo, capitulo: Capitulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('saveCapitulo', 'tipo', tipo);
      // verify required parameter 'capitulo' is not null or undefined
      assertParamExists('saveCapitulo', 'capitulo', capitulo);
      const localVarPath = `/capitulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(capitulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDesarrolloTecnologicoInnovacion: async (
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'desarrolloTecnologicoInnovacion' is not null or undefined
      assertParamExists('saveDesarrolloTecnologicoInnovacion', 'desarrolloTecnologicoInnovacion', desarrolloTecnologicoInnovacion);
      const localVarPath = `/desarrollos-tecnologicos-innovaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(desarrolloTecnologicoInnovacion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Agrega un nuevo libro
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveLibro: async (tipo: Tipo, libro: Libro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('saveLibro', 'tipo', tipo);
      // verify required parameter 'libro' is not null or undefined
      assertParamExists('saveLibro', 'libro', libro);
      const localVarPath = `/libros`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(libro, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Almacena información de una Propiedad Intelectual de un usuario
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePropiedadIntelectual: async (
      propiedadIntelectual: PropiedadIntelectual,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propiedadIntelectual' is not null or undefined
      assertParamExists('savePropiedadIntelectual', 'propiedadIntelectual', propiedadIntelectual);
      const localVarPath = `/propiedades-intelectuales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(propiedadIntelectual, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Almacena información de una Transferencia Tecnologica de un usuario
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveTransferenciaTecnologica: async (
      transferenciaTecnologica: TransferenciaTecnologica,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferenciaTecnologica' is not null or undefined
      assertParamExists('saveTransferenciaTecnologica', 'transferenciaTecnologica', transferenciaTecnologica);
      const localVarPath = `/transferencias-tecnologicas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transferenciaTecnologica, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza un artículo
     * @param {string} id Id de la aportación
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateArticulo: async (id: string, articulo: Articulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateArticulo', 'id', id);
      // verify required parameter 'articulo' is not null or undefined
      assertParamExists('updateArticulo', 'articulo', articulo);
      const localVarPath = `/articulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(articulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza un capítulo
     * @param {string} id Id de la aportación
     * @param {Capitulo} capitulo Información de un capítulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCapitulo: async (id: string, capitulo: Capitulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCapitulo', 'id', id);
      // verify required parameter 'capitulo' is not null or undefined
      assertParamExists('updateCapitulo', 'capitulo', capitulo);
      const localVarPath = `/capitulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(capitulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesarrolloTecnologicoInnovacion: async (
      id: string,
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateDesarrolloTecnologicoInnovacion', 'id', id);
      // verify required parameter 'desarrolloTecnologicoInnovacion' is not null or undefined
      assertParamExists('updateDesarrolloTecnologicoInnovacion', 'desarrolloTecnologicoInnovacion', desarrolloTecnologicoInnovacion);
      const localVarPath = `/desarrollos-tecnologicos-innovaciones/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(desarrolloTecnologicoInnovacion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza un libro
     * @param {string} id Id de la aportación
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLibro: async (id: string, libro: Libro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateLibro', 'id', id);
      // verify required parameter 'libro' is not null or undefined
      assertParamExists('updateLibro', 'libro', libro);
      const localVarPath = `/libros/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(libro, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePropiedadIntelectual: async (
      id: string,
      propiedadIntelectual: PropiedadIntelectual,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updatePropiedadIntelectual', 'id', id);
      // verify required parameter 'propiedadIntelectual' is not null or undefined
      assertParamExists('updatePropiedadIntelectual', 'propiedadIntelectual', propiedadIntelectual);
      const localVarPath = `/propiedades-intelectuales/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(propiedadIntelectual, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferenciaTecnologica: async (
      id: string,
      transferenciaTecnologica: TransferenciaTecnologica,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateTransferenciaTecnologica', 'id', id);
      // verify required parameter 'transferenciaTecnologica' is not null or undefined
      assertParamExists('updateTransferenciaTecnologica', 'transferenciaTecnologica', transferenciaTecnologica);
      const localVarPath = `/transferencias-tecnologicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transferenciaTecnologica, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AportacionesApi - functional programming interface
 * @export
 */
export const AportacionesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AportacionesApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina un artículo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteArticulo(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticulo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Elimina un capítulo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCapitulo(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCapitulo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDesarrolloTecnologicoInnovacion(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDesarrolloTecnologicoInnovacion(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Elimina un libro
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLibro(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLibro(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Elimina información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePropiedadIntelectual(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropiedadIntelectual(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Elimina información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTransferenciaTecnologica(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransferenciaTecnologica(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con artículos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [issnImpreso] ISSN Impreso del articulo
     * @param {string} [issnElectronico] ISSN Electrónico del articulo
     * @param {string} [doi] Doi del articulo
     * @param {string} [nombreRevista] Nombre de la revista del articulo
     * @param {string} [tituloArticulo] Titulo del articulo
     * @param {string} [anioPublicacion] Año de publicación del articulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArticulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      issnImpreso?: string,
      issnElectronico?: string,
      doi?: string,
      nombreRevista?: string,
      tituloArticulo?: string,
      anioPublicacion?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Articulo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllArticulos(
        tipo,
        page,
        size,
        issnImpreso,
        issnElectronico,
        doi,
        nombreRevista,
        tituloArticulo,
        anioPublicacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con capitulos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del capitulo
     * @param {string} [doi] Doi del capitulo
     * @param {string} [tituloLibro] Titulo del libro del capitulo
     * @param {string} [anioPublicacion] Año de publicación del capitulo
     * @param {string} [tituloCapitulo] Titulo del capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCapitulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      tituloCapitulo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Capitulo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCapitulos(
        tipo,
        page,
        size,
        isbn,
        doi,
        tituloLibro,
        anioPublicacion,
        tituloCapitulo,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCitas(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CitasTotales>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCitas(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [producto] Producto del desarrollo
     * @param {string} [nombre] Nombre del desarrollo
     * @param {string} [objetivo] Objetivo del desarrollo
     * @param {string} [problema] Problema del desarrollo
     * @param {string} [rol] Rol del desarrollo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDesarrollosTecnologicosInnovaciones(
      page?: number,
      size?: number,
      producto?: string,
      nombre?: string,
      objetivo?: string,
      problema?: string,
      rol?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DesarrolloTecnologicoInnovacion>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDesarrollosTecnologicosInnovaciones(
        page,
        size,
        producto,
        nombre,
        objetivo,
        problema,
        rol,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con libros
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del libro
     * @param {string} [doi] Doi del libro
     * @param {string} [tituloLibro] Titulo del libro
     * @param {string} [anioPublicacion] Año de publicación del libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllLibros(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Libro>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLibros(
        tipo,
        page,
        size,
        isbn,
        doi,
        tituloLibro,
        anioPublicacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene información de Propiedades Intelectuales de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
     * @param {string} [titulo] Titulo de la propiedad intelectual
     * @param {string} [rol] Rol de la propiedad intelectual
     * @param {string} [estado] Estado de la propiedad intelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPropiedadesIntelectuales(
      page?: number,
      size?: number,
      tipoProteccion?: string,
      titulo?: string,
      rol?: string,
      estado?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropiedadIntelectual>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPropiedadesIntelectuales(
        page,
        size,
        tipoProteccion,
        titulo,
        rol,
        estado,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene información de Transferencias Tecnologicas de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
     * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
     * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
     * @param {string} [rol] Rol de la transferencia tecnológica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllTransferenciasTecnologicas(
      page?: number,
      size?: number,
      tipoActividad?: string,
      fechaInicio?: string,
      fechaFin?: string,
      rol?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransferenciaTecnologica>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTransferenciasTecnologicas(
        page,
        size,
        tipoActividad,
        fechaInicio,
        fechaFin,
        rol,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un objeto que contiene todas las aportaciones
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAportacionesCompletas(
      cvu: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AportacionesCompletas>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAportacionesCompletas(cvu, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales de los documentos guardados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalDocumentos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalDocumentos(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales de produccion cientifica
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalPrincipalProduccion(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalPrincipalProduccion(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales de produccion tecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalPrincipalTecnologico(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalPrincipalTecnologico(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesArticulos(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesArticulos(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesCapitulos(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesCapitulos(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesDesarrollosTecnologicosInnovaciones(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesDesarrollosTecnologicosInnovaciones(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesPropiedadesIntelectuales(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesPropiedadesIntelectuales(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesTransferenciasTecnologicas(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesTransferenciasTecnologicas(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesibros(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesibros(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Agrega un nuevo artículo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveArticulo(
      tipo: Tipo,
      articulo: Articulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveArticulo(tipo, articulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Agrega un capítulo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Capitulo} capitulo Información de un capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveCapitulo(
      tipo: Tipo,
      capitulo: Capitulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveCapitulo(tipo, capitulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveDesarrolloTecnologicoInnovacion(
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveDesarrolloTecnologicoInnovacion(
        desarrolloTecnologicoInnovacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Agrega un nuevo libro
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveLibro(
      tipo: Tipo,
      libro: Libro,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveLibro(tipo, libro, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Almacena información de una Propiedad Intelectual de un usuario
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savePropiedadIntelectual(
      propiedadIntelectual: PropiedadIntelectual,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.savePropiedadIntelectual(propiedadIntelectual, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Almacena información de una Transferencia Tecnologica de un usuario
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveTransferenciaTecnologica(
      transferenciaTecnologica: TransferenciaTecnologica,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveTransferenciaTecnologica(transferenciaTecnologica, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza un artículo
     * @param {string} id Id de la aportación
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateArticulo(
      id: string,
      articulo: Articulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticulo(id, articulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza un capítulo
     * @param {string} id Id de la aportación
     * @param {Capitulo} capitulo Información de un capítulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCapitulo(
      id: string,
      capitulo: Capitulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCapitulo(id, capitulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDesarrolloTecnologicoInnovacion(
      id: string,
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDesarrolloTecnologicoInnovacion(
        id,
        desarrolloTecnologicoInnovacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza un libro
     * @param {string} id Id de la aportación
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLibro(
      id: string,
      libro: Libro,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLibro(id, libro, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePropiedadIntelectual(
      id: string,
      propiedadIntelectual: PropiedadIntelectual,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropiedadIntelectual(id, propiedadIntelectual, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTransferenciaTecnologica(
      id: string,
      transferenciaTecnologica: TransferenciaTecnologica,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransferenciaTecnologica(id, transferenciaTecnologica, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AportacionesApi - factory interface
 * @export
 */
export const AportacionesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AportacionesApiFp(configuration);
  return {
    /**
     * Elimina un artículo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteArticulo(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteArticulo(id, options).then(request => request(axios, basePath));
    },
    /**
     * Elimina un capítulo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCapitulo(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCapitulo(id, options).then(request => request(axios, basePath));
    },
    /**
     * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDesarrolloTecnologicoInnovacion(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteDesarrolloTecnologicoInnovacion(id, options).then(request => request(axios, basePath));
    },
    /**
     * Elimina un libro
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLibro(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteLibro(id, options).then(request => request(axios, basePath));
    },
    /**
     * Elimina información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePropiedadIntelectual(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deletePropiedadIntelectual(id, options).then(request => request(axios, basePath));
    },
    /**
     * Elimina información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransferenciaTecnologica(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteTransferenciaTecnologica(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con artículos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [issnImpreso] ISSN Impreso del articulo
     * @param {string} [issnElectronico] ISSN Electrónico del articulo
     * @param {string} [doi] Doi del articulo
     * @param {string} [nombreRevista] Nombre de la revista del articulo
     * @param {string} [tituloArticulo] Titulo del articulo
     * @param {string} [anioPublicacion] Año de publicación del articulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArticulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      issnImpreso?: string,
      issnElectronico?: string,
      doi?: string,
      nombreRevista?: string,
      tituloArticulo?: string,
      anioPublicacion?: string,
      options?: any
    ): AxiosPromise<Array<Articulo>> {
      return localVarFp
        .getAllArticulos(tipo, page, size, issnImpreso, issnElectronico, doi, nombreRevista, tituloArticulo, anioPublicacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con capitulos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del capitulo
     * @param {string} [doi] Doi del capitulo
     * @param {string} [tituloLibro] Titulo del libro del capitulo
     * @param {string} [anioPublicacion] Año de publicación del capitulo
     * @param {string} [tituloCapitulo] Titulo del capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCapitulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      tituloCapitulo?: string,
      options?: any
    ): AxiosPromise<Array<Capitulo>> {
      return localVarFp
        .getAllCapitulos(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, tituloCapitulo, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCitas(options?: any): AxiosPromise<Array<CitasTotales>> {
      return localVarFp.getAllCitas(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [producto] Producto del desarrollo
     * @param {string} [nombre] Nombre del desarrollo
     * @param {string} [objetivo] Objetivo del desarrollo
     * @param {string} [problema] Problema del desarrollo
     * @param {string} [rol] Rol del desarrollo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDesarrollosTecnologicosInnovaciones(
      page?: number,
      size?: number,
      producto?: string,
      nombre?: string,
      objetivo?: string,
      problema?: string,
      rol?: string,
      options?: any
    ): AxiosPromise<Array<DesarrolloTecnologicoInnovacion>> {
      return localVarFp
        .getAllDesarrollosTecnologicosInnovaciones(page, size, producto, nombre, objetivo, problema, rol, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con libros
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del libro
     * @param {string} [doi] Doi del libro
     * @param {string} [tituloLibro] Titulo del libro
     * @param {string} [anioPublicacion] Año de publicación del libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLibros(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      options?: any
    ): AxiosPromise<Array<Libro>> {
      return localVarFp
        .getAllLibros(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene información de Propiedades Intelectuales de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
     * @param {string} [titulo] Titulo de la propiedad intelectual
     * @param {string} [rol] Rol de la propiedad intelectual
     * @param {string} [estado] Estado de la propiedad intelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPropiedadesIntelectuales(
      page?: number,
      size?: number,
      tipoProteccion?: string,
      titulo?: string,
      rol?: string,
      estado?: string,
      options?: any
    ): AxiosPromise<Array<PropiedadIntelectual>> {
      return localVarFp
        .getAllPropiedadesIntelectuales(page, size, tipoProteccion, titulo, rol, estado, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene información de Transferencias Tecnologicas de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
     * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
     * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
     * @param {string} [rol] Rol de la transferencia tecnológica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransferenciasTecnologicas(
      page?: number,
      size?: number,
      tipoActividad?: string,
      fechaInicio?: string,
      fechaFin?: string,
      rol?: string,
      options?: any
    ): AxiosPromise<Array<TransferenciaTecnologica>> {
      return localVarFp
        .getAllTransferenciasTecnologicas(page, size, tipoActividad, fechaInicio, fechaFin, rol, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene un objeto que contiene todas las aportaciones
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAportacionesCompletas(cvu: string, options?: any): AxiosPromise<AportacionesCompletas> {
      return localVarFp.getAportacionesCompletas(cvu, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales de los documentos guardados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalDocumentos(options?: any): AxiosPromise<number> {
      return localVarFp.getTotalDocumentos(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales de produccion cientifica
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalProduccion(tipo: Tipo, options?: any): AxiosPromise<number> {
      return localVarFp.getTotalPrincipalProduccion(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales de produccion tecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalTecnologico(options?: any): AxiosPromise<number> {
      return localVarFp.getTotalPrincipalTecnologico(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesArticulos(tipo: Tipo, options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesArticulos(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesCapitulos(tipo: Tipo, options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesCapitulos(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesDesarrollosTecnologicosInnovaciones(options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesDesarrollosTecnologicosInnovaciones(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesPropiedadesIntelectuales(options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesPropiedadesIntelectuales(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesTransferenciasTecnologicas(options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesTransferenciasTecnologicas(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesibros(tipo: Tipo, options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesibros(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Agrega un nuevo artículo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveArticulo(tipo: Tipo, articulo: Articulo, options?: any): AxiosPromise<void> {
      return localVarFp.saveArticulo(tipo, articulo, options).then(request => request(axios, basePath));
    },
    /**
     * Agrega un capítulo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Capitulo} capitulo Información de un capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCapitulo(tipo: Tipo, capitulo: Capitulo, options?: any): AxiosPromise<void> {
      return localVarFp.saveCapitulo(tipo, capitulo, options).then(request => request(axios, basePath));
    },
    /**
     * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDesarrolloTecnologicoInnovacion(
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .saveDesarrolloTecnologicoInnovacion(desarrolloTecnologicoInnovacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Agrega un nuevo libro
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveLibro(tipo: Tipo, libro: Libro, options?: any): AxiosPromise<void> {
      return localVarFp.saveLibro(tipo, libro, options).then(request => request(axios, basePath));
    },
    /**
     * Almacena información de una Propiedad Intelectual de un usuario
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePropiedadIntelectual(propiedadIntelectual: PropiedadIntelectual, options?: any): AxiosPromise<void> {
      return localVarFp.savePropiedadIntelectual(propiedadIntelectual, options).then(request => request(axios, basePath));
    },
    /**
     * Almacena información de una Transferencia Tecnologica de un usuario
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveTransferenciaTecnologica(transferenciaTecnologica: TransferenciaTecnologica, options?: any): AxiosPromise<void> {
      return localVarFp.saveTransferenciaTecnologica(transferenciaTecnologica, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza un artículo
     * @param {string} id Id de la aportación
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateArticulo(id: string, articulo: Articulo, options?: any): AxiosPromise<void> {
      return localVarFp.updateArticulo(id, articulo, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza un capítulo
     * @param {string} id Id de la aportación
     * @param {Capitulo} capitulo Información de un capítulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCapitulo(id: string, capitulo: Capitulo, options?: any): AxiosPromise<void> {
      return localVarFp.updateCapitulo(id, capitulo, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesarrolloTecnologicoInnovacion(
      id: string,
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateDesarrolloTecnologicoInnovacion(id, desarrolloTecnologicoInnovacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Actualiza un libro
     * @param {string} id Id de la aportación
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLibro(id: string, libro: Libro, options?: any): AxiosPromise<void> {
      return localVarFp.updateLibro(id, libro, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePropiedadIntelectual(id: string, propiedadIntelectual: PropiedadIntelectual, options?: any): AxiosPromise<void> {
      return localVarFp.updatePropiedadIntelectual(id, propiedadIntelectual, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferenciaTecnologica(id: string, transferenciaTecnologica: TransferenciaTecnologica, options?: any): AxiosPromise<void> {
      return localVarFp.updateTransferenciaTecnologica(id, transferenciaTecnologica, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AportacionesApi - object-oriented interface
 * @export
 * @class AportacionesApi
 * @extends {BaseAPI}
 */
export class AportacionesApi extends BaseAPI {
  /**
   * Elimina un artículo
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public deleteArticulo(id: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .deleteArticulo(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Elimina un capítulo
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public deleteCapitulo(id: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .deleteCapitulo(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public deleteDesarrolloTecnologicoInnovacion(id: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .deleteDesarrolloTecnologicoInnovacion(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Elimina un libro
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public deleteLibro(id: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .deleteLibro(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Elimina información de una Propiedad Intelectual de un usuario
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public deletePropiedadIntelectual(id: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .deletePropiedadIntelectual(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Elimina información de una Transferencia Tecnologica de un usuario
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public deleteTransferenciaTecnologica(id: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .deleteTransferenciaTecnologica(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con artículos
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [issnImpreso] ISSN Impreso del articulo
   * @param {string} [issnElectronico] ISSN Electrónico del articulo
   * @param {string} [doi] Doi del articulo
   * @param {string} [nombreRevista] Nombre de la revista del articulo
   * @param {string} [tituloArticulo] Titulo del articulo
   * @param {string} [anioPublicacion] Año de publicación del articulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllArticulos(
    tipo: Tipo,
    page?: number,
    size?: number,
    issnImpreso?: string,
    issnElectronico?: string,
    doi?: string,
    nombreRevista?: string,
    tituloArticulo?: string,
    anioPublicacion?: string,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .getAllArticulos(tipo, page, size, issnImpreso, issnElectronico, doi, nombreRevista, tituloArticulo, anioPublicacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con capitulos
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [isbn] ISBN del capitulo
   * @param {string} [doi] Doi del capitulo
   * @param {string} [tituloLibro] Titulo del libro del capitulo
   * @param {string} [anioPublicacion] Año de publicación del capitulo
   * @param {string} [tituloCapitulo] Titulo del capitulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllCapitulos(
    tipo: Tipo,
    page?: number,
    size?: number,
    isbn?: string,
    doi?: string,
    tituloLibro?: string,
    anioPublicacion?: string,
    tituloCapitulo?: string,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .getAllCapitulos(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, tituloCapitulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllCitas(options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getAllCitas(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [producto] Producto del desarrollo
   * @param {string} [nombre] Nombre del desarrollo
   * @param {string} [objetivo] Objetivo del desarrollo
   * @param {string} [problema] Problema del desarrollo
   * @param {string} [rol] Rol del desarrollo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllDesarrollosTecnologicosInnovaciones(
    page?: number,
    size?: number,
    producto?: string,
    nombre?: string,
    objetivo?: string,
    problema?: string,
    rol?: string,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .getAllDesarrollosTecnologicosInnovaciones(page, size, producto, nombre, objetivo, problema, rol, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con libros
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [isbn] ISBN del libro
   * @param {string} [doi] Doi del libro
   * @param {string} [tituloLibro] Titulo del libro
   * @param {string} [anioPublicacion] Año de publicación del libro
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllLibros(
    tipo: Tipo,
    page?: number,
    size?: number,
    isbn?: string,
    doi?: string,
    tituloLibro?: string,
    anioPublicacion?: string,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .getAllLibros(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene información de Propiedades Intelectuales de un usuario
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
   * @param {string} [titulo] Titulo de la propiedad intelectual
   * @param {string} [rol] Rol de la propiedad intelectual
   * @param {string} [estado] Estado de la propiedad intelectual
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllPropiedadesIntelectuales(
    page?: number,
    size?: number,
    tipoProteccion?: string,
    titulo?: string,
    rol?: string,
    estado?: string,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .getAllPropiedadesIntelectuales(page, size, tipoProteccion, titulo, rol, estado, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene información de Transferencias Tecnologicas de un usuario
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
   * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
   * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
   * @param {string} [rol] Rol de la transferencia tecnológica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAllTransferenciasTecnologicas(
    page?: number,
    size?: number,
    tipoActividad?: string,
    fechaInicio?: string,
    fechaFin?: string,
    rol?: string,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .getAllTransferenciasTecnologicas(page, size, tipoActividad, fechaInicio, fechaFin, rol, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un objeto que contiene todas las aportaciones
   * @param {string} cvu cvu del usuario
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getAportacionesCompletas(cvu: string, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getAportacionesCompletas(cvu, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales de los documentos guardados
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalDocumentos(options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalDocumentos(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales de produccion cientifica
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalPrincipalProduccion(tipo: Tipo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalPrincipalProduccion(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales de produccion tecnologica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalPrincipalTecnologico(options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalPrincipalTecnologico(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalesArticulos(tipo: Tipo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalesArticulos(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalesCapitulos(tipo: Tipo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalesCapitulos(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalesDesarrollosTecnologicosInnovaciones(options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalesDesarrollosTecnologicosInnovaciones(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalesPropiedadesIntelectuales(options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalesPropiedadesIntelectuales(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalesTransferenciasTecnologicas(options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalesTransferenciasTecnologicas(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public getTotalesibros(tipo: Tipo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .getTotalesibros(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Agrega un nuevo artículo
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {Articulo} articulo Información de un artículo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public saveArticulo(tipo: Tipo, articulo: Articulo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .saveArticulo(tipo, articulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Agrega un capítulo
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {Capitulo} capitulo Información de un capitulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public saveCapitulo(tipo: Tipo, capitulo: Capitulo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .saveCapitulo(tipo, capitulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
   * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public saveDesarrolloTecnologicoInnovacion(
    desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .saveDesarrolloTecnologicoInnovacion(desarrolloTecnologicoInnovacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Agrega un nuevo libro
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {Libro} libro Información de un libro
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public saveLibro(tipo: Tipo, libro: Libro, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .saveLibro(tipo, libro, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Almacena información de una Propiedad Intelectual de un usuario
   * @param {PropiedadIntelectual} propiedadIntelectual
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public savePropiedadIntelectual(propiedadIntelectual: PropiedadIntelectual, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .savePropiedadIntelectual(propiedadIntelectual, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Almacena información de una Transferencia Tecnologica de un usuario
   * @param {TransferenciaTecnologica} transferenciaTecnologica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public saveTransferenciaTecnologica(transferenciaTecnologica: TransferenciaTecnologica, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .saveTransferenciaTecnologica(transferenciaTecnologica, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza un artículo
   * @param {string} id Id de la aportación
   * @param {Articulo} articulo Información de un artículo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public updateArticulo(id: string, articulo: Articulo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .updateArticulo(id, articulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza un capítulo
   * @param {string} id Id de la aportación
   * @param {Capitulo} capitulo Información de un capítulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public updateCapitulo(id: string, capitulo: Capitulo, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .updateCapitulo(id, capitulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
   * @param {string} id Id de la aportación
   * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public updateDesarrolloTecnologicoInnovacion(
    id: string,
    desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
    options?: AxiosRequestConfig
  ) {
    return AportacionesApiFp(this.configuration)
      .updateDesarrolloTecnologicoInnovacion(id, desarrolloTecnologicoInnovacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza un libro
   * @param {string} id Id de la aportación
   * @param {Libro} libro Información de un libro
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public updateLibro(id: string, libro: Libro, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .updateLibro(id, libro, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza información de una Propiedad Intelectual de un usuario
   * @param {string} id Id de la aportación
   * @param {PropiedadIntelectual} propiedadIntelectual
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public updatePropiedadIntelectual(id: string, propiedadIntelectual: PropiedadIntelectual, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .updatePropiedadIntelectual(id, propiedadIntelectual, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza información de una Transferencia Tecnologica de un usuario
   * @param {string} id Id de la aportación
   * @param {TransferenciaTecnologica} transferenciaTecnologica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesApi
   */
  public updateTransferenciaTecnologica(id: string, transferenciaTecnologica: TransferenciaTecnologica, options?: AxiosRequestConfig) {
    return AportacionesApiFp(this.configuration)
      .updateTransferenciaTecnologica(id, transferenciaTecnologica, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AportacionesCompletasApi - axios parameter creator
 * @export
 */
export const AportacionesCompletasApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Obtiene un objeto que contiene todas las aportaciones
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAportacionesCompletas: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cvu' is not null or undefined
      assertParamExists('getAportacionesCompletas', 'cvu', cvu);
      const localVarPath = `/aportaciones-completas/{cvu}`.replace(`{${'cvu'}}`, encodeURIComponent(String(cvu)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AportacionesCompletasApi - functional programming interface
 * @export
 */
export const AportacionesCompletasApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AportacionesCompletasApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtiene un objeto que contiene todas las aportaciones
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAportacionesCompletas(
      cvu: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AportacionesCompletas>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAportacionesCompletas(cvu, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AportacionesCompletasApi - factory interface
 * @export
 */
export const AportacionesCompletasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AportacionesCompletasApiFp(configuration);
  return {
    /**
     * Obtiene un objeto que contiene todas las aportaciones
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAportacionesCompletas(cvu: string, options?: any): AxiosPromise<AportacionesCompletas> {
      return localVarFp.getAportacionesCompletas(cvu, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AportacionesCompletasApi - object-oriented interface
 * @export
 * @class AportacionesCompletasApi
 * @extends {BaseAPI}
 */
export class AportacionesCompletasApi extends BaseAPI {
  /**
   * Obtiene un objeto que contiene todas las aportaciones
   * @param {string} cvu cvu del usuario
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AportacionesCompletasApi
   */
  public getAportacionesCompletas(cvu: string, options?: AxiosRequestConfig) {
    return AportacionesCompletasApiFp(this.configuration)
      .getAportacionesCompletas(cvu, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ArticulosApi - axios parameter creator
 * @export
 */
export const ArticulosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina un artículo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteArticulo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteArticulo', 'id', id);
      const localVarPath = `/articulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con artículos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [issnImpreso] ISSN Impreso del articulo
     * @param {string} [issnElectronico] ISSN Electrónico del articulo
     * @param {string} [doi] Doi del articulo
     * @param {string} [nombreRevista] Nombre de la revista del articulo
     * @param {string} [tituloArticulo] Titulo del articulo
     * @param {string} [anioPublicacion] Año de publicación del articulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArticulos: async (
      tipo: Tipo,
      page?: number,
      size?: number,
      issnImpreso?: string,
      issnElectronico?: string,
      doi?: string,
      nombreRevista?: string,
      tituloArticulo?: string,
      anioPublicacion?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getAllArticulos', 'tipo', tipo);
      const localVarPath = `/articulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (issnImpreso !== undefined) {
        localVarQueryParameter['issnImpreso'] = issnImpreso;
      }

      if (issnElectronico !== undefined) {
        localVarQueryParameter['issnElectronico'] = issnElectronico;
      }

      if (doi !== undefined) {
        localVarQueryParameter['doi'] = doi;
      }

      if (nombreRevista !== undefined) {
        localVarQueryParameter['nombreRevista'] = nombreRevista;
      }

      if (tituloArticulo !== undefined) {
        localVarQueryParameter['tituloArticulo'] = tituloArticulo;
      }

      if (anioPublicacion !== undefined) {
        localVarQueryParameter['anioPublicacion'] = anioPublicacion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesArticulos: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalesArticulos', 'tipo', tipo);
      const localVarPath = `/articulos/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Agrega un nuevo artículo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveArticulo: async (tipo: Tipo, articulo: Articulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('saveArticulo', 'tipo', tipo);
      // verify required parameter 'articulo' is not null or undefined
      assertParamExists('saveArticulo', 'articulo', articulo);
      const localVarPath = `/articulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(articulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza un artículo
     * @param {string} id Id de la aportación
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateArticulo: async (id: string, articulo: Articulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateArticulo', 'id', id);
      // verify required parameter 'articulo' is not null or undefined
      assertParamExists('updateArticulo', 'articulo', articulo);
      const localVarPath = `/articulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(articulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ArticulosApi - functional programming interface
 * @export
 */
export const ArticulosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticulosApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina un artículo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteArticulo(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticulo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con artículos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [issnImpreso] ISSN Impreso del articulo
     * @param {string} [issnElectronico] ISSN Electrónico del articulo
     * @param {string} [doi] Doi del articulo
     * @param {string} [nombreRevista] Nombre de la revista del articulo
     * @param {string} [tituloArticulo] Titulo del articulo
     * @param {string} [anioPublicacion] Año de publicación del articulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllArticulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      issnImpreso?: string,
      issnElectronico?: string,
      doi?: string,
      nombreRevista?: string,
      tituloArticulo?: string,
      anioPublicacion?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Articulo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllArticulos(
        tipo,
        page,
        size,
        issnImpreso,
        issnElectronico,
        doi,
        nombreRevista,
        tituloArticulo,
        anioPublicacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesArticulos(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesArticulos(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Agrega un nuevo artículo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveArticulo(
      tipo: Tipo,
      articulo: Articulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveArticulo(tipo, articulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza un artículo
     * @param {string} id Id de la aportación
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateArticulo(
      id: string,
      articulo: Articulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticulo(id, articulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ArticulosApi - factory interface
 * @export
 */
export const ArticulosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ArticulosApiFp(configuration);
  return {
    /**
     * Elimina un artículo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteArticulo(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteArticulo(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con artículos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [issnImpreso] ISSN Impreso del articulo
     * @param {string} [issnElectronico] ISSN Electrónico del articulo
     * @param {string} [doi] Doi del articulo
     * @param {string} [nombreRevista] Nombre de la revista del articulo
     * @param {string} [tituloArticulo] Titulo del articulo
     * @param {string} [anioPublicacion] Año de publicación del articulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllArticulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      issnImpreso?: string,
      issnElectronico?: string,
      doi?: string,
      nombreRevista?: string,
      tituloArticulo?: string,
      anioPublicacion?: string,
      options?: any
    ): AxiosPromise<Array<Articulo>> {
      return localVarFp
        .getAllArticulos(tipo, page, size, issnImpreso, issnElectronico, doi, nombreRevista, tituloArticulo, anioPublicacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesArticulos(tipo: Tipo, options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesArticulos(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Agrega un nuevo artículo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveArticulo(tipo: Tipo, articulo: Articulo, options?: any): AxiosPromise<void> {
      return localVarFp.saveArticulo(tipo, articulo, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza un artículo
     * @param {string} id Id de la aportación
     * @param {Articulo} articulo Información de un artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateArticulo(id: string, articulo: Articulo, options?: any): AxiosPromise<void> {
      return localVarFp.updateArticulo(id, articulo, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ArticulosApi - object-oriented interface
 * @export
 * @class ArticulosApi
 * @extends {BaseAPI}
 */
export class ArticulosApi extends BaseAPI {
  /**
   * Elimina un artículo
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticulosApi
   */
  public deleteArticulo(id: string, options?: AxiosRequestConfig) {
    return ArticulosApiFp(this.configuration)
      .deleteArticulo(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con artículos
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [issnImpreso] ISSN Impreso del articulo
   * @param {string} [issnElectronico] ISSN Electrónico del articulo
   * @param {string} [doi] Doi del articulo
   * @param {string} [nombreRevista] Nombre de la revista del articulo
   * @param {string} [tituloArticulo] Titulo del articulo
   * @param {string} [anioPublicacion] Año de publicación del articulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticulosApi
   */
  public getAllArticulos(
    tipo: Tipo,
    page?: number,
    size?: number,
    issnImpreso?: string,
    issnElectronico?: string,
    doi?: string,
    nombreRevista?: string,
    tituloArticulo?: string,
    anioPublicacion?: string,
    options?: AxiosRequestConfig
  ) {
    return ArticulosApiFp(this.configuration)
      .getAllArticulos(tipo, page, size, issnImpreso, issnElectronico, doi, nombreRevista, tituloArticulo, anioPublicacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de artículos para la solicitud de SNI
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticulosApi
   */
  public getTotalesArticulos(tipo: Tipo, options?: AxiosRequestConfig) {
    return ArticulosApiFp(this.configuration)
      .getTotalesArticulos(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Agrega un nuevo artículo
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {Articulo} articulo Información de un artículo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticulosApi
   */
  public saveArticulo(tipo: Tipo, articulo: Articulo, options?: AxiosRequestConfig) {
    return ArticulosApiFp(this.configuration)
      .saveArticulo(tipo, articulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza un artículo
   * @param {string} id Id de la aportación
   * @param {Articulo} articulo Información de un artículo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticulosApi
   */
  public updateArticulo(id: string, articulo: Articulo, options?: AxiosRequestConfig) {
    return ArticulosApiFp(this.configuration)
      .updateArticulo(id, articulo, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CapitulosApi - axios parameter creator
 * @export
 */
export const CapitulosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina un capítulo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCapitulo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteCapitulo', 'id', id);
      const localVarPath = `/capitulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con capitulos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del capitulo
     * @param {string} [doi] Doi del capitulo
     * @param {string} [tituloLibro] Titulo del libro del capitulo
     * @param {string} [anioPublicacion] Año de publicación del capitulo
     * @param {string} [tituloCapitulo] Titulo del capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCapitulos: async (
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      tituloCapitulo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getAllCapitulos', 'tipo', tipo);
      const localVarPath = `/capitulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (isbn !== undefined) {
        localVarQueryParameter['isbn'] = isbn;
      }

      if (doi !== undefined) {
        localVarQueryParameter['doi'] = doi;
      }

      if (tituloLibro !== undefined) {
        localVarQueryParameter['tituloLibro'] = tituloLibro;
      }

      if (anioPublicacion !== undefined) {
        localVarQueryParameter['anioPublicacion'] = anioPublicacion;
      }

      if (tituloCapitulo !== undefined) {
        localVarQueryParameter['tituloCapitulo'] = tituloCapitulo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesCapitulos: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalesCapitulos', 'tipo', tipo);
      const localVarPath = `/capitulos/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Agrega un capítulo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Capitulo} capitulo Información de un capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCapitulo: async (tipo: Tipo, capitulo: Capitulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('saveCapitulo', 'tipo', tipo);
      // verify required parameter 'capitulo' is not null or undefined
      assertParamExists('saveCapitulo', 'capitulo', capitulo);
      const localVarPath = `/capitulos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(capitulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza un capítulo
     * @param {string} id Id de la aportación
     * @param {Capitulo} capitulo Información de un capítulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCapitulo: async (id: string, capitulo: Capitulo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateCapitulo', 'id', id);
      // verify required parameter 'capitulo' is not null or undefined
      assertParamExists('updateCapitulo', 'capitulo', capitulo);
      const localVarPath = `/capitulos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(capitulo, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CapitulosApi - functional programming interface
 * @export
 */
export const CapitulosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CapitulosApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina un capítulo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteCapitulo(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCapitulo(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con capitulos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del capitulo
     * @param {string} [doi] Doi del capitulo
     * @param {string} [tituloLibro] Titulo del libro del capitulo
     * @param {string} [anioPublicacion] Año de publicación del capitulo
     * @param {string} [tituloCapitulo] Titulo del capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCapitulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      tituloCapitulo?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Capitulo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCapitulos(
        tipo,
        page,
        size,
        isbn,
        doi,
        tituloLibro,
        anioPublicacion,
        tituloCapitulo,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesCapitulos(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesCapitulos(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Agrega un capítulo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Capitulo} capitulo Información de un capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveCapitulo(
      tipo: Tipo,
      capitulo: Capitulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveCapitulo(tipo, capitulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza un capítulo
     * @param {string} id Id de la aportación
     * @param {Capitulo} capitulo Información de un capítulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCapitulo(
      id: string,
      capitulo: Capitulo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCapitulo(id, capitulo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CapitulosApi - factory interface
 * @export
 */
export const CapitulosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CapitulosApiFp(configuration);
  return {
    /**
     * Elimina un capítulo
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteCapitulo(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteCapitulo(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con capitulos
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del capitulo
     * @param {string} [doi] Doi del capitulo
     * @param {string} [tituloLibro] Titulo del libro del capitulo
     * @param {string} [anioPublicacion] Año de publicación del capitulo
     * @param {string} [tituloCapitulo] Titulo del capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCapitulos(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      tituloCapitulo?: string,
      options?: any
    ): AxiosPromise<Array<Capitulo>> {
      return localVarFp
        .getAllCapitulos(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, tituloCapitulo, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesCapitulos(tipo: Tipo, options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesCapitulos(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Agrega un capítulo
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Capitulo} capitulo Información de un capitulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveCapitulo(tipo: Tipo, capitulo: Capitulo, options?: any): AxiosPromise<void> {
      return localVarFp.saveCapitulo(tipo, capitulo, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza un capítulo
     * @param {string} id Id de la aportación
     * @param {Capitulo} capitulo Información de un capítulo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCapitulo(id: string, capitulo: Capitulo, options?: any): AxiosPromise<void> {
      return localVarFp.updateCapitulo(id, capitulo, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CapitulosApi - object-oriented interface
 * @export
 * @class CapitulosApi
 * @extends {BaseAPI}
 */
export class CapitulosApi extends BaseAPI {
  /**
   * Elimina un capítulo
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CapitulosApi
   */
  public deleteCapitulo(id: string, options?: AxiosRequestConfig) {
    return CapitulosApiFp(this.configuration)
      .deleteCapitulo(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con capitulos
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [isbn] ISBN del capitulo
   * @param {string} [doi] Doi del capitulo
   * @param {string} [tituloLibro] Titulo del libro del capitulo
   * @param {string} [anioPublicacion] Año de publicación del capitulo
   * @param {string} [tituloCapitulo] Titulo del capitulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CapitulosApi
   */
  public getAllCapitulos(
    tipo: Tipo,
    page?: number,
    size?: number,
    isbn?: string,
    doi?: string,
    tituloLibro?: string,
    anioPublicacion?: string,
    tituloCapitulo?: string,
    options?: AxiosRequestConfig
  ) {
    return CapitulosApiFp(this.configuration)
      .getAllCapitulos(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, tituloCapitulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales completos generales y principales de capitulos para la solicitud de SNI
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CapitulosApi
   */
  public getTotalesCapitulos(tipo: Tipo, options?: AxiosRequestConfig) {
    return CapitulosApiFp(this.configuration)
      .getTotalesCapitulos(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Agrega un capítulo
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {Capitulo} capitulo Información de un capitulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CapitulosApi
   */
  public saveCapitulo(tipo: Tipo, capitulo: Capitulo, options?: AxiosRequestConfig) {
    return CapitulosApiFp(this.configuration)
      .saveCapitulo(tipo, capitulo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza un capítulo
   * @param {string} id Id de la aportación
   * @param {Capitulo} capitulo Información de un capítulo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CapitulosApi
   */
  public updateCapitulo(id: string, capitulo: Capitulo, options?: AxiosRequestConfig) {
    return CapitulosApiFp(this.configuration)
      .updateCapitulo(id, capitulo, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CitasApi - axios parameter creator
 * @export
 */
export const CitasApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCitas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/citas-totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CitasApi - functional programming interface
 * @export
 */
export const CitasApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CitasApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCitas(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CitasTotales>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCitas(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CitasApi - factory interface
 * @export
 */
export const CitasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CitasApiFp(configuration);
  return {
    /**
     * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCitas(options?: any): AxiosPromise<Array<CitasTotales>> {
      return localVarFp.getAllCitas(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * CitasApi - object-oriented interface
 * @export
 * @class CitasApi
 * @extends {BaseAPI}
 */
export class CitasApi extends BaseAPI {
  /**
   * Obtiene un listado con las sumatorias de los tipos de citas de publicaciones científicas
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CitasApi
   */
  public getAllCitas(options?: AxiosRequestConfig) {
    return CitasApiFp(this.configuration)
      .getAllCitas(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DesarrollosTecnologicosInnovacionesApi - axios parameter creator
 * @export
 */
export const DesarrollosTecnologicosInnovacionesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDesarrolloTecnologicoInnovacion: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteDesarrolloTecnologicoInnovacion', 'id', id);
      const localVarPath = `/desarrollos-tecnologicos-innovaciones/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [producto] Producto del desarrollo
     * @param {string} [nombre] Nombre del desarrollo
     * @param {string} [objetivo] Objetivo del desarrollo
     * @param {string} [problema] Problema del desarrollo
     * @param {string} [rol] Rol del desarrollo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDesarrollosTecnologicosInnovaciones: async (
      page?: number,
      size?: number,
      producto?: string,
      nombre?: string,
      objetivo?: string,
      problema?: string,
      rol?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/desarrollos-tecnologicos-innovaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (producto !== undefined) {
        localVarQueryParameter['producto'] = producto;
      }

      if (nombre !== undefined) {
        localVarQueryParameter['nombre'] = nombre;
      }

      if (objetivo !== undefined) {
        localVarQueryParameter['objetivo'] = objetivo;
      }

      if (problema !== undefined) {
        localVarQueryParameter['problema'] = problema;
      }

      if (rol !== undefined) {
        localVarQueryParameter['rol'] = rol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesDesarrollosTecnologicosInnovaciones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/desarrollos-tecnologicos-innovaciones/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDesarrolloTecnologicoInnovacion: async (
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'desarrolloTecnologicoInnovacion' is not null or undefined
      assertParamExists('saveDesarrolloTecnologicoInnovacion', 'desarrolloTecnologicoInnovacion', desarrolloTecnologicoInnovacion);
      const localVarPath = `/desarrollos-tecnologicos-innovaciones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(desarrolloTecnologicoInnovacion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesarrolloTecnologicoInnovacion: async (
      id: string,
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateDesarrolloTecnologicoInnovacion', 'id', id);
      // verify required parameter 'desarrolloTecnologicoInnovacion' is not null or undefined
      assertParamExists('updateDesarrolloTecnologicoInnovacion', 'desarrolloTecnologicoInnovacion', desarrolloTecnologicoInnovacion);
      const localVarPath = `/desarrollos-tecnologicos-innovaciones/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(desarrolloTecnologicoInnovacion, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DesarrollosTecnologicosInnovacionesApi - functional programming interface
 * @export
 */
export const DesarrollosTecnologicosInnovacionesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DesarrollosTecnologicosInnovacionesApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDesarrolloTecnologicoInnovacion(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDesarrolloTecnologicoInnovacion(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [producto] Producto del desarrollo
     * @param {string} [nombre] Nombre del desarrollo
     * @param {string} [objetivo] Objetivo del desarrollo
     * @param {string} [problema] Problema del desarrollo
     * @param {string} [rol] Rol del desarrollo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllDesarrollosTecnologicosInnovaciones(
      page?: number,
      size?: number,
      producto?: string,
      nombre?: string,
      objetivo?: string,
      problema?: string,
      rol?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DesarrolloTecnologicoInnovacion>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDesarrollosTecnologicosInnovaciones(
        page,
        size,
        producto,
        nombre,
        objetivo,
        problema,
        rol,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesDesarrollosTecnologicosInnovaciones(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesDesarrollosTecnologicosInnovaciones(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveDesarrolloTecnologicoInnovacion(
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveDesarrolloTecnologicoInnovacion(
        desarrolloTecnologicoInnovacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDesarrolloTecnologicoInnovacion(
      id: string,
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDesarrolloTecnologicoInnovacion(
        id,
        desarrolloTecnologicoInnovacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DesarrollosTecnologicosInnovacionesApi - factory interface
 * @export
 */
export const DesarrollosTecnologicosInnovacionesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DesarrollosTecnologicosInnovacionesApiFp(configuration);
  return {
    /**
     * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDesarrolloTecnologicoInnovacion(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteDesarrolloTecnologicoInnovacion(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [producto] Producto del desarrollo
     * @param {string} [nombre] Nombre del desarrollo
     * @param {string} [objetivo] Objetivo del desarrollo
     * @param {string} [problema] Problema del desarrollo
     * @param {string} [rol] Rol del desarrollo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllDesarrollosTecnologicosInnovaciones(
      page?: number,
      size?: number,
      producto?: string,
      nombre?: string,
      objetivo?: string,
      problema?: string,
      rol?: string,
      options?: any
    ): AxiosPromise<Array<DesarrolloTecnologicoInnovacion>> {
      return localVarFp
        .getAllDesarrollosTecnologicosInnovaciones(page, size, producto, nombre, objetivo, problema, rol, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesDesarrollosTecnologicosInnovaciones(options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesDesarrollosTecnologicosInnovaciones(options).then(request => request(axios, basePath));
    },
    /**
     * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDesarrolloTecnologicoInnovacion(
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .saveDesarrolloTecnologicoInnovacion(desarrolloTecnologicoInnovacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
     * @param {string} id Id de la aportación
     * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDesarrolloTecnologicoInnovacion(
      id: string,
      desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateDesarrolloTecnologicoInnovacion(id, desarrolloTecnologicoInnovacion, options)
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * DesarrollosTecnologicosInnovacionesApi - object-oriented interface
 * @export
 * @class DesarrollosTecnologicosInnovacionesApi
 * @extends {BaseAPI}
 */
export class DesarrollosTecnologicosInnovacionesApi extends BaseAPI {
  /**
   * Elimina información de un Desarrollo Tecnologico o Innovacion de un usuario
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DesarrollosTecnologicosInnovacionesApi
   */
  public deleteDesarrolloTecnologicoInnovacion(id: string, options?: AxiosRequestConfig) {
    return DesarrollosTecnologicosInnovacionesApiFp(this.configuration)
      .deleteDesarrolloTecnologicoInnovacion(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene información de Desarrollos Tecnologicos o Innovaciones de un usuario
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [producto] Producto del desarrollo
   * @param {string} [nombre] Nombre del desarrollo
   * @param {string} [objetivo] Objetivo del desarrollo
   * @param {string} [problema] Problema del desarrollo
   * @param {string} [rol] Rol del desarrollo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DesarrollosTecnologicosInnovacionesApi
   */
  public getAllDesarrollosTecnologicosInnovaciones(
    page?: number,
    size?: number,
    producto?: string,
    nombre?: string,
    objetivo?: string,
    problema?: string,
    rol?: string,
    options?: AxiosRequestConfig
  ) {
    return DesarrollosTecnologicosInnovacionesApiFp(this.configuration)
      .getAllDesarrollosTecnologicosInnovaciones(page, size, producto, nombre, objetivo, problema, rol, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de desarrollos-tecnologicos-innovaciones para la solicitud de SNI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DesarrollosTecnologicosInnovacionesApi
   */
  public getTotalesDesarrollosTecnologicosInnovaciones(options?: AxiosRequestConfig) {
    return DesarrollosTecnologicosInnovacionesApiFp(this.configuration)
      .getTotalesDesarrollosTecnologicosInnovaciones(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Almacena información de un Desarrollo Tecnologico o Innovacion de un usuario
   * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DesarrollosTecnologicosInnovacionesApi
   */
  public saveDesarrolloTecnologicoInnovacion(
    desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
    options?: AxiosRequestConfig
  ) {
    return DesarrollosTecnologicosInnovacionesApiFp(this.configuration)
      .saveDesarrolloTecnologicoInnovacion(desarrolloTecnologicoInnovacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza información de un Desarrollo Tecnologico o Innovacion de un usuario
   * @param {string} id Id de la aportación
   * @param {DesarrolloTecnologicoInnovacion} desarrolloTecnologicoInnovacion Información de DesarrollosTecnologicosInnovaciones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DesarrollosTecnologicosInnovacionesApi
   */
  public updateDesarrolloTecnologicoInnovacion(
    id: string,
    desarrolloTecnologicoInnovacion: DesarrolloTecnologicoInnovacion,
    options?: AxiosRequestConfig
  ) {
    return DesarrollosTecnologicosInnovacionesApiFp(this.configuration)
      .updateDesarrolloTecnologicoInnovacion(id, desarrolloTecnologicoInnovacion, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LibrosApi - axios parameter creator
 * @export
 */
export const LibrosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina un libro
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLibro: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteLibro', 'id', id);
      const localVarPath = `/libros/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene un listado con libros
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del libro
     * @param {string} [doi] Doi del libro
     * @param {string} [tituloLibro] Titulo del libro
     * @param {string} [anioPublicacion] Año de publicación del libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLibros: async (
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getAllLibros', 'tipo', tipo);
      const localVarPath = `/libros`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      if (isbn !== undefined) {
        localVarQueryParameter['isbn'] = isbn;
      }

      if (doi !== undefined) {
        localVarQueryParameter['doi'] = doi;
      }

      if (tituloLibro !== undefined) {
        localVarQueryParameter['tituloLibro'] = tituloLibro;
      }

      if (anioPublicacion !== undefined) {
        localVarQueryParameter['anioPublicacion'] = anioPublicacion;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesibros: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalesibros', 'tipo', tipo);
      const localVarPath = `/libros/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Agrega un nuevo libro
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveLibro: async (tipo: Tipo, libro: Libro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('saveLibro', 'tipo', tipo);
      // verify required parameter 'libro' is not null or undefined
      assertParamExists('saveLibro', 'libro', libro);
      const localVarPath = `/libros`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(libro, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza un libro
     * @param {string} id Id de la aportación
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLibro: async (id: string, libro: Libro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateLibro', 'id', id);
      // verify required parameter 'libro' is not null or undefined
      assertParamExists('updateLibro', 'libro', libro);
      const localVarPath = `/libros/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(libro, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LibrosApi - functional programming interface
 * @export
 */
export const LibrosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LibrosApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina un libro
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteLibro(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLibro(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene un listado con libros
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del libro
     * @param {string} [doi] Doi del libro
     * @param {string} [tituloLibro] Titulo del libro
     * @param {string} [anioPublicacion] Año de publicación del libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllLibros(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Libro>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLibros(
        tipo,
        page,
        size,
        isbn,
        doi,
        tituloLibro,
        anioPublicacion,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesibros(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesibros(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Agrega un nuevo libro
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveLibro(
      tipo: Tipo,
      libro: Libro,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveLibro(tipo, libro, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza un libro
     * @param {string} id Id de la aportación
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLibro(
      id: string,
      libro: Libro,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLibro(id, libro, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * LibrosApi - factory interface
 * @export
 */
export const LibrosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = LibrosApiFp(configuration);
  return {
    /**
     * Elimina un libro
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteLibro(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteLibro(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene un listado con libros
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [isbn] ISBN del libro
     * @param {string} [doi] Doi del libro
     * @param {string} [tituloLibro] Titulo del libro
     * @param {string} [anioPublicacion] Año de publicación del libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllLibros(
      tipo: Tipo,
      page?: number,
      size?: number,
      isbn?: string,
      doi?: string,
      tituloLibro?: string,
      anioPublicacion?: string,
      options?: any
    ): AxiosPromise<Array<Libro>> {
      return localVarFp
        .getAllLibros(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesibros(tipo: Tipo, options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesibros(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Agrega un nuevo libro
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveLibro(tipo: Tipo, libro: Libro, options?: any): AxiosPromise<void> {
      return localVarFp.saveLibro(tipo, libro, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza un libro
     * @param {string} id Id de la aportación
     * @param {Libro} libro Información de un libro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLibro(id: string, libro: Libro, options?: any): AxiosPromise<void> {
      return localVarFp.updateLibro(id, libro, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * LibrosApi - object-oriented interface
 * @export
 * @class LibrosApi
 * @extends {BaseAPI}
 */
export class LibrosApi extends BaseAPI {
  /**
   * Elimina un libro
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LibrosApi
   */
  public deleteLibro(id: string, options?: AxiosRequestConfig) {
    return LibrosApiFp(this.configuration)
      .deleteLibro(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene un listado con libros
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [isbn] ISBN del libro
   * @param {string} [doi] Doi del libro
   * @param {string} [tituloLibro] Titulo del libro
   * @param {string} [anioPublicacion] Año de publicación del libro
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LibrosApi
   */
  public getAllLibros(
    tipo: Tipo,
    page?: number,
    size?: number,
    isbn?: string,
    doi?: string,
    tituloLibro?: string,
    anioPublicacion?: string,
    options?: AxiosRequestConfig
  ) {
    return LibrosApiFp(this.configuration)
      .getAllLibros(tipo, page, size, isbn, doi, tituloLibro, anioPublicacion, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de libros para la solicitud de SNI
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LibrosApi
   */
  public getTotalesibros(tipo: Tipo, options?: AxiosRequestConfig) {
    return LibrosApiFp(this.configuration)
      .getTotalesibros(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Agrega un nuevo libro
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {Libro} libro Información de un libro
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LibrosApi
   */
  public saveLibro(tipo: Tipo, libro: Libro, options?: AxiosRequestConfig) {
    return LibrosApiFp(this.configuration)
      .saveLibro(tipo, libro, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza un libro
   * @param {string} id Id de la aportación
   * @param {Libro} libro Información de un libro
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LibrosApi
   */
  public updateLibro(id: string, libro: Libro, options?: AxiosRequestConfig) {
    return LibrosApiFp(this.configuration)
      .updateLibro(id, libro, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * PropiedadesIntelectualesApi - axios parameter creator
 * @export
 */
export const PropiedadesIntelectualesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePropiedadIntelectual: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deletePropiedadIntelectual', 'id', id);
      const localVarPath = `/propiedades-intelectuales/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene información de Propiedades Intelectuales de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
     * @param {string} [titulo] Titulo de la propiedad intelectual
     * @param {string} [rol] Rol de la propiedad intelectual
     * @param {string} [estado] Estado de la propiedad intelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPropiedadesIntelectuales: async (
      page?: number,
      size?: number,
      tipoProteccion?: string,
      titulo?: string,
      rol?: string,
      estado?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/propiedades-intelectuales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipoProteccion !== undefined) {
        localVarQueryParameter['tipoProteccion'] = tipoProteccion;
      }

      if (titulo !== undefined) {
        localVarQueryParameter['titulo'] = titulo;
      }

      if (rol !== undefined) {
        localVarQueryParameter['rol'] = rol;
      }

      if (estado !== undefined) {
        localVarQueryParameter['estado'] = estado;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesPropiedadesIntelectuales: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/propiedades-intelectuales/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Almacena información de una Propiedad Intelectual de un usuario
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePropiedadIntelectual: async (
      propiedadIntelectual: PropiedadIntelectual,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'propiedadIntelectual' is not null or undefined
      assertParamExists('savePropiedadIntelectual', 'propiedadIntelectual', propiedadIntelectual);
      const localVarPath = `/propiedades-intelectuales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(propiedadIntelectual, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePropiedadIntelectual: async (
      id: string,
      propiedadIntelectual: PropiedadIntelectual,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updatePropiedadIntelectual', 'id', id);
      // verify required parameter 'propiedadIntelectual' is not null or undefined
      assertParamExists('updatePropiedadIntelectual', 'propiedadIntelectual', propiedadIntelectual);
      const localVarPath = `/propiedades-intelectuales/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(propiedadIntelectual, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PropiedadesIntelectualesApi - functional programming interface
 * @export
 */
export const PropiedadesIntelectualesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PropiedadesIntelectualesApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePropiedadIntelectual(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropiedadIntelectual(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene información de Propiedades Intelectuales de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
     * @param {string} [titulo] Titulo de la propiedad intelectual
     * @param {string} [rol] Rol de la propiedad intelectual
     * @param {string} [estado] Estado de la propiedad intelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllPropiedadesIntelectuales(
      page?: number,
      size?: number,
      tipoProteccion?: string,
      titulo?: string,
      rol?: string,
      estado?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropiedadIntelectual>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPropiedadesIntelectuales(
        page,
        size,
        tipoProteccion,
        titulo,
        rol,
        estado,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesPropiedadesIntelectuales(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesPropiedadesIntelectuales(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Almacena información de una Propiedad Intelectual de un usuario
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async savePropiedadIntelectual(
      propiedadIntelectual: PropiedadIntelectual,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.savePropiedadIntelectual(propiedadIntelectual, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePropiedadIntelectual(
      id: string,
      propiedadIntelectual: PropiedadIntelectual,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePropiedadIntelectual(id, propiedadIntelectual, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PropiedadesIntelectualesApi - factory interface
 * @export
 */
export const PropiedadesIntelectualesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PropiedadesIntelectualesApiFp(configuration);
  return {
    /**
     * Elimina información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePropiedadIntelectual(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deletePropiedadIntelectual(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene información de Propiedades Intelectuales de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
     * @param {string} [titulo] Titulo de la propiedad intelectual
     * @param {string} [rol] Rol de la propiedad intelectual
     * @param {string} [estado] Estado de la propiedad intelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllPropiedadesIntelectuales(
      page?: number,
      size?: number,
      tipoProteccion?: string,
      titulo?: string,
      rol?: string,
      estado?: string,
      options?: any
    ): AxiosPromise<Array<PropiedadIntelectual>> {
      return localVarFp
        .getAllPropiedadesIntelectuales(page, size, tipoProteccion, titulo, rol, estado, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesPropiedadesIntelectuales(options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesPropiedadesIntelectuales(options).then(request => request(axios, basePath));
    },
    /**
     * Almacena información de una Propiedad Intelectual de un usuario
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    savePropiedadIntelectual(propiedadIntelectual: PropiedadIntelectual, options?: any): AxiosPromise<void> {
      return localVarFp.savePropiedadIntelectual(propiedadIntelectual, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza información de una Propiedad Intelectual de un usuario
     * @param {string} id Id de la aportación
     * @param {PropiedadIntelectual} propiedadIntelectual
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePropiedadIntelectual(id: string, propiedadIntelectual: PropiedadIntelectual, options?: any): AxiosPromise<void> {
      return localVarFp.updatePropiedadIntelectual(id, propiedadIntelectual, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * PropiedadesIntelectualesApi - object-oriented interface
 * @export
 * @class PropiedadesIntelectualesApi
 * @extends {BaseAPI}
 */
export class PropiedadesIntelectualesApi extends BaseAPI {
  /**
   * Elimina información de una Propiedad Intelectual de un usuario
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropiedadesIntelectualesApi
   */
  public deletePropiedadIntelectual(id: string, options?: AxiosRequestConfig) {
    return PropiedadesIntelectualesApiFp(this.configuration)
      .deletePropiedadIntelectual(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene información de Propiedades Intelectuales de un usuario
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [tipoProteccion] Tipo de protección de la propiedad intelectual
   * @param {string} [titulo] Titulo de la propiedad intelectual
   * @param {string} [rol] Rol de la propiedad intelectual
   * @param {string} [estado] Estado de la propiedad intelectual
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropiedadesIntelectualesApi
   */
  public getAllPropiedadesIntelectuales(
    page?: number,
    size?: number,
    tipoProteccion?: string,
    titulo?: string,
    rol?: string,
    estado?: string,
    options?: AxiosRequestConfig
  ) {
    return PropiedadesIntelectualesApiFp(this.configuration)
      .getAllPropiedadesIntelectuales(page, size, tipoProteccion, titulo, rol, estado, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de propiedades-intelectuales para la solicitud de SNI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropiedadesIntelectualesApi
   */
  public getTotalesPropiedadesIntelectuales(options?: AxiosRequestConfig) {
    return PropiedadesIntelectualesApiFp(this.configuration)
      .getTotalesPropiedadesIntelectuales(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Almacena información de una Propiedad Intelectual de un usuario
   * @param {PropiedadIntelectual} propiedadIntelectual
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropiedadesIntelectualesApi
   */
  public savePropiedadIntelectual(propiedadIntelectual: PropiedadIntelectual, options?: AxiosRequestConfig) {
    return PropiedadesIntelectualesApiFp(this.configuration)
      .savePropiedadIntelectual(propiedadIntelectual, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza información de una Propiedad Intelectual de un usuario
   * @param {string} id Id de la aportación
   * @param {PropiedadIntelectual} propiedadIntelectual
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PropiedadesIntelectualesApi
   */
  public updatePropiedadIntelectual(id: string, propiedadIntelectual: PropiedadIntelectual, options?: AxiosRequestConfig) {
    return PropiedadesIntelectualesApiFp(this.configuration)
      .updatePropiedadIntelectual(id, propiedadIntelectual, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TotalDocumentosApi - axios parameter creator
 * @export
 */
export const TotalDocumentosApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Obtiene los totales de los documentos guardados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalDocumentos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/total-documentos`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales de produccion cientifica
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalProduccion: async (tipo: Tipo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'tipo' is not null or undefined
      assertParamExists('getTotalPrincipalProduccion', 'tipo', tipo);
      const localVarPath = `/total-documentos/principal-produccion`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (tipo !== undefined) {
        localVarQueryParameter['tipo'] = tipo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales de produccion tecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalTecnologico: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/total-documentos/principal-tecnologico`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TotalDocumentosApi - functional programming interface
 * @export
 */
export const TotalDocumentosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TotalDocumentosApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtiene los totales de los documentos guardados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalDocumentos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalDocumentos(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales de produccion cientifica
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalPrincipalProduccion(
      tipo: Tipo,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalPrincipalProduccion(tipo, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales de produccion tecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalPrincipalTecnologico(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalPrincipalTecnologico(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TotalDocumentosApi - factory interface
 * @export
 */
export const TotalDocumentosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TotalDocumentosApiFp(configuration);
  return {
    /**
     * Obtiene los totales de los documentos guardados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalDocumentos(options?: any): AxiosPromise<number> {
      return localVarFp.getTotalDocumentos(options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales de produccion cientifica
     * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalProduccion(tipo: Tipo, options?: any): AxiosPromise<number> {
      return localVarFp.getTotalPrincipalProduccion(tipo, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales de produccion tecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalPrincipalTecnologico(options?: any): AxiosPromise<number> {
      return localVarFp.getTotalPrincipalTecnologico(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TotalDocumentosApi - object-oriented interface
 * @export
 * @class TotalDocumentosApi
 * @extends {BaseAPI}
 */
export class TotalDocumentosApi extends BaseAPI {
  /**
   * Obtiene los totales de los documentos guardados
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TotalDocumentosApi
   */
  public getTotalDocumentos(options?: AxiosRequestConfig) {
    return TotalDocumentosApiFp(this.configuration)
      .getTotalDocumentos(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales de produccion cientifica
   * @param {Tipo} tipo tipo de la publicacion (cientifica o difusion)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TotalDocumentosApi
   */
  public getTotalPrincipalProduccion(tipo: Tipo, options?: AxiosRequestConfig) {
    return TotalDocumentosApiFp(this.configuration)
      .getTotalPrincipalProduccion(tipo, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales de produccion tecnologica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TotalDocumentosApi
   */
  public getTotalPrincipalTecnologico(options?: AxiosRequestConfig) {
    return TotalDocumentosApiFp(this.configuration)
      .getTotalPrincipalTecnologico(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TransferenciasTecnologicasApi - axios parameter creator
 * @export
 */
export const TransferenciasTecnologicasApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Elimina información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransferenciaTecnologica: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteTransferenciaTecnologica', 'id', id);
      const localVarPath = `/transferencias-tecnologicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene información de Transferencias Tecnologicas de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
     * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
     * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
     * @param {string} [rol] Rol de la transferencia tecnológica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransferenciasTecnologicas: async (
      page?: number,
      size?: number,
      tipoActividad?: string,
      fechaInicio?: string,
      fechaFin?: string,
      rol?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/transferencias-tecnologicas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (tipoActividad !== undefined) {
        localVarQueryParameter['tipoActividad'] = tipoActividad;
      }

      if (fechaInicio !== undefined) {
        localVarQueryParameter['fechaInicio'] = fechaInicio;
      }

      if (fechaFin !== undefined) {
        localVarQueryParameter['fechaFin'] = fechaFin;
      }

      if (rol !== undefined) {
        localVarQueryParameter['rol'] = rol;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesTransferenciasTecnologicas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/transferencias-tecnologicas/totales`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Almacena información de una Transferencia Tecnologica de un usuario
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveTransferenciaTecnologica: async (
      transferenciaTecnologica: TransferenciaTecnologica,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'transferenciaTecnologica' is not null or undefined
      assertParamExists('saveTransferenciaTecnologica', 'transferenciaTecnologica', transferenciaTecnologica);
      const localVarPath = `/transferencias-tecnologicas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transferenciaTecnologica, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Actualiza información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferenciaTecnologica: async (
      id: string,
      transferenciaTecnologica: TransferenciaTecnologica,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateTransferenciaTecnologica', 'id', id);
      // verify required parameter 'transferenciaTecnologica' is not null or undefined
      assertParamExists('updateTransferenciaTecnologica', 'transferenciaTecnologica', transferenciaTecnologica);
      const localVarPath = `/transferencias-tecnologicas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth', ['jhipster', 'email', 'profile'], configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(transferenciaTecnologica, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransferenciasTecnologicasApi - functional programming interface
 * @export
 */
export const TransferenciasTecnologicasApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransferenciasTecnologicasApiAxiosParamCreator(configuration);
  return {
    /**
     * Elimina información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTransferenciaTecnologica(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTransferenciaTecnologica(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene información de Transferencias Tecnologicas de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
     * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
     * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
     * @param {string} [rol] Rol de la transferencia tecnológica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllTransferenciasTecnologicas(
      page?: number,
      size?: number,
      tipoActividad?: string,
      fechaInicio?: string,
      fechaFin?: string,
      rol?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransferenciaTecnologica>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTransferenciasTecnologicas(
        page,
        size,
        tipoActividad,
        fechaInicio,
        fechaFin,
        rol,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTotalesTransferenciasTecnologicas(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalesTransferenciasTecnologicas(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Almacena información de una Transferencia Tecnologica de un usuario
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveTransferenciaTecnologica(
      transferenciaTecnologica: TransferenciaTecnologica,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveTransferenciaTecnologica(transferenciaTecnologica, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Actualiza información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTransferenciaTecnologica(
      id: string,
      transferenciaTecnologica: TransferenciaTecnologica,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTransferenciaTecnologica(id, transferenciaTecnologica, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TransferenciasTecnologicasApi - factory interface
 * @export
 */
export const TransferenciasTecnologicasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TransferenciasTecnologicasApiFp(configuration);
  return {
    /**
     * Elimina información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTransferenciaTecnologica(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteTransferenciaTecnologica(id, options).then(request => request(axios, basePath));
    },
    /**
     * Obtiene información de Transferencias Tecnologicas de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
     * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
     * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
     * @param {string} [rol] Rol de la transferencia tecnológica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTransferenciasTecnologicas(
      page?: number,
      size?: number,
      tipoActividad?: string,
      fechaInicio?: string,
      fechaFin?: string,
      rol?: string,
      options?: any
    ): AxiosPromise<Array<TransferenciaTecnologica>> {
      return localVarFp
        .getAllTransferenciasTecnologicas(page, size, tipoActividad, fechaInicio, fechaFin, rol, options)
        .then(request => request(axios, basePath));
    },
    /**
     * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTotalesTransferenciasTecnologicas(options?: any): AxiosPromise<TotalProductos> {
      return localVarFp.getTotalesTransferenciasTecnologicas(options).then(request => request(axios, basePath));
    },
    /**
     * Almacena información de una Transferencia Tecnologica de un usuario
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveTransferenciaTecnologica(transferenciaTecnologica: TransferenciaTecnologica, options?: any): AxiosPromise<void> {
      return localVarFp.saveTransferenciaTecnologica(transferenciaTecnologica, options).then(request => request(axios, basePath));
    },
    /**
     * Actualiza información de una Transferencia Tecnologica de un usuario
     * @param {string} id Id de la aportación
     * @param {TransferenciaTecnologica} transferenciaTecnologica
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTransferenciaTecnologica(id: string, transferenciaTecnologica: TransferenciaTecnologica, options?: any): AxiosPromise<void> {
      return localVarFp.updateTransferenciaTecnologica(id, transferenciaTecnologica, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TransferenciasTecnologicasApi - object-oriented interface
 * @export
 * @class TransferenciasTecnologicasApi
 * @extends {BaseAPI}
 */
export class TransferenciasTecnologicasApi extends BaseAPI {
  /**
   * Elimina información de una Transferencia Tecnologica de un usuario
   * @param {string} id Id de la aportación
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferenciasTecnologicasApi
   */
  public deleteTransferenciaTecnologica(id: string, options?: AxiosRequestConfig) {
    return TransferenciasTecnologicasApiFp(this.configuration)
      .deleteTransferenciaTecnologica(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene información de Transferencias Tecnologicas de un usuario
   * @param {number} [page] Page number
   * @param {number} [size] Page size
   * @param {string} [tipoActividad] Tipo de Actividad de la transferencia tecnológica
   * @param {string} [fechaInicio] Fecha de inicio de la transferencia tecnológica
   * @param {string} [fechaFin] Fecha de fin de la transferencia tecnológica
   * @param {string} [rol] Rol de la transferencia tecnológica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferenciasTecnologicasApi
   */
  public getAllTransferenciasTecnologicas(
    page?: number,
    size?: number,
    tipoActividad?: string,
    fechaInicio?: string,
    fechaFin?: string,
    rol?: string,
    options?: AxiosRequestConfig
  ) {
    return TransferenciasTecnologicasApiFp(this.configuration)
      .getAllTransferenciasTecnologicas(page, size, tipoActividad, fechaInicio, fechaFin, rol, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Obtiene los totales productos completos y principales de transferencias-tecnologicas para la solicitud de SNI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferenciasTecnologicasApi
   */
  public getTotalesTransferenciasTecnologicas(options?: AxiosRequestConfig) {
    return TransferenciasTecnologicasApiFp(this.configuration)
      .getTotalesTransferenciasTecnologicas(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Almacena información de una Transferencia Tecnologica de un usuario
   * @param {TransferenciaTecnologica} transferenciaTecnologica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferenciasTecnologicasApi
   */
  public saveTransferenciaTecnologica(transferenciaTecnologica: TransferenciaTecnologica, options?: AxiosRequestConfig) {
    return TransferenciasTecnologicasApiFp(this.configuration)
      .saveTransferenciaTecnologica(transferenciaTecnologica, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * Actualiza información de una Transferencia Tecnologica de un usuario
   * @param {string} id Id de la aportación
   * @param {TransferenciaTecnologica} transferenciaTecnologica
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransferenciasTecnologicasApi
   */
  public updateTransferenciaTecnologica(id: string, transferenciaTecnologica: TransferenciaTecnologica, options?: AxiosRequestConfig) {
    return TransferenciasTecnologicasApiFp(this.configuration)
      .updateTransferenciaTecnologica(id, transferenciaTecnologica, options)
      .then(request => request(this.axios, this.basePath));
  }
}
