import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JhiFooter extends Vue {
  public currentLanguage = '';
  today = new Date().getFullYear();

  created() {
    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
      }
    );
  }
}
