import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
@Component
export default class BreadcrumbComponent extends Vue {
  public breadcrumbs: { name: string; link?: string }[] = [];
  public currentLanguage: string;

  public scrollPosition: number = window.scrollY;
  public authenticated;
  public isCommentDialogActivated = false;
  private lastScrollY: number = window.scrollY;
  private currentHeaderState: 'engrozar' | 'breadcrumb-adelgazar' = 'engrozar';

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.updateBreadcrumbs();
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  get rutaBreadcrumb() {
    return this.$route;
  }

  get lenguage(): string {
    return this.$store.state.translationStore.currentLanguage;
  }

  @Watch('lenguage')
  handler(value) {
    if (value) {
      this.updateBreadcrumbs();
    }
  }

  // Watch para detectar cambios en la ruta
  @Watch('rutaBreadcrumb')
  onRouteChange() {
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs() {
    const routeBreadcrumbs = this.$route.meta.breadcrumb || [];

    if (this.$route.path === '/' && routeBreadcrumbs - length === 0) {
      this.breadcrumbs = [{ name: this.$t('breadcrumb.home').toString(), link: '/' }];
    } else {
      this.breadcrumbs = routeBreadcrumbs.map(item => ({
        name: this.$t(`breadcrumb.${item.name}`), // Traducción basada en el nombre
        link: item.link,
      }));
    }

    this.validateBreadCrumbs();
  }

  validateBreadCrumbs() {
    let existenSinTraduccion = false;
    this.breadcrumbs.map(item => {
      if (item.name.includes('breadcrumb')) {
        existenSinTraduccion = true;
      }
    });

    if (existenSinTraduccion) {
      setTimeout(this.updateBreadcrumbs, 500);
    }
  }

  private handleScroll() {
    const currentScrollY = window.scrollY;
    const threshold = 10;

    if (currentScrollY - this.lastScrollY > threshold) {
      if (this.currentHeaderState !== 'breadcrumb-adelgazar') {
        this.currentHeaderState = 'breadcrumb-adelgazar';
      }
    } else if (this.lastScrollY - currentScrollY > threshold) {
      if (this.currentHeaderState !== 'engrozar') {
        this.currentHeaderState = 'engrozar';
      }
    }

    this.scrollPosition = currentScrollY;
    this.lastScrollY = currentScrollY;
  }

  get headerClasses() {
    return [this.currentHeaderState];
  }
}
